import { axiosClient } from "./apiClient";

export function login(data) {
  return axiosClient.post("/api/v1/website/auth/login", data);
}

export function signup(data) {
  return axiosClient.post("/api/v1/website/auth/register", data);
}

export function logout(data) {
  return axiosClient.post("/api/v1/website/auth/logout", data);
}

export function verifyOtp(data) {
  return axiosClient.post("/api/v1/website/auth/verify-otp", data);
}

export function resendOtp(data) {
  return axiosClient.post("/api/v1/website/auth/resend-user-otp", data);
}

export function forgetPassword(data) {
  return axiosClient.post("/api/v1/website/auth/forgot-password", data);
}

export function resetPassword(data) {
  return axiosClient.post("/api/v1/website/auth/reset-password", data);
}

export function changePassword(data) {
  return axiosClient.post("/api/v1/website/auth/change-password", data);
}

export function editProfile(data) {
  return axiosClient.post("/api/v1/website/auth/edit-profile", data);
}

export function deleteAccont(data) {
  return axiosClient.post("/api/v1/website/auth/delete-account", data);
}

export function getDeleteAccontReason(data) {
  return axiosClient.post(
    "/api/v1/website/auth/get-delete-account-reason",
    data
  );
}

export function getStaticPage(data) {
  return axiosClient.post("/api/v1/website/cms/get-static-page", data);
}

export function getFaqs(data) {
  return axiosClient.post("/api/v1/website/cms/get-faq", data);
}

export function addContactUs(data) {
  return axiosClient.post("/api/v1/website/cms/add-contact-us", data);
}

export function getReview(data) {
  return axiosClient.post("/api/v1/website/user/get-review-details", data);
}

export function getTestimonial(data) {
  return axiosClient.post("/api/v1/website/user/get-review-details", data);
}

export function addReview(data) {
  return axiosClient.post("/api/v1/website/user/add-review", data);
}

export function becomeDesigner(data) {
  return axiosClient.post("/api/v1/website/user/become-designer", data);
}

export function getDesignerDetails(data) {
  return axiosClient.post("/api/v1/website/user/get-designer-profile", data);
}

export function sunscribeNewsLetter(data) {
  return axiosClient.post("api/v1/website/user/news-letter", data);
}

export function getEarning(data) {
  return axiosClient.post("api/v1/website/earning/get-earning", data);
}

export function getTeam(data) {
  return axiosClient.post("api/v1/website/team/get-team", data);
}

export function getTemplateInvitation(data) {
  return axiosClient.post("api/v1/website/team/get-template-invitation", data);
}

export function addTeamMember(data) {
  return axiosClient.post("api/v1/website/team/add-team-member", data);
}

export function deleteTeamMember(data) {
  return axiosClient.post("api/v1/website/team/delete-team-member", data);
}

export function inviteTeamMember(data) {
  return axiosClient.post("api/v1/website/team/invite-member", data);
}

export function deleteInvitedTeamMember(data) {
  return axiosClient.post("api/v1/website/team/delete-invited-member", data);
}

export function addTemplate(data) {
  return axiosClient.post("api/v1/website/template/add-template", data);
}

export function deleteTemplate(data) {
  return axiosClient.post("api/v1/website/template/delete-template", data);
}

export function editTemplate(data) {
  return axiosClient.post("api/v1/website/template/edit-template", data);
}

export function getTemplate(data) {
  return axiosClient.post("api/v1/website/template/get-template", data);
}

export function getTemplateData(data) {
  return axiosClient.post("api/v1/website/template/get-template-data", data);
}

export function getInvitedTemplate(data) {
  return axiosClient.post("api/v1/website/template/get-invited-template", data);
}

export function getMyTemplate(data) {
  return axiosClient.post("api/v1/website/template/get-my-template", data);
}
export function getSearchTemplate(data) {
  return axiosClient.post("api/v1/website/template/get-search-template", data);
}
export function getUserTemplate(data) {
  return axiosClient.post("api/v1/website/template/get-user-template", data);
}

export function getTrendingTemplate(data) {
  return axiosClient.post(
    "api/v1/website/template/get-trending-template",
    data
  );
}

export function getAllTemplate(data) {
  return axiosClient.post("api/v1/website/template/get-all-template", data);
}

export function templateDownload(data) {
  return axiosClient.post("api/v1/website/template/template-download", data);
}

export function publishTemplate(data) {
  return axiosClient.post("api/v1/website/template/publish-template", data);
}

export function getSimilarTemplate(data) {
  return axiosClient.post("api/v1/website/template/get-similar-template", data);
}

export function getCategoryTemplate(data) {
  return axiosClient.post(
    "api/v1/website/template/get-template-category",
    data
  );
}

export function getAllFonts(data) {
  return axiosClient.post("api/v1/website/assets/get-fonts", data);
}

export function getSocialMediaIcon(data) {
  return axiosClient.post("/api/v1/website/assets/get-social-media-icon", data);
}

export function getShapes(data) {
  return axiosClient.post("/api/v1/website/assets/get-shapes", data);
}

export function getStickers(data) {
  return axiosClient.post("/api/v1/website/assets/get-stickers", data);
}

export function addUploadDocumentPolotnoApi(data) {
  return axiosClient.post("/api/v1/website/assets/add-upload-document-polotno", data);
}

export function removeUploadDocumentPolotnoApi(data) {
  return axiosClient.post("/api/v1/website/assets/remove-upload-document-polotno", data);
}

export function getUploadDocumentPolotnoApi(data) {
  return axiosClient.post("/api/v1/website/assets/get-upload-document-polotno", data);
}

export function getCliparts(data) {
  return axiosClient.post("/api/v1/website/assets/get-clipart", data);
}

export function getAllCategories(data) {
  return axiosClient.post("/api/v1/website/category/get-category", data);
}

export function getCategoryIcon(data) {
  return axiosClient.post("/api/v1/website/category/get-category-icon", data);
}

export function getKeyword(data) {
  return axiosClient.post("/api/v1/website/category/get-keyword", data);
}

export function getTheme(data) {
  return axiosClient.post("/api/v1/website/category/get-theme", data);
}

export function getLandingTheme(data) {
  return axiosClient.post("/api/v1/website/category/get-landing-theme", data);
}

export function getAllCategoriesSubcategories(data) {
  return axiosClient.post(
    "/api/v1/website/category/get-category-subcategory",
    data
  );
}

export function subscripPayment(data) {
  return axiosClient.post(
    "/api/v1/website/subscription/add-subscription-plan",
    data
  );
}

export function changeCardDetailsApi(data) {
  return axiosClient.post(
    "/api/v1/website/subscription/change-subscription-card",
    data
  );
}

export function getSubscription(data) {
  return axiosClient.post(
    "/api/v1/website/subscription/get-subscription-plan",
    data
  );
}
export function getCurrentSubscription(data) {
  return axiosClient.post(
    "/api/v1/website/subscription/get-current-subscription",
    data
  );
}

export function cancelSubscription(data) {
  return axiosClient.post(
    "/api/v1/website/subscription/cancel-subscription-plan",
    data
  );
}

export function getSubscriptionUpdateDetails(data) {
  return axiosClient.post(
    "/api/v1/website/subscription/get-subscription-updated-plan",
    data
  );
}

export function mp4Download(data) {
  return axiosClient.post(
    "/api/v1/website/template/polotno-download-video",
    data
  );
}

export function downloadPercentage(data) {
  return axiosClient.post("/api/v1/website/template/get-polotno-download-video", data);
}