import React, { useEffect, useState } from "react";
import Innerheader from "../../components/Innerheader";
import { Link, useLocation, useParams } from "react-router-dom";
import Instagramreels from "./components/Instagramreels1";
import { useTranslation } from "react-i18next";
import Sidebar from "../../components/Sidebar";
import { getAllCategories, getAllTemplate } from "../../api/apiHandler";
import { useFilter } from "../../Common/FilterContex";
import { date } from "yup";
import { getToken } from "../../Common/LocalStorageService";
import Header from "../../components/Header";
import ShimmerEffect from "./ShimmerEffect";
// import ShareModal from "../../components/ShareModal";

const Home = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [category, setCategory] = useState([]);
  const [isLoading, setIsLoding] = useState(false);
  console.log('isLoading: ', isLoading);
  const [activeCategory, setActiveCategory] = useState(
    location?.state?.category_id || 1
  );
  console.log('location ', location);
  const [isLogin, setIsLogin] = useState(false);
  const [isSignup, setIsSignup] = useState(false);
  const [templateData, setTemplateData] = useState([]);
  console.log('templateData: ', templateData);

  const { prevFilters } = useFilter();
  console.log("prevFilters:+++++ ", prevFilters.global_search);

  // const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  // const [isTemplateDetailsModalOpen, setIsTemplateDetailsModalOpen] =
  //   useState(false);
  // const [singleTemplate, setSingleTemplate] = useState({});

  useEffect(() => {
    getAllCategories({}).then((response) => {
      console.log("response: cateeee", response);
      if (response.code === 200) {
        setCategory(response?.data);
      } else {
        setCategory([]);
      }
    });
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setIsLoding(false)
    console.log("prevFilters  userrererere", activeCategory);
    if (activeCategory) {
      getAllTemplate({
        category_id: activeCategory,
        global_search: prevFilters.global_search || "",
        global_color: prevFilters.global_colors || [],
        global_orientation: prevFilters.global_orientation || "",
      }).then((_template) => {
        setIsLoding(true)
        if (_template.code === 200) {
          setTemplateData(_template?.data);
        }
      }).catch((err) => {
        setIsLoding(true)
        console.log(err);
      });
    }
  }, [
    activeCategory,
    prevFilters.global_color,
    prevFilters.global_colors,
    prevFilters.global_orientation,
    prevFilters.global_search,
  ]);

  // useEffect(() => {
  //   setActiveCategory(categoryId);
  // }, [categoryId]);
  const categoryImageData = [
    {
      id: 1,
      image: '/assets/images/newicons/Social_Media_graphics.svg'
    },
    {
      id: 2,
      image: '/assets/images/newicons/Marketing.svg'
    },
    {
      id: 3,
      image: '/assets/images/newicons/Online_Ads.svg'
    },
    {
      id: 4,
      image: '/assets/images/newicons/Event.svg'
    },
    {
      id: 5,
      image: '/assets/images/newicons/Personal.svg'
    },
    {
      id: 7,
      image: '/assets/images/newicons/Web_banners.svg'
    },
    // {
    //   id: 52,
    //   // image: '/assets/images/icon/Marketing.png'
    // },
    // {
    //   id: 53,
    //   // image: '/assets/images/icon/Marketing.png'
    // }
  ]
  return (
    <main>
      {getToken() ? (
        <Innerheader />
      ) : (
        <Header openLogin={isLogin} setOpenLogin={setIsLogin} />
      )}
      <div className={getToken() ? "wrapper" : ""}>
        {getToken() && <Sidebar />}
        <div
          className={`content flex-grow-1 ${getToken() ? "" : "container"} `}
        >
          <section className="category_main_title" >
            <div className="container-fluid myriad-pro-ul">
              <div className="row">
                <div className="col-12 mb-3 d-flex flex-wrap justify-content-between align-items-center">
                  <h2 className="section_title">{t("exploreAllTemplate")}</h2>
                </div>
                <div className="col-12 mb-3">
                  <div className="category_list">
                    <ul>
                      {category?.map((item) => {
                        let matchedCategory = categoryImageData.find(category => category.id === item.id);
                        return (
                          <li
                            key={`category_${item?.id}`}
                            className={
                              activeCategory == item?.id ? "active" : ""
                            }
                            onClick={() => {
                              setActiveCategory(item?.id);
                            }}
                          >
                            <a>
                              <div className="category_inner_div">
                                <img
                                  src={(matchedCategory && item.id == matchedCategory.id && matchedCategory.image) ? (process.env.PUBLIC_URL + matchedCategory.image) : (process.env.PUBLIC_URL + '/assets/images/category_icon.svg')}
                                  className="TopCategoryIcon"
                                  alt=""
                                />
                                <span> {item?.name}</span>
                                {/* <span> {t("socialMediaGraphics")}</span> */}
                              </div>
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {isLoading ? (templateData?.map((item) => {
            return (
              <section
                className="category_main_title"
                // style={{ paddingRight: "45px" }}
                key={"template_category" + item?.name}
              >
                <Instagramreels
                  data={item}
                  templates={item.templates}
                  setIsLogin={setIsLogin}
                />
              </section>
            );
          })) : (
            <>
              {console.log("")}
              {
                [1, 2, 3].map(() => {
                  return (
                    <section
                      className="category_main_title"
                      // style={{ paddingRight: "45px" }}
                      key={"template_category" + 123}
                    >
                      <ShimmerEffect tempData={[1, 2, 3]} isLoading={isLoading} />
                    </section>
                  )
                })
              }
            </>
          )}
        </div>
      </div>
    </main >
  );
};

export default Home;
