import React, { useCallback, useEffect, useState } from "react";
import { InputGroup } from "@blueprintjs/core";
import styled from "polotno/utils/styled";
import { ImagesGrid } from "polotno/side-panel";
import { t } from "polotno/utils/l10n";
import { getShapes } from "../../../api/apiHandler";
import { getImageSize } from "polotno/utils/image";

async function imageUrlToBase64(imageUrl) {
  try {
    const response = await fetch(imageUrl);
    if (!response.ok) {
      throw new Error("Network response was not ok " + response.statusText);
    }

    const contentType = response.headers.get("content-type");
    let base64;

    if (contentType.includes("image/svg+xml")) {
      const text = await response.text();
      base64 = btoa(text);
    } else {
      const arrayBuffer = await response.arrayBuffer();
      base64 = arrayBufferToBase64(arrayBuffer);
    }

    const base64String = `data:${contentType};base64,${base64}`;
    console.log(base64String);
    return base64String;
  } catch (error) {
    console.error("There has been a problem with your fetch operation:", error);
  }
}

// Helper function to convert ArrayBuffer to base64
function arrayBufferToBase64(buffer) {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return btoa(binary);
}

const NounContainer = styled("div")`
  height: 100%;
  overflow: hidden;
`;

export const NounprojectPanel = ({ store, query }) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getShapes({ page: 1, limit: 10 }).then((response) => {
      if (response.code == 200) {
        setData(response.data.data);
        setHasMore(true);
        setIsLoading(false);
      } else {
        setHasMore(false);
        setIsLoading(false);
      }
    });
  }, []);

  const loadMore = useCallback(() => {
    setIsLoading(true);
    getShapes({ page: page + 1, limit: 10 }).then((response) => {
      console.log("response: ", response);
      if (response.code == 200) {
        setData((prev) => [...prev, ...response.data.data]);
        setHasMore(true);
        setIsLoading(false);
      } else {
        setHasMore(false);
        setIsLoading(false);
      }
    });
    setPage(page + 1);
  }, [page]);

  return (
    <NounContainer>
      <ImagesGrid
        shadowEnabled={false}
        images={data}
        getPreview={(item) => item.image}
        isLoading={isLoading}
        itemHeight={"150px"}
        onSelect={async (image, pos, element) => {
          const url = await imageUrlToBase64(image.image);
          let { width, height } = await getImageSize(url);
          const isSVG = url.indexOf("svg+xml") >= 0 || url.indexOf(".svg") >= 0;

          const type = isSVG ? "svg" : "image";
          element = element || store.selectedElements[0];
          if (
            element &&
            element.type === "svg" &&
            !element.locked &&
            type === "image"
          ) {
            element.set({ maskSrc: url });
            return;
          }

          if (
            element &&
            element.type === "image" &&
            !element.locked &&
            type === "svg"
          ) {
            element.set({ clipSrc: url });
            return;
          }

          const scale = Math.min(store.width / width, store.height / height, 1);
          width = width * scale;
          height = height * scale;

          const x = (pos?.x || store.width / 2) - width / 2;
          const y = (pos?.y || store.height / 2) - height / 2;

          store.activePage?.addElement({
            type,
            src: url,
            x,
            y,
            width,
            height,
          });
        }}
        rowsNumber={3}
        loadMore={hasMore && loadMore}
      />
    </NounContainer>
  );
};

export const FancyShapePanel = ({ store }) => {
  const requestTimeout = React.useRef();
  const [query, setQuery] = React.useState("");
  const [delayedQuery, setDelayedQuery] = React.useState(query);

  React.useEffect(() => {
    requestTimeout.current = setTimeout(() => {
      setDelayedQuery(query);
    }, 500);
    return () => {
      clearTimeout(requestTimeout.current);
    };
  }, [query]);

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      {/* <InputGroup
        leftIcon="search"
        placeholder={t("sidePanel.searchPlaceholder")}
        onChange={(e) => {
          setQuery(e.target.value);
        }}
        style={{
          marginBottom: "0",
        }}
        type="search"
      /> */}
      <NounprojectPanel query={delayedQuery} store={store} />
    </div>
  );
};
