import React, { useEffect, useRef } from 'react'
function VideoPlayAutoDesign({ item, editTemplate, setShareId, setIsShareModalOpen, setIsTemplateDetailsModalOpen, userinfo, setTemplateData, setPrevFilters }) {
    const videoRef = useRef(null);

    // Event handlers for hover
    const handleMouseEnter = (e) => {
        if (videoRef && videoRef.current) {
            videoRef.current.play(); // Start playing the video
        }
    };

    const handleMouseLeave = (e) => {
        if (videoRef && videoRef.current) {
            // setIsPlaying(-1);
            videoRef.current.pause(); // Pause the video
        }
    };
    // const handleIntersection = (entries) => {
    //     entries.forEach((entry) => {
    //         if (videoRef.current && entry.isIntersecting) {
    //             // Video is in view, autoplay
    //             videoRef.current.play();
    //         } else if (videoRef.current) {
    //             // Video is not in view, pause
    //             videoRef.current.pause();
    //         }
    //     });
    // };
    const togglePlayback = () => {
        if (videoRef.current) {
            if (videoRef.current.paused) {
                videoRef.current.play(); // Play the video if paused
            } else {
                videoRef.current.pause(); // Pause the video if playing
            }
        }
    };
    useEffect(() => {
        if (videoRef && videoRef.current) {
            videoRef.current.load(); // Ensure video is loaded
        }
    }, [item?.video_url]);
    return (
        <div className="overlay_hover" onMouseEnter={(e) => { handleMouseEnter(e) }}
            onMouseLeave={(e) => { handleMouseLeave(e) }}>
            <a className="template_image ">
                {/* <div className="template_view_Main" style={{ padding: '0' }}> */}
                {item?.is_video == 1 && item?.video_url ? (
                    <video
                        src={item?.video_url}
                        ref={videoRef}
                        loop
                        muted
                        playsInline
                        preload="auto"
                        style={{ objectFit: 'contain', height: '100%', width: 'auto' }}
                    ></video>
                ) : (
                    <img src={item?.thumbnail} alt="" />
                )}
                {/* style={{ objectFit: 'contain' }} */}
                {/* </div> */}
            </a>
            <div className="overlay_div"
                onClick={(e) => {
                    e.stopPropagation()
                    setTemplateData(item);
                    setIsTemplateDetailsModalOpen(true);
                }}
            >
                <div className={`d-flex ${item?.is_video == 1 ? "justify-content-between" : "justify-content-end"}`}>
                    {item?.is_video == 1 && (
                        <a >
                            <img
                                src={process.env.PUBLIC_URL + "/assets/images/icon/video-pause.svg"}
                            />
                        </a>
                    )}
                    <div className="top_li">
                        <a
                            onClick={(e) => {
                                e.stopPropagation()
                                setShareId(item?.id);
                                setIsShareModalOpen(true);
                            }}
                        >
                            <img src="assets/images/share.svg" />
                        </a>
                        <a
                            onClick={(e) => {
                                e.stopPropagation()
                                editTemplate(item);
                            }}
                        >
                            <img src={`${process.env.PUBLIC_URL}/assets/images/edit.svg`} alt="" />
                        </a>
                    </div>
                </div>
                <div className="bottom_li">
                    <div
                        className="user_name cursor-pointer"
                        onClick={(e) => { e.stopPropagation(); setPrevFilters({}); userinfo(item?.user_id) }}
                    >
                        {/* <img src={item?.profile_image} /> */}
                        {/* <h6 className="user-name-h6">{item?.full_name}</h6> */}
                        {/* <h6>{item?.full_name?.length > 6 ? `${item.full_name.substring(0, 6)}...` : item.full_name}</h6>     */}
                    </div>
                    {/* <a
                    onClick={() => {
                        setTemplateData(item);
                        setIsTemplateDetailsModalOpen(true);
                    }}
                    >
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/assets/images/info.svg"
                            }
                            className="infoImg"
                        />
                    </a> */}
                </div>
            </div>
        </div>
    )
}

export default VideoPlayAutoDesign