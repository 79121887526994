import React, { useMemo, useState } from "react";
import {
  FacebookShareButton,
  EmailShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTelegramPlane, faTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { showErrorMessage, showSuccessMessage } from "../Common/notification";
import { useTranslation } from "react-i18next";

const ShareModal = (props) => {
  const { isShareModalOpen, setIsShareModalOpen, shareId } = props;
  const { t, i18n } = useTranslation();
  console.log(process.env.REACT_APP_WEBSITE_LINK, process.env.REACT_APP_S3_CLOUDFRONT_URL);

  const shareUrl = useMemo(() => {
    return `${process.env.REACT_APP_WEBSITE_LINK}copy-template/${shareId}/` + btoa("share");
    // return `http://localhost:3000/templify-dev/copy-template/${shareId}/` + btoa("share");
  }, [shareId]);

  return (
    <>
      <div
        className={`modal fade ${isShareModalOpen ? "show" : ""}`}
        id="otp"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
        style={{ display: isShareModalOpen ? "block" : "none", zIndex: 8000 }}
      >
        {/* <div className="modal" tabIndex={-1} id="otp"> */}
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="modal-body modal-body-custom">
              <button
                type="button"
                className="custom_btn"
                onClick={() => {
                  setIsShareModalOpen(false);
                }}
                aria-label="Close"
              >
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/close.svg"}
                />
              </button>
              <section className="category_main_title">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12 mb-4 d-flex justify-content-between align-items-center flex-wrap">
                      <h2 className="section_title">{t("share")}</h2>
                    </div>
                    <div classname="col-12">
                      <p>{t("shareThisLinkvia")}</p>
                      <div className="d-flex align-items-center icons">
                        {/* <FacebookShareButton url={shareUrl}>
                          <a className="fs-5 d-flex align-items-center justify-content-center facebook">
                            <span className="fab fa-facebook-f" />
                          </a>
                        </FacebookShareButton> */}
                        <FacebookShareButton url={shareUrl}>
                          <a className="fs-5 d-flex align-items-center justify-content-center facebook">
                            <FontAwesomeIcon icon={faFacebookF} />
                          </a>
                        </FacebookShareButton>
                        {/* <TwitterShareButton url={shareUrl}>
                          <a className="fs-5 d-flex align-items-center justify-content-center twitter">
                            <span className="fab fa-twitter" />
                          </a>
                        </TwitterShareButton>
                        <EmailShareButton url={shareUrl}>
                          <a className="fs-5 d-flex align-items-center justify-content-center instagram">
                            <span className="far fa-envelope" />
                          </a>
                        </EmailShareButton>
                        <WhatsappShareButton url={shareUrl} separator=":: ">
                          <a className="fs-5 d-flex align-items-center justify-content-center whatsapp">
                            <span className="fab fa-whatsapp" />
                          </a>
                        </WhatsappShareButton>
                        <TelegramShareButton url={shareUrl}>
                          <a className="fs-5 d-flex align-items-center justify-content-center telegram">
                            <span className="fab fa-telegram-plane" />
                          </a>
                        </TelegramShareButton> */}
                        <TwitterShareButton url={shareUrl}>
                          <a className="fs-5 d-flex align-items-center justify-content-center twitter">
                            <FontAwesomeIcon icon={faTwitter} />
                          </a>
                        </TwitterShareButton>

                        <EmailShareButton url={shareUrl}>
                          <a className="fs-5 d-flex align-items-center justify-content-center instagram">
                            <FontAwesomeIcon icon={faEnvelope} />
                          </a>
                        </EmailShareButton>

                        <WhatsappShareButton url={shareUrl} separator=":: ">
                          <a className="fs-5 d-flex align-items-center justify-content-center whatsapp">
                            <FontAwesomeIcon icon={faWhatsapp} />
                          </a>
                        </WhatsappShareButton>

                        <TelegramShareButton url={shareUrl}>
                          <a className="fs-5 d-flex align-items-center justify-content-center telegram">
                            <FontAwesomeIcon icon={faTelegramPlane} />
                          </a>
                        </TelegramShareButton>

                      </div>
                      <p>{t("orCopyLink")}</p>
                      <div className="field d-flex align-items-center justify-content-between">
                        {/* <span className="fas fa-link text-center" /> */}
                        <FontAwesomeIcon icon={faLink} className="text-center" />
                        <input type="text" readOnly defaultValue={shareUrl} />
                        <button
                          onClick={() => {
                            if (
                              navigator.clipboard &&
                              navigator.clipboard.writeText
                            ) {
                              navigator.clipboard
                                .writeText(shareUrl)
                                .then(() => {
                                  showSuccessMessage("Copied to clipboard!");
                                })
                                .catch((err) => {
                                  showErrorMessage("Failed to copy");
                                  console.error("Failed to copy: ", err);
                                });
                            } else {
                              showErrorMessage("Clipboard API not supported");
                              console.error(
                                "Clipboard API not supported or unavailable."
                              );
                            }
                          }}
                        >
                          {t("copy")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </>
  );
};

export default ShareModal;
