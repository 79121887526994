import React, { useCallback, useEffect, useRef, useState } from "react";
import Innerheader from "../../components/Innerheader";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Sidebar from "../../components/Sidebar";
import { getInvitedTemplate } from "../../api/apiHandler";
import TemplateDetailsModal from "../../components/TemplateDetailsEditModal";
import ShareModal from "../../components/ShareModal";

const VideoComponent = ({ item, editTemplate, userinfo, setTemplateData, setIsTemplateDetailsModalOpen }) => {
  const videoRef = useRef(null);

  // Event handlers for hover
  const handleMouseEnter = (e) => {
    console.log('handleMouseEnter: ');
    if (videoRef && videoRef.current) {
      // setIsPlaying(index);
      videoRef.current.play(); // Start playing the video
    }
  };

  const handleMouseLeave = (e) => {
    console.log('handleMouseEnter: 55664564');
    if (videoRef && videoRef.current) {
      // setIsPlaying(-1);
      videoRef.current.pause(); // Pause the video
    }
  };
  useEffect(() => {
    if (videoRef && videoRef.current) {
      videoRef.current.load(); // Ensure video is loaded
    }
  }, [item?.video_url]);
  return (
    <>
      {item?.is_video == 1 && (
        <a className="video_show2">
          <img
            src={process.env.PUBLIC_URL + "/assets/images/icon/video-pause.svg"}
          />
        </a>
      )}
      <div className="overlay_hover" onMouseEnter={(e) => { handleMouseEnter(e) }}
        onMouseLeave={(e) => { handleMouseLeave(e) }}
        onClick={(e) => {
          e.stopPropagation();
          setTemplateData(item);
          setIsTemplateDetailsModalOpen(true);
        }}
      >
        <a className="template_image ">
          {item?.is_video == 1 && item?.video_url ? (
            <video
              src={item?.video_url}
              ref={videoRef}
              loop
              muted
              playsInline
              preload="auto"
              style={{ objectFit: 'contain', height: '100%', width: 'auto' }}
            ></video>
          ) : (
            <img src={item?.thumbnail} />
          )}
        </a>
        <div className="overlay_div">
          <div className={`d-flex justify-content-end`}>
            {/* {item?.is_video == 1 && (
            <a >
              <img
                src={process.env.PUBLIC_URL + "/assets/images/icon/video-pause.svg"}
              />
            </a>
          )} */}
            <div className="top_li">
              {/* <a
                                        onClick={() => {
                                          setIsShareModalOpen(true);
                                        }}
                                      >
                                        <img src="assets/images/share.svg" />
                                      </a> */}
              <a
                onClick={(e) => {
                  e.stopPropagation();
                  editTemplate(item);
                }}
              >
                <img src="assets/images/edit.svg" />
              </a>
            </div>
          </div>
          <div className="bottom_li">
            <div
              className="user_name cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                userinfo(item?.main_user_id);
              }}
            >
              {/* <img src={item?.profile_image} /> */}
              {/* <h6 className="user-name-h6">{item?.full_name}</h6> */}
              {/* <h6>{item?.full_name?.length > 6 ? `${item.full_name.substring(0, 6)}...` : item.full_name}</h6> */}

            </div>
            {/* <a
              onClick={() => {
                setTemplateData(item);
                setIsTemplateDetailsModalOpen(true);
              }}
            >
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/images/info.svg"
                }
                className="infoImg"
              />
            </a> */}
          </div>
        </div>
      </div>
    </>
  );
}

const Invited_project = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [invitedTemplate, setInvitedTemplate] = useState([]);
  const [isTemplateDetailsModalOpen, setIsTemplateDetailsModalOpen] =
    useState(false);

  const [templateData, setTemplateData] = useState({});

  const userinfo = useCallback(
    (id) => {
      navigate(`/designer-profile/${id}`);
    },
    [navigate]
  );

  const editTemplate = useCallback(
    (data) => {
      navigate(`/edit-template/${data?.template_id}`, { state: "invitedProject" });
    },
    [navigate]
  );

  useEffect(() => {
    getInvitedTemplate({}).then((response) => {
      if (response.code === 200) {
        setInvitedTemplate(response.data);
      }
    });
  }, []);

  return (
    <main>
      <Innerheader />
      <div className="wrapper">
        <Sidebar />
        {isTemplateDetailsModalOpen && (
          <TemplateDetailsModal
            data={templateData || {}}
            setTemplateData={setTemplateData}
            isTemplateDetailsModalOpen={isTemplateDetailsModalOpen}
            setIsTemplateDetailsModalOpen={setIsTemplateDetailsModalOpen}
          // setIsShareModalOpen={setIsShareModalOpen}
          />
        )}

        {/* {isShareModalOpen && (
          <ShareModal
            isShareModalOpen={isShareModalOpen}
            setIsShareModalOpen={setIsShareModalOpen}
          />
        )} */}
        <div className="content flex-grow-1">
          <section className="category_main_title">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 mb-4 d-flex justify-content-between align-items-center flex-wrap">
                  <h2 className="section_title">{t("teamProjects")}</h2>
                </div>
                <div className="col-12">
                  <div className="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4">
                      <div class="ul_list">
                        {invitedTemplate.length ? (
                          invitedTemplate?.map((item) => {
                            return (
                              <li className="position-relative">
                                {/* <div className="col-xl-2 col-lg-3 col-md-6 col-sm-12 col-12 mb-4"> */}
                                {/* <div className="overlay_hover">
                                  <a className="template_image ">
                                    {item?.is_video == 1 && item?.video_url ? (
                                      <video
                                        src={item?.video_url}
                                        // controls
                                        autoPlay
                                        loop
                                        muted
                                        style={{ objectFit: 'contain', height: '100%', width: 'auto' }}
                                      ></video>
                                    ) : (
                                      <img src={item?.thumbnail} />
                                    )}
                                  </a>
                                  <div className="overlay_div">
                                    <div className={`d-flex ${item?.is_video == 1 ? "justify-content-between" : "justify-content-end"}`}>
                                      {item?.is_video == 1 && (
                                        <a >
                                          <img
                                            src={process.env.PUBLIC_URL + "/assets/images/icon/video-pause.svg"}
                                          />
                                        </a>
                                      )}
                                      <div className="top_li">
                                        <a
                                          onClick={() => {
                                            editTemplate(item);
                                          }}
                                        >
                                          <img src="assets/images/edit.svg" />
                                        </a>
                                      </div>
                                    </div>
                                    <div className="bottom_li">
                                      <div
                                        className="user_name cursor-pointer"
                                        onClick={() => {
                                          userinfo(item?.main_user_id);
                                        }}
                                      >
                                        <h6 className="user-name-h6">{item?.full_name}</h6>
                                      </div>
                                      <a
                                        onClick={() => {
                                          setTemplateData(item);
                                          setIsTemplateDetailsModalOpen(true);
                                        }}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/assets/images/info.svg"
                                          }
                                          className="infoImg"
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div> */}
                                <VideoComponent item={item} editTemplate={editTemplate} userinfo={userinfo} setTemplateData={setTemplateData} setIsTemplateDetailsModalOpen={setIsTemplateDetailsModalOpen} />
                                {/* </div> */}
                              </li>
                            );
                          })
                        ) : (
                          <div
                            style={{ height: "100px" }}
                            className="fw-light d-flex justify-content-center align-items-center  w-100"
                          >
                            {t("noDataFound")}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </main>
  );
};

export default Invited_project;
