import { configureStore } from '@reduxjs/toolkit';
import changeLanguageReducer from './slice/Changelanguage';


const store = configureStore({
    reducer: {
        changelanguage: changeLanguageReducer
    },
});

export default store;