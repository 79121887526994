import React, { useCallback, useState, useEffect, useRef } from "react";
import OwlCarousel from "react-owl-carousel";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import ShareModal from "../../../components/ShareModal";
import TemplateDetailsModal from "../../../components/TemplateDetailsModal";
import { getToken } from "../../../Common/LocalStorageService";

const VideoComponent = ({ item, setShareId, setIsShareModalOpen, editTemplate, userinfo, setTemplateData, setIsTemplateDetailsModalOpen }) => {
    const videoRef = useRef(null);

    // Event handlers for hover
    const handleMouseEnter = (e) => {
        if (videoRef && videoRef.current) {
            // setIsPlaying(index);
            videoRef.current.play(); // Start playing the video
        }
    };

    const handleMouseLeave = (e) => {
        if (videoRef && videoRef.current) {
            // setIsPlaying(-1);
            videoRef.current.pause(); // Pause the video
        }
    };
    useEffect(() => {
        if (videoRef && videoRef.current) {
            videoRef.current.load(); // Ensure video is loaded
        }
    }, [item?.video_url]);
    return (
        <>
            {item?.is_video == 1 && (
                <a className="video_show2">
                    <img
                        src={
                            process.env.PUBLIC_URL +
                            "/assets/images/icon/video-pause.svg"
                        }
                    />
                </a>
            )}
            <div className="overlay_hover hoverMyproject" onMouseEnter={(e) => { handleMouseEnter(e) }}
                onMouseLeave={(e) => { handleMouseLeave(e) }}
                onClick={(e) => {
                    e.stopPropagation()
                    setTemplateData(item);
                    setIsTemplateDetailsModalOpen(true);
                }}
            >
                <a className="template_image ">
                    {item?.is_video == 1 && item?.video_url ? (
                        <video
                            src={item?.video_url}
                            ref={videoRef}
                            loop
                            muted
                            playsInline
                            preload="auto"
                            style={{ objectFit: 'contain', height: '100%', width: 'auto' }}
                        ></video>
                    ) : (
                        <img src={item?.thumbnail} />
                    )}
                </a>
                <div className="overlay_div">
                    <div className={`d-flex justify-content-end`}>
                        {/* {item?.is_video == 1 && (
                            <a >
                                <img className="video_icon"
                                    src={process.env.PUBLIC_URL + "/assets/images/icon/video-pause.svg"}
                                />
                            </a>
                        )} */}
                        <div className="top_li">
                            <a
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setShareId(item?.id);
                                    setIsShareModalOpen(true);
                                }}
                            >
                                <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/share.svg"
                                    }
                                />
                            </a>
                            <a
                                onClick={(e) => {
                                    e.stopPropagation()
                                    editTemplate(item);
                                }}
                            >
                                <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/edit.svg"
                                    }
                                />
                            </a>
                        </div>
                    </div>
                    <div className="bottom_li">
                        <div
                            className="user_name cursor-pointer"
                            onClick={(e) => { e.stopPropagation(); userinfo(item?.user_id) }}
                        >
                            {/* <img src={item?.profile_image} /> */}
                            {/* <h6>{item?.full_name}</h6> */}
                        </div>
                        {/* <a
                            onClick={() => {
                                setTemplateData(item);
                                setIsTemplateDetailsModalOpen(true);
                            }}
                        >
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/assets/images/info.svg"
                                }
                                className="infoImg"
                            />
                        </a> */}
                    </div>
                </div>
            </div>
        </>
    );
}
const AllSquareTemplateList = (props) => {
    const { data, isDesigner = false, setIsLogin } = props;
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const { id } = useParams();

    const [carouselKey, setCarouselKey] = useState(0); // State variable for forcing re-render
    const [isTemplateDetailsModalOpen, setIsTemplateDetailsModalOpen] =
        useState(false);
    const [isShareModalOpen, setIsShareModalOpen] = useState(false);
    const [shareId, setShareId] = useState();
    const [templateData, setTemplateData] = useState({});

    useEffect(() => {
        // Update the key to force re-render when data.templates changes
        setCarouselKey((key) => key + 1);
    }, [data]);

    const userinfo = useCallback(
        (id) => {
            navigate(`/designer-profile/${id}`);
        },
        [navigate]
    );

    const editTemplate = useCallback(
        (data) => {
            if (getToken()) {
                navigate(`/copy-template/${data?.id}`);
            } else {
                setIsLogin(true);
            }
        },
        [navigate, setIsLogin]
    );
    return (
        <section className="category_main_title">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 mb-2 d-flex justify-content-between align-items-center flex-wrap">
                        <h2 className="section_title">{data?.name}</h2>
                        {/* {data?.length > 0 && (
                            <Link
                                to={
                                    isDesigner
                                        ? `/designer/${id}/template/category/${data?.id}`
                                        : `/template/category/${data?.id}`
                                }
                                className="view_all"
                            >
                                {t("viewAll")}
                            </Link>
                        )} */}
                    </div>
                    <div className="col-12">
                        <div className="row">
                            {data?.length ? (
                                data?.map((item) => {
                                    return (
                                        <div className="col-xl-2 col-lg-3 col-md-6 col-sm-4 col-6 mb-4 position-relative">
                                            <VideoComponent item={item} setShareId={setShareId} setIsShareModalOpen={setIsShareModalOpen} editTemplate={editTemplate} userinfo={userinfo} setTemplateData={setTemplateData} setIsTemplateDetailsModalOpen={setIsTemplateDetailsModalOpen} />
                                        </div>
                                    );
                                })
                            ) : (
                                <div
                                    style={{ height: "100px" }}
                                    className="fw-light d-flex justify-content-center align-items-center  w-100"
                                >
                                    {t("noDataFound")}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {isShareModalOpen && (
                <ShareModal
                    isShareModalOpen={isShareModalOpen}
                    shareId={shareId}
                    setIsShareModalOpen={setIsShareModalOpen}
                />
            )}
            {isTemplateDetailsModalOpen && (
                <TemplateDetailsModal
                    data={templateData || {}}
                    setTemplateData={setTemplateData}
                    isTemplateDetailsModalOpen={isTemplateDetailsModalOpen}
                    setShareId={setShareId}
                    setIsTemplateDetailsModalOpen={setIsTemplateDetailsModalOpen}
                    setIsShareModalOpen={setIsShareModalOpen}
                    setIsLogin={setIsLogin}
                />
            )}
        </section>
    );
};

export default AllSquareTemplateList;
