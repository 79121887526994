import React, { useCallback, useState, useEffect, useRef } from "react";
import OwlCarousel from "react-owl-carousel";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import ShareModal from "../../../components/ShareModal";
import TemplateDetailsModal from "../../../components/TemplateDetailsModal";
import { getToken } from "../../../Common/LocalStorageService";
import { useFilter } from "../../../Common/FilterContex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import VideoPlay from "../../../components/VideoPlay";


const Instagramreels = (props) => {
  const { data, isDesigner = false, setIsLogin } = props;
  console.log("data44444: ", data);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const { prevFilters, setPrevFilters } = useFilter();

  const [carouselKey, setCarouselKey] = useState(0); // State variable for forcing re-render
  const [isTemplateDetailsModalOpen, setIsTemplateDetailsModalOpen] =
    useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [shareId, setShareId] = useState();
  const [templateData, setTemplateData] = useState({});
  console.log("templateData: ", templateData);
  const [canGoNext, setCanGoNext] = useState(false);
  const [canGoPrev, setCanGoPrev] = useState(false);
  const [isPlaying, setIsPlaying] = useState(-1);
  const videoRef = useRef(null);

  // Event handlers for hover
  const handleMouseEnter = (e, index) => {
    console.log('handleMouseEnter: ', index);
    if (videoRef && videoRef.current) {
      setIsPlaying(index);
      videoRef.current.play(); // Start playing the video
    }
  };

  const handleMouseLeave = (e, index) => {
    console.log('handleMouseEnter: 55664564', index);
    if (videoRef && videoRef.current) {
      setIsPlaying(-1);
      videoRef.current.pause(); // Pause the video
    }
  };
  const goToPrev = () => {
    if (carouselRef && carouselRef?.current) {
      carouselRef.current.prev();
    }
  };

  const goToNext = () => {
    if (carouselRef && carouselRef?.current) {
      carouselRef.current.next();
    }
  };

  const checkNavButtons = () => {
    if (carouselRef && carouselRef?.current) {
      console.log("carouselRef?.current: ", carouselRef?.current);
      const current = carouselRef?.current?.state?.current;
      const items = carouselRef?.current?.state?.items;
      const totalItems = data?.templates?.length || 0;

      setCanGoPrev(current > 0);
      setCanGoNext(current + items < totalItems);
    }
  };

  useEffect(() => {
    if (carouselRef && carouselRef?.current) {
      checkNavButtons();
    }
  }, [data, carouselKey]);
  // const goToPrev = () => {
  //   if (carouselRef.current) {
  //     carouselRef.current.prev();
  //   }
  // };

  // const goToNext = () => {
  //   if (carouselRef.current) {
  //     carouselRef.current.next();
  //   }
  // };
  useEffect(() => {
    // Update the key to force re-render when data.templates changes
    setCarouselKey((key) => key + 1);
  }, [data.templates]);

  const userinfo = useCallback(
    (id) => {
      navigate(`/designer-profile/${id}`);
    },
    [navigate]
  );
  const addQueryParams = (id) => {
    const params = new URLSearchParams(window.location.search);
    params.set("c2hhcmU", id);

    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${params}`
    );
  };
  const editTemplate = useCallback(
    (data) => {
      if (getToken()) {
        navigate(`/copy-template/${data?.id}`);
      } else {
        addQueryParams(data?.id);
        setIsLogin(true);
      }
    },
    [navigate, setIsLogin]
  );
  const carouselRef = useRef(null);
  console.log("carouselRef: ", carouselRef?.current);
  const ComponentDDD = useCallback(
    (props) => {
      const { item, index } = props;
      console.log("item147: ", item, item?.is_video);
      return (
        <div
          key={"template_in" + item?.name + "_" + index}
          className={`item ${item?.orientation == "landscape"
            ? "landscapeWidth"
            : "templateWidth"
            }`}
          // style={{ padding: "3%" }}
          onMouseEnter={(e) => { handleMouseEnter(e, index) }}
          onMouseLeave={(e) => { handleMouseLeave(e, index) }}

        >
          <div className="overlay_hover">
            <a className="template_image_slider ">
              {/* position-relative */}
              {item?.is_video == 1 && item?.video_url ? (
                <VideoPlay item={item} />
                // <video
                //   autoPlay={true}
                //   src={item?.video_url}
                //   // controls
                //   // loop
                //   // muted
                //   ref={videoRef}
                //   // src={item?.video_url}
                //   loop
                //   muted
                //   style={{
                //     objectFit: "contain",
                //     height: "100%",
                //     width: "100%",
                //   }}
                // ></video>
              ) : (
                <img src={item?.thumbnail} />
              )}
            </a>
            <div className="overlay_div">
              <div
                className={`d-flex ${item?.is_video == 1
                  ? "justify-content-between"
                  : "justify-content-end"
                  }`}
              >
                {item?.is_video == 1 && (
                  <a>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/icon/video-pause.svg"
                      }
                    />
                  </a>
                )}
                <div className="top_li">
                  <a
                    onClick={() => {
                      setShareId(item?.id);
                      setIsShareModalOpen(true);
                    }}
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/assets/images/share.svg"}
                    />
                  </a>
                  <a
                    onClick={() => {
                      editTemplate(item);
                    }}
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/assets/images/edit.svg"}
                    />
                  </a>
                </div>
              </div>
              <div className="bottom_li">
                <div
                  className="user_name cursor-pointer"
                  onClick={() => userinfo(item?.user_id)}
                >
                  {/* <img src={item?.profile_image} /> */}
                  {/* <h6>{item?.full_name}</h6> */}
                  {/* <h6 className="user-name-h6">
                    {item?.full_name}
                  </h6> */}
                </div>
                <a
                  onClick={() => {
                    setTemplateData(item);
                    setIsTemplateDetailsModalOpen(true);
                  }}
                >
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/info.svg"}
                    className="infoImg"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      );
    },
    [editTemplate, userinfo]
  );

  return (
    // <section className="category_main_title">
    <>
      {/* <OwlCarousel
        margin={10}
        loop={true}
        autoWidth={true}
        item={4}
        key={carouselKey + 1}
      >
        <div className="item" style={{ width: 250, background: "red" }}><h4>1</h4></div>
        <div className="item" style={{ width: 100, background: "yellow" }}><h4>2</h4></div>
        <div className="item" style={{ width: 500, background: "green" }}><h4>3</h4></div>
        <div className="item" style={{ width: 100, background: "pink" }}><h4>4</h4></div>
        <div className="item" style={{ width: 50, background: "red" }}><h4>6</h4></div>
        <div className="item" style={{ width: 250, background: "yellow" }}><h4>7</h4></div>
        <div className="item" style={{ width: 120, background: "black" }}><h4>8</h4></div>
        <div className="item" style={{ width: 420, background: "blue" }}><h4>9</h4></div>
        <div className="item" style={{ width: 120, background: "green" }}><h4>10</h4></div>
        <div className="item" style={{ width: 300, background: "gray" }}><h4>11</h4></div>
        <div className="item" style={{ width: 450, background: "pink" }}><h4>12</h4></div>
        <div className="item" style={{ width: 220, background: "red" }}><h4>13</h4></div>
        <div className="item" style={{ width: 150, background: "yellow" }}><h4>14</h4></div>
        <div className="item" style={{ width: 600, background: "blue" }}><h4>15</h4></div>
      </OwlCarousel> */}
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 mb-2 d-flex justify-content-between align-items-center">
            {/* flex-wrap */}
            <h2 className="section_title">{data?.name}</h2>
            {data?.templates?.length > 0 && (
              <Link
                to={
                  getToken() && (isDesigner
                    ? `/designer/${id}/template/category/${data?.id}`
                    : `/template/category/${data?.id}`)
                }
                onClick={() => {
                  setPrevFilters({});
                  if (!getToken()) {
                    setIsLogin(true)
                  }
                }}
                style={{ whiteSpace: "nowrap", textDecoration: "none" }}
                className="view_all"
              >
                <p className="mobile_hide">{t("viewAll")}</p>
                <img
                  className="mobile_show"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/arrow-right-blue.svg"
                  }
                  alt=""
                ></img>
              </Link>
            )}
          </div>
          <FontAwesomeIcon icon="fa - solid fa - circle - left" />

          <div className="col-12 carousel-container">
            {data?.templates?.length ? (
              <>
                {/* <button onClick={goToPrev} className="prev-btn">Prev</button> */}
                {carouselRef && carouselRef?.current?.props?.nav && (
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/images/rating/left-white.svg"
                    }
                    role="presentation"
                    alt="prev-btn mobile_hide"
                    className="prev-btn"
                    onClick={goToPrev}
                  />
                )}
                <OwlCarousel
                  ref={carouselRef}
                  nav={true}
                  // navText={["<div class='nav-btn prev-slide'></div>", "<div class='nav-btn next-slide'></div>"]}
                  navText={["", ""]}
                  // navElement="div"
                  // navContainerClass="owl-nav"
                  // navClass={['owl-prev', 'owl-next']}
                  key={carouselKey} // Force re-render when carouselKey changes
                  className="owl-carousel owl-theme template_slider"
                  autoWidth={true}
                  dots={true}
                  responsive={{
                    0: {
                      items: 2,
                    },
                    600: {
                      items: 3,
                    },
                    1100: {
                      items: 4,
                    },
                    1300: {
                      items: 6,
                    },
                  }}
                >
                  {data?.templates?.map((item, index) => (
                    <div key={item.id} className="item ">
                      <VideoPlay item={item} index={index} setShareId={setShareId} setIsShareModalOpen={setIsShareModalOpen} editTemplate={editTemplate} userinfo={userinfo} setTemplateData={setTemplateData} setIsTemplateDetailsModalOpen={setIsTemplateDetailsModalOpen} />
                      {/* <ComponentDDD item={item} index={index} /> */}
                    </div>
                  ))}
                </OwlCarousel>
                {carouselRef && carouselRef?.current?.props?.nav && (
                  <button>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/rating/right-white.svg"
                      }
                      alt="next-btn mobile_hide"
                      className="next-btn"
                      onClick={goToNext}
                    />
                  </button>
                )}
                {/* <button onClick={goToNext} className="next-btn">Next</button> */}
              </>
            ) : (
              <div
                style={{ height: "100px" }}
                className="fw-light d-flex justify-content-center align-items-center  w-100"
              >
                {t("noDataFound")}
              </div>
            )}
          </div>
        </div>
      </div>
      {isShareModalOpen && (
        <ShareModal
          isShareModalOpen={isShareModalOpen}
          shareId={shareId}
          setIsShareModalOpen={setIsShareModalOpen}
        />
      )}
      {isTemplateDetailsModalOpen && (
        <TemplateDetailsModal
          data={templateData || {}}
          setTemplateData={setTemplateData}
          isTemplateDetailsModalOpen={isTemplateDetailsModalOpen}
          setShareId={setShareId}
          setIsTemplateDetailsModalOpen={setIsTemplateDetailsModalOpen}
          setIsShareModalOpen={setIsShareModalOpen}
          setIsLogin={setIsLogin}
        />
      )}
    </>
    // </section>
  );
};

export default Instagramreels;
