import React, { useState, useEffect } from "react";
import {
  getSubscriptionUpdateDetails,
  subscripPayment,
} from "../../../api/apiHandler";
import { useNavigate, Link, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import $ from "jquery";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import {
  getUserDataFromLocal,
  saveUserDataToLocal,
} from "../../../Common/LocalStorageService";
import { showPromiseNotification } from "../../../Common/notification";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import Lottie from "react-lottie";
import loadingAnimation from '../../../Common/notification'

const stripePromise = loadStripe(process.env.REACT_APP_PUBLISH_KEY);

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      lineHeight: "27px",
      color: "#212529",
      fontSize: "1.1rem",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const CheckoutForm = (props) => {
  const { selectedPlan, planDuration, setPaymentModalOpen } = props;
  const user = getUserDataFromLocal();
  console.log('user: ', user);
  const { state } = useLocation();
  const navigate = useNavigate();
  const subscribe = state;
  const stripe = useStripe();
  const elements = useElements();
  const milestoneobj = {};
  const [isLoading, setIsLoading] = useState(false);
  const [updatePlan, setUpdatePlan] = useState({});
  console.log('updatePlan: ', updatePlan, updatePlan?.amount_due);
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(true);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("nameIsRequired")),
    email: Yup.string().email(t("emailInvalid")).required(t("emailRequired")),
    cardNumber: Yup.number().required(t("cardNumberRequired")),
    cardExpiry: Yup.number().required(t("cardExpiryRequired")),
    cardCvc: Yup.number().required(t("cardCvcRequired")),
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  console.log("errors: ", errors);

  const onSubmit = async (data) => {
    setIsLoading(true);

    if (!stripe || !elements) {
      Swal.fire(
        "Error",
        "Stripe has not loaded properly. Please try again.",
        "error"
      );
      setIsLoading(false);
      return;
    }

    try {
      const result = await stripe.createToken(
        elements.getElement(CardNumberElement),
        { name: "Pro" || subscribe?.plan_name }
      );

      if (result.error) {
        Swal.fire("Error", result.error.message, "error");
        setIsLoading(false);
      } else {
        const myPromise = new Promise(async (resolve, reject) => {
          const paymentMethodObj = {
            type: "card",
            card: elements.getElement(CardNumberElement),
            billing_details: {
              name: data.name,
              email: data.email,
            },
          };
          const paymentMethodResult = await stripe.createPaymentMethod(
            paymentMethodObj
          );
          const payMethod = paymentMethodResult.paymentMethod;
          milestoneobj.card_detail = result.token;
          milestoneobj.paymethod = payMethod;
          milestoneobj.price_id =
            planDuration === "monthly"
              ? selectedPlan?.monthly_price_id
              : selectedPlan?.yearly_price_id;
          milestoneobj.subscription_id = selectedPlan?.id;

          subscripPayment(milestoneobj).then((response) => {
            if (response.code === 200) {
              saveUserDataToLocal(response?.data);
              setPaymentModalOpen(false);
              navigate("/home");
              resolve(response);
            } else {
              reject(response);
            }
          });
        });
        showPromiseNotification(myPromise);
      }
    } catch (error) {
      Swal.fire(
        "Error",
        "An error occurred during the payment process.",
        "error"
      );
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (user?.is_subscribed) {
      setIsLoading(true);
      getSubscriptionUpdateDetails({
        price_id:
          planDuration === "monthly"
            ? selectedPlan?.monthly_price_id
            : selectedPlan?.yearly_price_id,
        subscription_id: selectedPlan?.id,
      }).then((response) => {
        if (response.code === 200) {
          setUpdatePlan(response.data);
          setIsLoading(false);
        }
      });
    } else {
      setUpdatePlan({
        amount_remaining:
          planDuration === "monthly"
            ? selectedPlan?.price_monthly * 100
            : selectedPlan?.price_yearly * 100,
      });
    }
  }, [
    planDuration,
    selectedPlan?.id,
    selectedPlan?.monthly_price_id,
    selectedPlan?.price_monthly,
    selectedPlan?.price_yearly,
    selectedPlan?.yearly_price_id,
    user?.is_subscribed,
  ]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row justify-content-center">
        <h6 className="mb-3 text-center">{t("cardDetailsPay")}</h6>
        <div className="col-12 mb-3">
          <div className="title-text text-center">
            <div className="login_form">
              <div className="row">
                <div className="col-12 mb-3">
                  <div className="form-group text-start">
                    <label htmlFor="name" className="form-label">
                      {t("name")}
                    </label>
                    <input
                      id="name"
                      type="text"
                      className="form-control"
                      placeholder={t("name")}
                      {...register("name")}
                    />
                    {errors.name && (
                      <div className="text-danger">{errors.name.message}</div>
                    )}
                  </div>
                </div>
                <div className="col-12 mb-3">
                  <div className="form-group text-start">
                    <label className="form-label">{t("email")}</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t("email")}
                      {...register("email")}
                    />
                    {errors.email && (
                      <div className="text-danger">{errors.email.message}</div>
                    )}
                  </div>
                </div>
                <div className="col-12 mb-3">
                  <div className="form-group text-start">
                    <label className="form-label">{t("cardNumber")}</label>
                    <Controller
                      control={control}
                      name="cardNumber"
                      render={({ field }) => (
                        <CardNumberElement
                          id="cc-number"
                          className="form-control custom-card"
                          options={CARD_ELEMENT_OPTIONS}
                          onChange={(event) => {
                            setError("cardNumber", event?.error);
                            if (event.complete) {
                              setValue("cardNumber", 123);
                            }
                          }}
                        />
                      )}
                    />
                    {errors.cardNumber && (
                      <div className="text-danger">
                        {errors.cardNumber.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-6 mb-3">
                  <div className="form-group text-start w-100">
                    <label className="form-label">{t("cardExpiry")}</label>
                    <Controller
                      control={control}
                      name="cardExpiry"
                      render={({ field }) => (
                        <CardExpiryElement
                          id="expiry"
                          className="form-control custom-card"
                          options={CARD_ELEMENT_OPTIONS}
                          onChange={(event) => {
                            setError("cardExpiry", event?.error);
                            if (event.complete) {
                              setValue("cardExpiry", 123);
                            }
                          }}
                        />
                      )}
                    />
                    {errors.cardExpiry && (
                      <div className="text-danger">
                        {errors.cardExpiry.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-6 mb-3">
                  <div className="form-group text-start">
                    <label className="form-label">{t("cardCVV")}</label>
                    <Controller
                      control={control}
                      name="cardCvc"
                      render={({ field }) => (
                        <CardCvcElement
                          id="cvc"
                          className="form-control custom-card"
                          options={CARD_ELEMENT_OPTIONS}
                          onChange={(event) => {
                            setError("cardCvc", event?.error);
                            if (event.complete) {
                              setValue("cardCvc", 123);
                            }
                          }}
                        />
                      )}
                    />
                    {errors.cardCvc && (
                      <div className="text-danger">
                        {errors.cardCvc.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 mb-3">
                  <div className="form-group text-start left_fixed">
                    <button
                      type="submit"
                      disabled={isLoading}
                      className="btn btn-theme"
                    >
                      {/* {user?.is_subscribed == 0 ? (
                        !updatePlan && updatePlan?.amount_due == undefined ? (
                          ((user?.is_subscription_cancel
                            ? updatePlan?.amount_due
                            : updatePlan?.amount_remaining) / 100).toFixed(2)
                        ) : (
                          t("pleaseWait")
                        )
                      ) : (
                        <>
                          {t("pay")} $
                          {((user?.is_subscription_cancel
                            ? updatePlan?.amount_due
                            : updatePlan?.amount_remaining) / 100).toFixed(2)}
                        </>
                      )} */}


                      {/* old  */}
                      {/* {updatePlan && updatePlan?.amount_due !== undefined ? (
                        <> */}
                      {t("pay")} $
                      {(
                        (user?.is_subscription_cancel
                          ? updatePlan?.amount_due
                          : updatePlan?.amount_remaining) / 100
                      ).toFixed(2)}
                      {/* </>
                      ) : (
                        <>
                          {t("pleaseWait")}
                        </>
                      )} */}
                      {/* {(
                        (user?.is_subscription_cancel
                          ? updatePlan?.amount_due
                          : updatePlan?.amount_remaining) / 100
                      ).toFixed(2)} */}
                    </button>
                  </div>
                  {(
                    (user?.is_subscription_cancel
                      ? updatePlan?.amount_due
                      : updatePlan?.amount_remaining) / 100
                  ).toFixed(2) < 0 && (
                      <div class="alert alert-warning mt-2 mb-0 " role="alert">
                        {t("amountAdjusted")}
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

function FinalSubPayment(props) {
  const { paymentModalOpen, setPaymentModalOpen, selectedPlan, planDuration } =
    props;
  console.log("selectedPlan: ", selectedPlan);
  return (
    <>
      {/* <main> */}
      <div
        className={`modal ${paymentModalOpen ? "fade show d-block " : "d-none"
          }`}
        tabIndex={-1}
        id="become_designer"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="custom_btn"
                // data-bs-dismiss="modal"
                // aria-label="Close"
                onClick={() => {
                  setPaymentModalOpen(false);
                  // reset();
                }}
              >
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/close.svg"}
                />
              </button>
              <div className="mobile_icon text-center mb-2">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/11668732_20945314.svg"
                  }
                />
              </div>
              <Elements stripe={stripePromise}>
                <CheckoutForm
                  selectedPlan={selectedPlan}
                  planDuration={planDuration}
                  paymentModalOpen={paymentModalOpen}
                  setPaymentModalOpen={setPaymentModalOpen}
                />
              </Elements>
            </div>
          </div>
        </div>
      </div>
      {paymentModalOpen && <div className="modal-backdrop fade show"></div>}

      {/* </main> */}
    </>
  );
}

export default FinalSubPayment;
