import { history } from "./helpers";

export function loginRedirectCall() {
  // let path= window.location.protocol+"//"+window.location.host+"/admin"
  //    window.open(path,'mywindow').focus()
  // console.log(path)
  // window.location.pathname = "/";
}

export function logOutRedirectCall() {
  // console.log('history: ', history);
  // console.log("inside logout redirect");
  localStorage.clear();
  history.navigate("/");
  // window.location.reload();
  // loginRedirectCall();
}


