import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import checkValidation from "../../../../validation/ValidationSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { resetPassword } from "../../../../api/apiHandler";
import { showPromiseNotification } from "../../../../Common/notification";

const ResetPassword = (props) => {
  const { resetpassword, closeResetModal, loginResponse } = props;
  const { t, i18n } = useTranslation();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isconfirmPasswordVisible, setconfirmPasswordVisible] = useState(false);

  const schema4 = checkValidation(["NewPassword", "Confirm_New_Password"]);
  const {
    register: register4,
    handleSubmit: handleSubmit4,
    reset: reset4,
    formState: { errors: errors4 },
  } = useForm({
    resolver: yupResolver(schema4),
  });

  const onSubmit4 = (data) => {
    console.log('data: ', data);
    const payload = {
      email: loginResponse?.email,
      password: data?.NewPassword,
    };

    const myPromise = new Promise((resolve, reject) =>
      resetPassword(payload).then((response) => {
        console.log("response: ", typeof response, response);
        if (response.code == 200) {
          resolve(response);
          closeResetModal();
          reset4();
        } else {
          reject(response);
        }
      })
    );
    showPromiseNotification(myPromise);
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prevState) => !prevState);
  };

  const toggleconfirmPasswordVisibility = () => {
    setconfirmPasswordVisible((prevState) => !prevState);
  };

  return (
    <>
      <div
        className={`modal fade ${resetpassword ? "show" : ""}`}
        id="reset_password"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
        style={{ display: resetpassword ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="custom_btn"
                onClick={closeResetModal}
                aria-label="Close"
              >
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/close.svg"}
                />
              </button>
              <div className="modal_forgot">
                <h6>{t("resetPasswords")}</h6>
                <p>{t("newPasswordDescription")}</p>
                <form onSubmit={handleSubmit4(onSubmit4)}>
                  <div className="login_form mt-4">
                    <div className="row">
                      <div className="col-12 mb-3">
                        <div className="form-group left_fixed">
                          <input
                            type={isPasswordVisible ? "text" : "password"}
                            className="form-control"
                            placeholder={t("newPassword")}
                            {...register4("NewPassword")}
                          />
                          <img
                            src={
                              process.env.PUBLIC_URL + "/assets/images/lock.svg"
                            }
                          />
                          <img
                            onClick={togglePasswordVisibility}
                            src={
                              isPasswordVisible
                                ? process.env.PUBLIC_URL +
                                "/assets/images/eye-close-line.svg"
                                : process.env.PUBLIC_URL +
                                "/assets/images/eye_open.svg"
                            }
                            alt={
                              isPasswordVisible
                                ? "Hide password"
                                : "Show password"
                            }
                            className="close_eye"
                          />
                        </div>
                        {errors4.NewPassword && (
                          <span className="text-danger">
                            {errors4.NewPassword.message}
                          </span>
                        )}
                      </div>
                      <div className="col-12 mb-3">
                        <div className="form-group left_fixed">
                          <input
                            type={
                              isconfirmPasswordVisible ? "text" : "password"
                            }
                            className="form-control"
                            placeholder={t("confirmPassword")}
                            {...register4("Confirm_New_Password")}
                          />
                          <img
                            src={
                              process.env.PUBLIC_URL + "/assets/images/lock.svg"
                            }
                          />
                          <img
                            onClick={toggleconfirmPasswordVisibility}
                            src={
                              isconfirmPasswordVisible
                                ? process.env.PUBLIC_URL +
                                "/assets/images/eye-close-line.svg"
                                : process.env.PUBLIC_URL +
                                "/assets/images/eye_open.svg"
                            }
                            alt={
                              isconfirmPasswordVisible
                                ? "Hide password"
                                : "Show password"
                            }
                            className="close_eye"
                          />
                        </div>
                        {errors4.Confirm_New_Password && (
                          <span className="text-danger">
                            {" "}
                            {errors4.Confirm_New_Password.message}
                          </span>
                        )}
                      </div>
                      <div className="col-12 mb-3">
                        <div className="form-group left_fixed">
                          <button type="submit" className="btn btn-theme">
                            Verify
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal-backdrop fade show"></div>
    </>
  );
};

export default ResetPassword;
