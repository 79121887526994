import React from "react";
import { Helmet } from "react-helmet";

const Jquaryfixedheader = () => {
  return (
    <Helmet>
      <script src="https://code.jquery.com/jquery-3.6.0.min.js"></script>
      <script>
        {`
       
            $(document).on("scroll", function () {
                if
                    ($(document).scrollTop() > 90) {
                    $(".header-main").addClass("fixed-top");
                }
                else {
                    $(".header-main").removeClass("fixed-top");
                }
            });
      `}
      </script>
    </Helmet>
  );
};

export default Jquaryfixedheader;
