import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import checkValidation from "../validation/ValidationSchema";
import { becomeDesigner } from "../api/apiHandler";
import { showPromiseNotification } from "../Common/notification";

const BecomeDesigner = (props) => {
  const { bocomeDesignerModalOpen, setBocomeDesignerModalOpen } = props;

  const { t, i18n } = useTranslation();
  const schema = checkValidation(["PortfolioLink", "Description"]);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    // navigate('/home');

    const payload = {
      about: data?.Description,
      portfolio_link: data?.PortfolioLink,
    };

    const myPromise = new Promise((resolve, reject) =>
      becomeDesigner(payload).then((response) => {
        if (response.code === 200) {
          reset();
          setBocomeDesignerModalOpen(false);
          resolve(response);
        } else {
          reject(response);
        }
      })
    );
    showPromiseNotification(myPromise);
  };

  return (
    <>
      <div
        className={`modal ${bocomeDesignerModalOpen ? "fade show d-block " : "d-none"
          }`}
        tabIndex={-1}
        id="become_designer"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <button
                  type="button"
                  className="custom_btn"
                  // data-bs-dismiss="modal"
                  // aria-label="Close"
                  onClick={() => {
                    setBocomeDesignerModalOpen(false);
                    reset();
                  }}
                >
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/close.svg"}
                  />
                </button>
                <div className="modal_forgot">
                  <div className="mobile_icon text-center mb-2">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/images/designer.svg"
                      }
                    />
                  </div>
                  <h6>{t("becomeaDesigner")}</h6>
                  <div className="login_form mt-5">
                    <div className="row">
                      <div className="col-12 mb-3">
                        <div className="form-group left_fixed">
                          <textarea
                            className="form-control textare_height"
                            placeholder={t("portfolioLink")}
                            defaultValue={""}
                            {...register("PortfolioLink")}
                          />
                        </div>
                        {errors.PortfolioLink && (
                          <p
                            role="alert"
                            className="error-text m-1"
                            style={{ color: "red" }}
                          >
                            {errors.PortfolioLink.message}
                          </p>
                        )}
                      </div>

                      <div className="col-12 mb-3">
                        <div className="form-group left_fixed">
                          <textarea
                            className="form-control textare_height"
                            placeholder={t("aboutMe")}
                            defaultValue={""}
                            {...register("Description")}
                          />
                        </div>
                        {errors.Description && (
                          <p
                            role="alert"
                            className="error-text m-1"
                            style={{ color: "red" }}
                          >
                            {errors.Description.message}
                          </p>
                        )}
                      </div>
                      <div className="col-12 mb-3">
                        <div className="form-group left_fixed">
                          <button type="submit" className="btn btn-theme">
                            {t("submit")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      {bocomeDesignerModalOpen && (
        <div className="modal-backdrop fade show"></div>
      )}
    </>
  );
};

export default BecomeDesigner;
