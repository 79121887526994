import React, { useCallback, useEffect, useRef, useState } from "react";
import { getSimilarTemplate } from "../api/apiHandler";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getToken } from "../Common/LocalStorageService";
const VideoComponent = ({ val, setIsShareModalOpen, editTemplate, userinfo, setTemplateData, setIsTemplateDetailsModalOpen }) => {
  const videoRef = useRef(null);

  // Event handlers for hover
  const handleMouseEnter = (e) => {
    console.log('handleMouseEnter: ');
    if (videoRef && videoRef.current) {
      // setIsPlaying(index);
      videoRef.current.play(); // Start playing the video
    }
  };

  const handleMouseLeave = (e) => {
    console.log('handleMouseEnter: 55664564');
    if (videoRef && videoRef.current) {
      // setIsPlaying(-1);
      videoRef.current.pause(); // Pause the video
    }
  };
  useEffect(() => {
    if (videoRef && videoRef.current) {
      videoRef.current.load(); // Ensure video is loaded
    }
  }, [val?.video_url]);
  return (
    <>
      {val?.is_video == 1 && (
        <a className="video_show2">
          <img
            src={process.env.PUBLIC_URL + "/assets/images/icon/video-pause.svg"}
          />
        </a>
      )}
      <div className="overlay_hover" onMouseEnter={(e) => { handleMouseEnter(e) }}
        onMouseLeave={(e) => { handleMouseLeave(e) }}
        onClick={(e) => {
          e.stopPropagation()
          setTemplateData(val);
          setIsTemplateDetailsModalOpen(true);
        }}
      >
        <a className="template_image ">
          {val?.orientation == "portrait" ? (
            <div className="template_view_Main" >
              {val?.is_video == 1 && val?.video_url ? (
                <video
                  src={val?.video_url}
                  ref={videoRef}
                  loop
                  muted
                  playsInline
                  preload="auto"
                  style={{ objectFit: 'contain', height: '100%', width: 'auto' }}
                ></video>
              ) : (
                <img src={val?.thumbnail} style={{ height: "100%", width: 'auto' }} />
              )}
            </div>
          ) : (
            <div className="template_view_Main" >
              <div className="template_view">
                {val?.is_video == 1 && val?.video_url ? (
                  <video
                    src={val?.video_url}
                    ref={videoRef}
                    loop
                    muted
                    playsInline
                    preload="auto"
                    style={{ objectFit: 'contain', height: '100%', width: 'auto' }}
                  ></video>
                ) : (
                  <img src={val?.thumbnail} style={{ height: "100%", width: 'auto' }} />
                )}
              </div>
            </div>
          )}

        </a>
        <div className="overlay_div">
          <div className={`d-flex justify-content-end`}>
            {/* {val?.is_video == 1 && (
              <a >
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/icon/video-pause.svg"}
                />
              </a>
            )} */}
            <div className="top_li">
              <a
                onClick={(e) => {
                  e.stopPropagation()
                  setIsShareModalOpen(true);
                  // setIsTemplateDetailsModalOpen(false);
                }}
              >
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/share.svg"
                  }
                />
              </a>
              <a
                onClick={(e) => {
                  e.stopPropagation()
                  editTemplate(val);
                }}
              >
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/edit.svg"
                  }
                />
              </a>
            </div>
          </div>
          <div className="bottom_li">
            <div
              className="user_name cursor-pointer"
              onClick={(e) => { e.stopPropagation(); userinfo(val?.user_id) }}
            >
              {/* <img src={val?.profile_image} /> */}
              {/* <h6 className="user-name-h6">{val?.full_name}</h6> */}
              {/* <h6>{val?.full_name?.length > 6 ? `${val.full_name.substring(0, 6)}...` : val.full_name}</h6> */}
            </div>
            {/* <a
              onClick={() => {
                setTemplateData(val);
                setIsTemplateDetailsModalOpen(true);
              }}
            >
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/images/info.svg"
                }
                className="infoImg"
              />
            </a> */}
          </div>
        </div>
      </div>
    </>
  );
}
const TemplateDetailsModal = (props) => {
  const {
    data = {},
    isTemplateDetailsModalOpen,
    setIsTemplateDetailsModalOpen,
    setIsShareModalOpen,
    setTemplateData,
    setShareId,
    setIsLogin,
  } = props;
  const [similarTemplates, setSimilarTemplates] = useState([]);
  console.log('data: ', data);

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  console.log('location: ', location);
  const userinfo = useCallback(
    (id) => {
      navigate(`/designer-profile/${id}`);
    },
    [navigate]
  );
  const addQueryParams = (id) => {
    const params = new URLSearchParams(window.location.search);
    params.set('c2hhcmU', id);

    window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
  };
  const editTemplate = useCallback(
    (data) => {
      if (getToken()) {
        navigate(`/edit-template/${data?.id}`, { state: "invitedProject" });
      } else {
        addQueryParams(data?.id)
        setIsTemplateDetailsModalOpen(false);
        setIsLogin(true);
      }
    },
    [navigate, setIsLogin, setIsTemplateDetailsModalOpen]
  );

  useEffect(() => {
    const handleContextmenu = e => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
    }
  }, [])
  useEffect(() => {
    getSimilarTemplate({ template_id: data?.id, limit: 10 }).then((response) => {
      if (response.code === 200) {
        setSimilarTemplates(response?.data);
      }
    });
  }, [data?.id]);

  return (
    <>
      <div
        className={`modal fade ${isTemplateDetailsModalOpen ? "show d-block" : "d-none"
          }`}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="staticBackdropLabel"
        tabIndex={-1}
      >
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="custom_btn"
                onClick={() => {
                  setIsTemplateDetailsModalOpen(false);
                }}
              >
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/close.svg"}
                />
              </button>
              <div className="row">
                <div className="col-lg-6  col-sm-12 col-12 mb-3 mb-sm-0">
                  <div className="template_view_Main" >
                    <div className="template_view">
                      {/* <img src={data?.main_thumbnail || data?.thumbnail} className="img-fluid" /> */}
                      {data?.is_video == 1 && data?.video_url ? (
                        <video
                          src={data?.video_url}
                          // src={process.env.REACT_APP_S3_URL + "user/synrmbn_2024_08_28_17_36_55.mp4"}
                          autoPlay
                          playsInline
                          loop
                          muted
                          preload="none"
                          disablePictureInPicture
                          style={{ objectFit: 'contain', height: '100%', width: '100%' }}
                        ></video>
                      ) : (
                        <img src={data?.main_thumbnail || data?.thumbnail} className="img-fluid" alt="" />
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6  col-sm-12 col-12 mb-3 mb-sm-0">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="template_edit">
                        <div className="user_template">
                          <img src={data?.profile_image} />
                        </div>
                        <div className="user_temp_name me-5">
                          <span>{t("createdBy")}</span>
                          <h6>{data?.full_name}</h6>
                        </div>
                        <div className="user_option">
                          <div className="text-center mt-1">
                            {setIsShareModalOpen && (
                              <button
                                onClick={() => {
                                  setShareId(data?.id);
                                  setIsShareModalOpen(true);
                                  // setIsTemplateDetailsModalOpen(false);
                                }}
                                className="btn btn-outline share-hover me-2 "
                              >
                                <img
                                  className=""
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/images/share_without_circle.svg"
                                  }
                                />
                                {t("share")}
                              </button>
                            )}
                            <button
                              className="btn me-2 btn-login"
                              onClick={() => {
                                editTemplate(data);
                              }}
                            >
                              {t("editDesign")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <hr />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 d-flex justify-content-between align-items-center mb-2">
                      <h5 className="modal_template">{t("similarTemplates")}</h5>
                      {/* {similarTemplates?.length > 0 && (
                        <Link
                          to={`/template/similar/${data?.id}`}
                          className="view_all"
                          onClick={() => {
                            setIsTemplateDetailsModalOpen(false);
                          }}
                        >
                          {t("viewAll")}
                        </Link>
                      )} */}
                    </div>
                  </div>
                  <div className="row scroll_height">
                    {similarTemplates?.length > 0 ? (
                      similarTemplates?.slice(0, 10).map((val, index) => {
                        return (
                          <>
                            <div className="col-6 mb-3 position-relative">
                              {/* <a className="template_image ">
                              <img src={val?.thumbnail} />
                            </a> */}
                              {/* <div className="overlay_hover">
                                <a className="template_image ">
                                  {val?.orientation == "portrait" ? (
                                    <div className="template_view_Main" >
                                      {val?.is_video == 1 && val?.video_url ? (
                                        <video
                                          src={val?.video_url}
                                          autoPlay
                                          loop
                                          muted
                                          style={{ objectFit: 'contain', height: '100%', width: 'auto' }}
                                        ></video>
                                      ) : (
                                        <img src={val?.thumbnail} style={{ height: "100%", width: 'auto' }} />
                                      )}
                                    </div>
                                  ) : (
                                    <div className="template_view_Main" >
                                      <div className="template_view">
                                        {val?.is_video == 1 && val?.video_url ? (
                                          <video
                                            src={val?.video_url}
                                            autoPlay
                                            loop
                                            muted
                                            style={{ objectFit: 'contain', height: '100%', width: 'auto' }}
                                          ></video>
                                        ) : (
                                          <img src={val?.thumbnail} style={{ height: "100%", width: 'auto' }} />
                                        )}
                                      </div>
                                    </div>
                                  )}

                                </a>
                                <div className="overlay_div">
                                  <div className={`d-flex ${val?.is_video == 1 ? "justify-content-between" : "justify-content-end"}`}>
                                    {val?.is_video == 1 && (
                                      <a >
                                        <img
                                          src={process.env.PUBLIC_URL + "/assets/images/icon/video-pause.svg"}
                                        />
                                      </a>
                                    )}
                                    <div className="top_li">
                                      <a
                                        onClick={() => {
                                          setIsShareModalOpen(true);
                                        }}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/assets/images/share.svg"
                                          }
                                        />
                                      </a>
                                      <a
                                        onClick={() => {
                                          editTemplate(val);
                                        }}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/assets/images/edit.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                  <div className="bottom_li">
                                    <div
                                      className="user_name cursor-pointer"
                                      onClick={() => userinfo(val?.user_id)}
                                    >
                                      <img src={val?.profile_image} />
                                      <h6 className="user-name-h6">{val?.full_name}</h6>
                                    </div>
                                    <a
                                      onClick={() => {
                                        setTemplateData(val);
                                        setIsTemplateDetailsModalOpen(true);
                                      }}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/images/info.svg"
                                        }
                                        className="infoImg"
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div> */}
                              <VideoComponent val={val} setIsShareModalOpen={setIsShareModalOpen} editTemplate={editTemplate} userinfo={userinfo} setTemplateData={setTemplateData} setIsTemplateDetailsModalOpen={setIsTemplateDetailsModalOpen} />
                            </div>
                          </>
                        );
                      })
                    ) : (
                      <div
                        style={{ height: "100px" }}
                        className="fw-light d-flex justify-content-center align-items-center  w-100"
                      >
                        {t("noDataFound")}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </>
  );
};

export default TemplateDetailsModal;
