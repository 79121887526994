import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Footer2 from "../../components/Footer2";
import { useTranslation } from "react-i18next";
import Header2 from "../../components/Header2";
import { getToken } from "../../Common/LocalStorageService";
import { useSelector } from "react-redux";
import { getStaticPage } from "../../api/apiHandler";
import Footer from "../../components/Footer";

const Aboutus = () => {
  const [aboutUs, setAboutUs] = useState({});
  const changelanguage = useSelector((state) => state.changelanguage);

  useEffect(() => {
    window.scrollTo(0, 0);
    getStaticPage({ page_id: 3 }).then((response) => {
      if (response.code === 200) {
        setAboutUs(response.data[0]);
      }
    });
  }, []);
  return (
    <div>
      <main>
        {getToken() ? <Header2 /> : <Header />}
        <section className="about_us_page" id="about">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-5 col-12 ">
                <div className="left_content_img">
                  <img
                    src="assets/images/about.svg"
                    className="img-fluid"
                    alt="about"
                  />
                </div>
              </div>
              <div className="col-lg-8 col-md-8 col-sm-7 col-12 ">
                <div className="right_side_content">
                  <div className="main_title">
                    <h1>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/about_icon.svg"
                        }
                      />
                      {changelanguage === "EN"
                        ? aboutUs?.title_en
                        : changelanguage === "FR"
                        ? aboutUs?.title_fr
                        : aboutUs?.title_port}
                    </h1>
                  </div>
                  <p
                    className="ql-editor"
                    dangerouslySetInnerHTML={{
                      __html:
                        changelanguage === "EN"
                          ? aboutUs?.content_en
                          : changelanguage === "FR"
                          ? aboutUs?.content_fr
                          : aboutUs?.content_port,
                    }}
                  ></p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
        {/* <Footer2 /> */}
      </main>
    </div>
  );
};

export default Aboutus;
