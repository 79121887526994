import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import checkValidation from "../validation/ValidationSchema";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import BecomeDesigner from "./BecomeDesignerModal";
import {
  getToken,
  getUserDataFromLocal,
  removeUserDataFromLocal,
} from "../Common/LocalStorageService";
import DeleteAccont from "./DeleteAccont";
import RatingModal from "./RatingModal";
import { getAllCategoriesSubcategories } from "../api/apiHandler";
import { useFilter } from "../Common/FilterContex";
const Header2 = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { prevFilters, setPrevFilters } = useFilter();

  const [menuOpen, setMenuOpen] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  console.log("categoryData: ", categoryData);
  const [bocomeDesignerModalOpen, setBocomeDesignerModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [testiMonialModalOpen, setIsTestimonialModalOpen] = useState(false);
  const initialValue = useMemo(() => {
    return { global_search: "", global_colors: [], global_orientation: "" };
  }, []);
  const [filter, setFilter] = useState({
    ...initialValue,
    ...prevFilters,
  });

  const applyFilter = useCallback(() => {
    setPrevFilters(filter);
    navigate("/home");
  }, [filter, navigate, setPrevFilters]);

  const userDetails = getUserDataFromLocal();
  console.log('userDetails: ', userDetails);
  const designerType = userDetails?.type;
  const toggleMenu = () => {
    console.log("click");
    setMenuOpen(!menuOpen);
  };

  const schema = checkValidation(["Description"]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => { };

  const handledelete = () => {
    setIsDeleteModalOpen(true);
  };

  const handleSubmitReview = () => {
    setIsTestimonialModalOpen(true);
  };

  const handleCustomerStory = () => {
    if (location.pathname !== "/") {
      navigate("/", {
        state: "testimonial",
      });
      return;
    }
    document
      .getElementById("testimonial")
      .scrollIntoView({ behavior: "smooth", block: "center" });
  };

  const handlePricing = () => {
    if (location.pathname !== "/") {
      navigate("/", {
        state: "pricing",
      });
      return;
    }
    document
      .getElementById("pricing")
      .scrollIntoView({ behavior: "smooth", block: "center" });
  };

  const handleLogOut = useCallback(() => {
    Swal.fire({
      title: t("areYouSure"),
      text: t("logoutText"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: t("logOutConfirm"),
    }).then((result) => {
      if (result.isConfirmed) {
        removeUserDataFromLocal();

        navigate("/");
      }
    });
  }, [navigate, t]);

  const handleLogoClick = () => {
    console.log("kjkjfkdjfkdfjk");

    window.scrollTo(0, 0);
  };

  const handleBrowseTemplate = useCallback(() => {
    navigate("/home");
  }, [navigate]);

  const handleBlanckTemplate = useCallback(() => {
    navigate("/add-template");
  }, [navigate]);

  const MegamenuItem = ({ title, items, viewAllText }) => (
    <div className="col-lg-4 col-sm-12 col-12 mb-3">
      <h4>{title}</h4>
      <ul className="megamenu_all">
        {items.map((item, index) => (
          <li key={index}>
            <a href="#">{item}</a>
          </li>
        ))}
        <li className="last-child">
          <Link to={`template/${title}`} className="fw-bold">
            {viewAllText}{" "}
            <img
              src={process.env.PUBLIC_URL + "/assets/images/arrow-right.svg"}
            />
          </Link>
        </li>
      </ul>
    </div>
  );
  const MegamenuColumn = ({ title, items, t, category_id }) => {
    if (items.length > 0) {
      return (
        <div className="col-lg-4 col-sm-12 col-12 mb-3">
          <h4>{t(title)}</h4>
          <ul className="megamenu_all">
            {items.slice(0, 3).map((item, index) => (
              <li key={index}>
                <Link to={`template/category/${item.id}`}>{t(item.name)}</Link>
              </li>
            ))}
            <li className="last-child">
              <Link to={`/home`} state={{ category_id }} className="fw-bold">
                {t("viewAll")}{" "}
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/arrow-right.svg"}
                />
              </Link>
            </li>
          </ul>
        </div>
      );
    }
  }

  const Megamenu = ({ sections }) => (
    <div className="row">
      {sections.map((section, index) => (
        <MegamenuItem
          key={index}
          title={section.title}
          items={section.items}
          viewAllText={section.viewAllText}
        />
      ))}
    </div>
  );

  const sections = [
    {
      title: "Themes",
      items: ["Events", "Small Business", "Church", "Valentines Day"],
      viewAllText: "View All",
    },
    {
      title: "Sizes",
      items: ["Flyers", "A4", "Posters", "Album Covers"],
      viewAllText: "View All",
    },
    {
      title: "Social Media",
      items: [
        "Instagram Reels",
        "TikTok Video",
        "Instagram Post",
        "Facebook Post",
      ],
      viewAllText: "View All",
    },
  ];

  useEffect(() => {
    getAllCategoriesSubcategories({}).then((response) => {
      if (response.code == 200) {
        setCategoryData(response.data);
      }
    });
  }, []);

  return (
    <>
      <BecomeDesigner
        bocomeDesignerModalOpen={bocomeDesignerModalOpen}
        setBocomeDesignerModalOpen={setBocomeDesignerModalOpen}
      />
      {testiMonialModalOpen && (
        <RatingModal
          testiMonialModalOpen={testiMonialModalOpen}
          setIsTestimonialModalOpen={setIsTestimonialModalOpen}
        />
      )}

      {isDeleteModalOpen && (
        <DeleteAccont
          isDeleteModalOpen={isDeleteModalOpen}
          setIsDeleteModalOpen={setIsDeleteModalOpen}
        />
      )}

      <header className="header-main content_page_header bg-white">
        <nav className="navbar navbar-expand-lg ">
          <div className="container d-lg-flex d-none">
            <Link
              className="nav-brand"
              to={getToken() ? "/home" : "/"}
              onClick={() => {
                handleLogoClick();
              }}
            >
              <img
                src={process.env.PUBLIC_URL + "/assets/images/Logo_new.svg"}
              />
            </Link>
            <ul className="nav col-12 col-lg-auto me-lg-auto nav_menu">
              <li className="dropdown">
                <a className="sub_category nav-link dropdown-toggle cursor-pointer">
                  {/* {t("headercategory")} */}
                  {t("headerTemplate")}
                </a>
                <div className="mega-menu">
                  <div className="row">
                    {categoryData.length > 0 &&
                      categoryData.map((item, index) => (
                        <>
                          <MegamenuColumn
                            title={item.name}
                            items={item.subcategory_details}
                            t={t}
                            category_id={item.id}
                          />
                        </>
                      ))}
                    {/* <Megamenu sections={sections} /> */}
                  </div>
                </div>
              </li>
              <li>
                <a
                  id="div-101"
                  className="nav-link cursor-pointer"
                  onClick={() => {
                    handlePricing();
                  }}
                >
                  {t("headerpricing")}
                </a>
              </li>
              <li>
                <a
                  id="div-103"
                  className="nav-link cursor-pointer "
                  onClick={() => {
                    handleCustomerStory();
                  }}
                >
                  {t("CustomerStories")}
                </a>
              </li>
            </ul>
            <form className="me-auto search_input form_input position-relative dropdown ">
              <input
                className="form-control me-2"
                type="text"
                placeholder={t("searchPlaceholder")}
                aria-label="Search"
                value={filter?.global_search}
                onChange={(e) => {
                  if (e.target.value) {
                    setFilter((val) => {
                      return { ...val, global_search: e.target.value };
                    });
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    applyFilter();
                  }
                }}
              />
              <Link
                to="/my-projects"
                className="btn btn-gradient"
                type="submit"
              >
                {t("myProjects")}
              </Link>
              <div>
                <button
                  className="dropdown-toggle btn btn-create"
                  type="submit"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {t("authButton")}
                </button>
                <div
                  className="dropdown-menu filter_dropdown"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <div className="create_list_menu">
                    <a href>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/browse_template.svg"
                        }
                      />
                      <div
                        className="list_line cursor-pointer"
                        onClick={() => {
                          handleBrowseTemplate();
                        }}
                      >
                        <h6>{t("browseTemplates")}</h6>
                        <label>{t("flyerVideoedPost")} </label>
                      </div>
                    </a>
                    <a href>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/blank_design.svg"
                        }
                      />
                      <div
                        className="list_line cursor-pointer"
                        onClick={() => {
                          handleBlanckTemplate();
                        }}
                      >
                        <h6>{t("blankDesign")}</h6>
                        <label>{t("startWithFreshCanvas")}</label>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </form>
            <div
              className="collapse navbar-collapse ms-auto"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ms-auto mb-lg-0">
                <li className="dropdown white_arrow account_setting">
                  <a
                    className="dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img alt="" src={userDetails?.profile_image} />{" "}
                  </a>
                  <ul
                    className="dropdown-menu myriad-pro-ul"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li className="account_bg" >
                      <a className="dropdown-item"
                        style={{ color: "black" }}
                      >
                        <img
                          alt=""
                          src={userDetails?.profile_image}
                          className="ineer_small_icon"
                        />
                        {userDetails?.full_name}
                        {!userDetails?.is_subscribed && (
                          <p className="subs_btn">{t("Free")}</p>
                          // <span className="subs_btn">{t("Free")}</span>
                        )}
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" onClick={() => { setPrevFilters({}); navigate(`/designer-profile/${userDetails?.id}`); }}>
                        <img
                          alt=""
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/account/edit.svg"
                          }
                        />
                        {t("viewProfile")}
                      </a>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/my-account" onClick={() => { setPrevFilters({}); }}>
                        <img
                          alt=""
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/account/settings.svg"
                          }
                        />
                        {t("accountSettings")}
                      </Link>
                    </li>
                    {/* <li>
                      <Link className="dropdown-item" to="/change-password" onClick={() => { setPrevFilters({}); }}>
                        <img
                          alt=""
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/account/password.svg"
                          }
                        />
                        {t("changePassword")}
                      </Link>
                    </li> */}
                    <li>
                      <Link className="dropdown-item" to="/subscription" onClick={() => { setPrevFilters({}); }}>
                        <img
                          alt=""
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/account/crown.svg"
                          }
                        />
                        {t("unlimitedDownloads")}
                      </Link>
                    </li>
                    {/* <li className="cursor-pointer">
                      <a
                        className="dropdown-item"
                        onClick={() => { handledelete(); setPrevFilters({}) }}
                      >
                        <img
                          alt=""
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/account/delete.svg"
                          }
                        />
                        {t("deleteAccount")}
                      </a>
                    </li> */}
                    <li className="cursor-pointer">
                      <a
                        className="dropdown-item"
                        onClick={() => handleSubmitReview()}
                      >
                        <img
                          alt=""
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/star-fill.svg"
                          }
                        />
                        {t("addYourReview")}
                      </a>
                    </li>
                    <li className="cursor-pointer">
                      <Link
                        to={"/contact-us"}
                        className="dropdown-item"

                      >
                        <img
                          alt=""
                          // style={{ height: "20px" }}
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/Help.svg"
                          }
                        // className="ImageBlackFilter"
                        />
                        {t("help")}
                      </Link>
                    </li>
                    <li
                      onClick={() => {
                        handleLogOut();
                        setPrevFilters({})
                      }}
                    >
                      <a className="dropdown-item cursor-pointer">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/sign-out.svg"
                          }
                        />
                        {t("Logout")}
                      </a>
                    </li>
                    {/* <li className="text-center cursor-pointer">
                      {designerType === "designer" ? (
                        <a className="becomre_deigner">
                          {t("alreadyDesigner")}
                        </a>
                      ) : (
                        <Link
                          to={"/become-designer"}
                          className="becomre_deigner"
                        // onClick={() => {
                        //   setBocomeDesignerModalOpen(true);
                        // }}
                        >
                          {t("becomeDesigner")}
                        </Link>
                      )}
                    </li> */}
                  </ul>
                </li>
              </ul>
            </div>
            {/* Mobile menu */}
          </div>
          <div className="container-fluid d-flex d-lg-none mobile_menu">
            <div className="main_top_header">
              <Link className="nav-brand" to={getToken() ? "/home" : "/"}>
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/Logo_new.svg"}
                />
              </Link>
              <form className="  me-auto search_input form_input position-relative dropdown first_header">
                <input
                  className="form-control me-2"
                  type="text"
                  placeholder={t("searchPlaceholder")}
                  aria-label="Search"
                  value={filter?.global_search}
                  onChange={(e) => {
                    if (e.target.value) {
                      setFilter((val) => {
                        return { ...val, global_search: e.target.value };
                      });
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      applyFilter();
                    }
                  }}
                />
                <Link
                  to="/my-projects"
                  className="btn btn-gradient"
                  type="submit"
                >
                  {t("myProjects")}
                </Link>
              </form>
              <div className="right_option">
                <ul className="normal_list ms-auto mb-lg-0">
                  <li className="dropdown white_arrow account_setting">
                    <a
                      className="dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img src={userDetails?.profile_image} />{" "}
                      <span> {userDetails?.full_name}</span>
                    </a>
                    {/* <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li className="account_bg" onClick={() => { navigate(`/designer-profile/${userDetails?.id}`) }}>
                        <a className="dropdown-item" href="#">
                          <img
                            src={userDetails?.profile_image}
                            className="ineer_small_icon"
                          />
                          {userDetails?.full_name}
                          {!userDetails?.is_subscribed && (
                            <span className="subs_btn">{t("Free")}</span>
                          )}
                        </a>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/profile">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/account/edit.svg"
                            }
                          />
                          {t("editProfile")}
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/change-password">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/account/password.svg"
                            }
                          />
                          {t("changePassword")}
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/subscription">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/account/crown.svg"
                            }
                          />
                          {t("subscriptions")}
                        </Link>
                      </li>
                      <li
                        className="cursor-pointer"
                        onClick={() => handledelete()}
                      >
                        <a className="dropdown-item" href="#">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/account/delete.svg"
                            }
                          />
                          {t("deleteAccount")}
                        </a>
                      </li>
                      <li className="cursor-pointer">
                        <a
                          className="dropdown-item"
                          onClick={() => handleSubmitReview()}
                        >
                          <img
                            alt=""
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/star-fill.svg"
                            }
                          />
                          {t("addYourReview")}
                        </a>
                      </li>
                      <li className="cursor-pointer">
                        <Link
                          to={"/contact-us"}
                          className="dropdown-item"
                        >
                          <img
                            alt=""
                            // style={{ height: "20px" }}
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/Help.svg"
                            }
                          // className="ImageBlackFilter"
                          />
                          {t("help")}
                        </Link>
                      </li>
                      <li>
                        <a
                          className="dropdown-item cursor-pointer"
                          onClick={() => {
                            handleLogOut();
                          }}
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/sign-out.svg"
                            }
                          />
                          {t("Logout")}
                        </a>
                      </li>
                      <li className="text-center">
                        {designerType === "designer" ? (
                          <a className="becomre_deigner">
                            {t("alreadyDesigner")}
                          </a>
                        ) : (
                          <a
                            className="becomre_deigner"
                            onClick={() => {
                              setBocomeDesignerModalOpen(true);
                            }}
                          >
                            {t("becomeDesigner")}
                          </a>
                        )}
                      </li>
                    </ul> */}
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li className="account_bg">
                        <a className="dropdown-item" href="#"
                          style={{ color: "black" }}
                        >
                          <img
                            alt=""
                            src={userDetails?.profile_image}
                            className="ineer_small_icon"
                          />
                          {userDetails?.full_name}
                          {!userDetails?.is_subscribed && (
                            <p className="subs_btn">{t("Free")}</p>
                            // <span className="subs_btn">{t("Free")}</span>
                          )}
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" onClick={() => { setPrevFilters({}); navigate(`/designer-profile/${userDetails?.id}`); }}>
                          <img
                            alt=""
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/account/edit.svg"
                            }
                          />
                          {t("viewProfile")}
                        </a>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/my-account" onClick={() => { setPrevFilters({}); }}>
                          <img
                            alt=""
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/account/settings.svg"
                            }
                          />
                          {t("accountSettings")}
                        </Link>
                      </li>
                      {/* <li>
                        <Link className="dropdown-item" to="/change-password" onClick={() => { setPrevFilters({}); }}>
                          <img
                            alt=""
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/account/password.svg"
                            }
                          />
                          {t("changePassword")}
                        </Link>
                      </li> */}
                      <li>
                        <Link className="dropdown-item" to="/subscription" onClick={() => { setPrevFilters({}); }}>
                          <img
                            alt=""
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/account/crown.svg"
                            }
                          />
                          {t("unlimitedDownloads")}
                        </Link>
                      </li>
                      {/* <li
                        className="cursor-pointer"
                        onClick={() => { handledelete(); setPrevFilters({}) }}
                      >
                        <a className="dropdown-item">
                          <img
                            alt=""
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/account/delete.svg"
                            }
                          />
                          {t("deleteAccount")}
                        </a>
                      </li> */}
                      <li
                        className="cursor-pointer"
                        onClick={() => handleSubmitReview()}
                      >
                        <a className="dropdown-item">
                          <img
                            alt=""
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/star-fill.svg"
                            }
                          />
                          {t("addYourReview")}
                        </a>
                      </li>
                      <li className="cursor-pointer">
                        <Link
                          to={"/contact-us"}
                          className="dropdown-item"
                          onClick={() => { setPrevFilters({}); }}
                        >
                          <img
                            alt=""
                            // style={{ height: "20px" }}
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/Help.svg"
                            }
                          // className="ImageBlackFilter"
                          />
                          {t("help")}
                        </Link>
                      </li>
                      <li>
                        <a
                          className="dropdown-item cursor-pointer"
                          onClick={() => {
                            handleLogOut();
                            setPrevFilters({})
                          }}
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/sign-out.svg"
                            }
                          />
                          {t("Logout")}
                        </a>
                      </li>
                      {/* <li className="text-center">
                        {designerType === "designer" ? (
                          <p className="becomre_deigner">
                            {t("alreadyDesigner")}
                          </p>
                        ) : (
                          <Link
                            to={"/become-designer"}
                            className="becomre_deigner"
                          // onClick={() => {
                          //   setBocomeDesignerModalOpen(true);
                          // }}
                          >
                            {t("becomeDesigner")}
                          </Link>
                        )}
                      </li> */}
                    </ul>
                  </li>
                </ul>
                <span
                  className={`toggle-button ${menuOpen ? "button-open" : ""}`}
                  onClick={toggleMenu}
                >
                  <div className="menu-bar menu-bar-top" />
                  <div className="menu-bar menu-bar-middle" />
                  <div className="menu-bar menu-bar-bottom" />
                </span>
              </div>
            </div>
            <div className="main_bottom_header">
              <form className=" me-auto search_input position-relative dropdown ">
                <input
                  className="form-control me-2"
                  type="text"
                  placeholder={t("searchPlaceholder")}
                  aria-label="Search"
                  value={filter?.global_search}
                  onChange={(e) => {
                    if (e.target.value) {
                      setFilter((val) => {
                        return { ...val, global_search: e.target.value };
                      });
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      applyFilter();
                    }
                  }}
                />
                <Link
                  to="/my-projects"
                  className="btn btn-gradient"
                  type="submit"
                >
                  {t("myProjects")}
                </Link>
              </form>
            </div>
            <div
              className={`menu-wrap ${menuOpen ? "menu-show" : ""}`}
              id="menu-1"
            >
              <div className="menu-sidebar">
                <ul className="menu">
                  <li className="dropdown">
                    <a
                      href="#"
                      className="dropdown-toggle nav-link"
                      role="button"
                      id="dropdownMenuLink"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {/* {t("headercategory")} */}
                      {t("headerTemplate")}
                    </a>
                    <div
                      className="dropdown-menu mega-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <div className="row">
                        {categoryData.length > 0 &&
                          categoryData.map((item, index) => (
                            <MegamenuColumn
                              title={item.name}
                              items={item.subcategory_details}
                              t={t}
                              id={item.id}
                            />
                          ))}
                        {/* <Megamenu sections={sections} /> */}
                      </div>
                    </div>
                  </li>
                  <li>
                    <a
                      data-type="page-transition"
                      className="data-link-1 cursor-pointer"
                      onClick={() => {
                        handlePricing();
                        setMenuOpen(false)
                      }}
                    >
                      {t("headerpricing")}
                    </a>
                  </li>
                  <li>
                    <a
                      data-type="page-transition"
                      className="data-link-1 cursor-pointer"
                      onClick={() => {
                        handleCustomerStory();
                      }}
                    >
                      {t("CustomerStories")}
                    </a>
                  </li>
                </ul>
                <div className="text-center auth-button mt-4 form_input">
                  <button
                    className="dropdown-toggle btn btn-create"
                    type="submit"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {t("authButton")}
                  </button>
                  <div
                    className="dropdown-menu filter_dropdown"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <div className="create_list_menu">
                      <a href>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/browse_template.svg"
                          }
                        />
                        <div
                          className="list_line cursor-pointer"
                          onClick={() => {
                            handleBrowseTemplate();
                          }}
                        >
                          <h6>{t("browseTemplates")}</h6>
                          <label>{t("flyerVideoedPost")} </label>
                        </div>
                      </a>
                      <a href>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/blank_design.svg"
                          }
                        />
                        <div
                          className="list_line cursor-pointer"
                          onClick={() => {
                            handleBlanckTemplate();
                          }}
                        >
                          <h6>{t("blankDesign")}</h6>
                          <label>{t("startWithFreshCanvas")}</label>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
      {/* <div className="modal pe-0" tabIndex={-1} id="become_designer">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <button
                  type="button"
                  className="custom_btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/close.svg"}
                  />
                </button>
                <div className="modal_forgot">
                  <div className="mobile_icon text-center mb-2">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/images/designer.svg"
                      }
                    />
                  </div>
                  <h6>{t("becomeaDesigner")}</h6>
                  <div className="login_form mt-5">
                    <div className="row">
                      <div className="col-12 mb-3">
                        <div className="form-group left_fixed">
                          <textarea
                            className="form-control textare_height"
                            placeholder={t("portfolioLink")}
                            defaultValue={""}
                            {...register("PortfolioLink")}
                          />
                        </div>
                        {errors.PortfolioLink && (
                          <p
                            role="alert"
                            className="error-text m-1"
                            style={{ color: "red" }}
                          >
                            {errors.PortfolioLink.message}
                          </p>
                        )}
                      </div>

                      <div className="col-12 mb-3">
                        <div className="form-group left_fixed">
                          <textarea
                            className="form-control textare_height"
                            placeholder={t("aboutMe")}
                            defaultValue={""}
                            {...register("Description")}
                          />
                        </div>
                        {errors.Description && (
                          <p
                            role="alert"
                            className="error-text m-1"
                            style={{ color: "red" }}
                          >
                            {errors.Description.message}
                          </p>
                        )}
                      </div>
                      <div className="col-12 mb-3">
                        <div className="form-group left_fixed">
                          <button type="submit" className="btn btn-theme">
                            {t("submit")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div> */}
    </>
  );
};

export default Header2;
