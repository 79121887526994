import React from "react";
import { observer } from "mobx-react-lite";
import { SectionTab } from "polotno/side-panel";
import QRCode from "qrcode";
import * as svg from "polotno/utils/svg";
import ImQrcode from "@meronex/icons/im/ImQrcode";
import { Button, InputGroup } from "@blueprintjs/core";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";

// create svg image for QR code for input text
export async function getQR(text) {
  return new Promise((resolve) => {
    QRCode.toString(
      text || "no-data",
      {
        type: "svg",
        color: {
          dark: "#00F", // Blue dots
          light: "#0000", // Transparent background
        },
      },
      (err, string) => {
        resolve(svg.svgToURL(string));
      }
    );
  });
}

// define the new custom section
export const QrSection = {
  name: "Add QR",
  Tab: (props) => {
    const { t } = useTranslation();
    return (
      <SectionTab name={t("add_qr")} {...props}>
        <img className="qrHover" src={process.env.PUBLIC_URL +
          "/assets/images/icon/qr-code.svg"} alt="" style={{ height: "20px" }}></img>
      </SectionTab>
    );
  },
  // we need observer to update component automatically on any store changes
  Panel: observer(({ store }) => {
    const [val, setVal] = React.useState("https://www.craetivo.com");

    const el = store.selectedElements[0];
    const isQR = el?.name === "qr";

    // if selection is changed we need to update input value
    React.useEffect(() => {
      if (el?.custom?.value) {
        setVal(el?.custom.value);
      }
    }, [isQR, el]);

    // update image src when we change input data
    React.useEffect(() => {
      if (isQR) {
        getQR(val).then((src) => {
          el.set({
            src,
            custom: {
              value: val,
            },
          });
        });
      }
    }, [el, val, isQR]);

    return (
      <div>
        {isQR && <p style={{ display: 'flex', justifyContent: 'center', fontWeight: "600", padding: '10px 0' }}>{i18n.t("UpdateQrCodeContent")}:</p>}
        {!isQR && <p style={{ display: 'flex', justifyContent: 'center', fontWeight: "600", padding: '10px 0' }}>{i18n.t("addNewQr")}:</p>}
        <InputGroup
          className="QrSection"
          onChange={(e) => {
            setVal(e.target.value);
          }}
          placeholder="Type qr code content"
          value={val}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "20px",
          }}
        >
          {/* <Button
            style={{
              display: isQR ? "" : "none",
            }}
            onClick={() => {
              store.selectElements([]);
              setVal("");
            }}
          >
            Cancel
          </Button> */}
          {/* <Button
            style={{
              display: isQR ? "none" : "",
            }}
            onClick={async () => {
              const src = await getQR(val);

              store.activePage.addElement({
                type: "svg",
                name: "qr",
                x: 50,
                y: 50,
                width: 200,
                height: 200,
                src,
                custom: {
                  value: val,
                },
              });
            }}
          >
            Add new QR code
          </Button> */}

          <button className="btn-create" type="button" style={{ margin: !isQR && '0 auto' }}
            onClick={async () => {
              const src = await getQR(val);

              store.activePage.addElement({
                type: "svg",
                name: "qr",
                x: 50,
                y: 50,
                width: 200,
                height: 200,
                src,
                custom: {
                  value: val,
                },
              });
            }}>{i18n.t("AddQrCode")}
          </button>
          <button className="btn-create" type="button"
            style={{
              display: isQR ? "" : "none",
            }}
            onClick={() => {
              store.selectElements([]);
              setVal("https://www.craetivo.com");
            }}> {i18n.t("cancelText")}
          </button>
        </div>
      </div>
    );
  }),
};
