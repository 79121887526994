import React, { useCallback, useEffect, useState } from "react";
import Innerheader from "../../components/Innerheader";
import Sidebar from "../../components/Sidebar";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getEarning } from "../../api/apiHandler";
import moment from "moment/moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const My_earning = () => {
  const { t, i18n } = useTranslation();
  const [userEarningData, setUserEarningData] = useState([]);
  const [categoryEarningData, setCategoryEarningData] = useState([]);
  const [totalEarnings, setTotalEarnings] = useState(0);
  const minDate = moment()
    .clone()
    .startOf("month")
    .clone()
    .subtract(1, "day")
    .toDate();
  const [startDate, setStartDate] = useState(minDate);
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const getEarningDetails = useCallback(() => {
    const date = moment(startDate, "ddd MMM DD YYYY HH:mm:ss Z");

    getEarning({
      start_date: date.startOf("month").format("YYYY/MM/DD"),
      end_date: date.endOf("month").format("YYYY/MM/DD"),
    }).then((response) => {
      console.log("response: ", response);
      if (response.code === 200) {
        setUserEarningData(response.data.user_data);
        setCategoryEarningData(response.data.category_data);
        setTotalEarnings(response?.data?.total_earning)
      }
    });
  }, [startDate]);

  const totalSum = categoryEarningData
    ? categoryEarningData.reduce((total, item) => {
      return (
        total +
        (item.download_template
          ? item.download_template.reduce(
            (subTotal, subItem) => subTotal + subItem.price,
            0
          )
          : 0)
      );
    }, 0)
    : 0;

  useEffect(() => {
    getEarningDetails();
  }, [getEarningDetails]);

  return (
    <main>
      <Innerheader />
      <div className="wrapper">
        <Sidebar />
        <div className="content flex-grow-1">
          <section className="category_main_title">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 mb-4 ">
                  <div className="main_gray">
                    <div className="row">
                      <div className="col-12">
                        <div className="login_header">
                          <Link className="cursor-pointer" to="/home">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/left_arrow.svg"
                              }
                            />{" "}
                            {t("back")}
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-5">
                      <div className="col-md-12">
                        <h5 className="mb-2 fw-bold text-center">
                          {t("myEarnings")}
                        </h5>
                      </div>
                    </div>
                    <div className="row  justify-content-center">
                      <div className="col-lg-4 col-md-5 col-sm-6 col-12 mb-3">
                        <div className="my_subscription_div current">
                          <div className="my_earning">
                            <h4>{t("totalEarnings")}</h4>
                            <h4>${totalEarnings?.toFixed(2)}</h4>
                          </div>
                          <div className="my_earning mt-2">
                            <p>{monthNames[startDate.getMonth()] + " " + startDate.getFullYear() + " " + t("earnings")}</p>
                            <p>${totalSum?.toFixed(2)}</p>
                          </div>
                          {categoryEarningData?.length > 0 && (
                            <ul className="earning_list">
                              {categoryEarningData &&
                                categoryEarningData.length > 0 &&
                                categoryEarningData.map((item, index) => (
                                  <li key={index}>
                                    <label>{item.name}</label>{" "}
                                    <span>
                                      ${" "}
                                      {item.download_template
                                        ? item.download_template
                                          .reduce(
                                            (total, item) =>
                                              total + item.price,
                                            0
                                          )
                                          .toFixed(2)
                                        : 0}
                                    </span>
                                  </li>
                                ))}
                            </ul>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-5 col-sm-6 col-12 mb-3">
                        <div className="row">
                          <div className="col-12 d-flex justify-content-end">
                            <div className="box earning_select w-50 d-flex justify-content-end">
                              <DatePicker
                                selected={startDate}
                                className="cursor-pointer"
                                onChange={(date) => { if (date) { setStartDate(date) } }}
                                dateFormat="MMM yyyy"
                                showMonthYearPicker
                                maxDate={minDate}
                              />
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="member_list payment_list">
                              <ul className="mt-3">
                                {userEarningData &&
                                  userEarningData.length > 0 ? (
                                  userEarningData.map((item, index) => (
                                    <li key={index}>
                                      <div className="member_img">
                                        <a>
                                          <img src={item.profile_image} />
                                        </a>
                                      </div>
                                      <div className="member_name">
                                        <a> {item.full_name}</a>
                                        <p>
                                          {moment(item.created_at).format(
                                            "DD-MMM|YYYY HH:mm"
                                          )}
                                        </p>
                                      </div>
                                      <div className="member_btn">
                                        <h6 className="date">
                                          ${parseFloat(item.price).toFixed(2)}
                                        </h6>
                                      </div>
                                    </li>
                                  ))
                                ) : (
                                  <div
                                    style={{ height: "100px" }}
                                    className="fw-light d-flex justify-content-center align-items-center  w-100"
                                  >
                                    {t("noDataFound")}
                                  </div>
                                )}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </main>
  );
};

export default My_earning;
