import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import checkValidation from "../validation/ValidationSchema";
import * as Yup from "yup";
import { removeUserDataFromLocal } from "../Common/LocalStorageService";
import { useNavigate } from "react-router-dom";
import { showPromiseNotification } from "../Common/notification";
import { deleteAccont, getDeleteAccontReason } from "../api/apiHandler";

const DeleteAccont = (props) => {
  const { isDeleteModalOpen, setIsDeleteModalOpen } = props;
  const navigate = useNavigate();
  const [reason, setReason] = useState([]);
  console.log("reason: ", reason);
  const { t, i18n } = useTranslation();
  const schema = Yup.object().shape({
    reason_id: Yup.string().required(t("selectSomething")),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  console.log('errors: ', errors);

  const onSubmit = (data) => {
    const payload = { ...data };

    const myPromise = new Promise((resolve, reject) =>
      deleteAccont(payload).then((response) => {
        if (response.code === 200) {
          removeUserDataFromLocal();
          navigate("/");
          reset();
          resolve(response);
        } else {
          reject(response);
        }
      })
    );
    showPromiseNotification(myPromise);
  };

  useEffect(() => {
    getDeleteAccontReason().then((response) => {
      if (response.code === 200) {
        setReason(response.data);
      }
    });
  }, []);

  return (
    <>
      <div
        className={`modal fade ${isDeleteModalOpen ? "show d-block " : ""}`}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
        id="delete_account"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="custom_btn"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setIsDeleteModalOpen(false);
                }}
              >
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/close.svg"}
                />
              </button>
              <div className="modal_forgot">
                <div className="mobile_icon text-center mb-2">
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/designer.svg"}
                  />
                </div>
                <h6>{t("areYouSureDeleteAccount")}</h6>
                <div className="login_form mt-5">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                      <div className="col-12 mb-3">
                        <div className="form-group left_fixed">
                          <p>{t("actionNotRevertable")}</p>
                        </div>
                      </div>
                      <div className="col-12 mb-3">
                        <select
                          className="w-100"
                          // value={reason}
                          {...register("reason_id")}
                        // onChange={()}
                        >
                          <option value={""}>{t("selectReason")}</option>
                          {reason.map((item) => {
                            return (
                              <option value={item.id}>{item.reason}</option>
                            );
                          })}
                        </select>
                        {errors.reason_id && (
                          <span className="text-danger">
                            {" "}
                            {errors.reason_id.message}
                          </span>
                        )}
                      </div>
                      <div className="col-12 mb-3">
                        <div className="form-group d-flex align-items-center justify-content-center">
                          <button
                            type="submit"
                            className="btn btn-theme w-auto me-2"
                          >
                            {t("delete")}
                          </button>
                          {/* <a href="#" className="bptn btn-theme w-auto me-2">
                            Delete
                          </a> */}
                          <a
                            className="btn text-danger"
                            onClick={() => {
                              setIsDeleteModalOpen(false);
                            }}
                          >
                            {t("cancelText")}
                          </a>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isDeleteModalOpen && <div className="modal-backdrop fade show"></div>}
    </>
  );
};

export default DeleteAccont;
