import React, { useCallback, useEffect, useState } from "react";
import { getUserDataFromLocal } from "../../../Common/LocalStorageService";
import {
  downloadPercentage,
  mp4Download,
  publishTemplate,
  templateDownload,
} from "../../../api/apiHandler";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { showPromiseNotification } from "../../../Common/notification";
import { FIX_SIZES } from "..";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { use } from "i18next";
import Lottie from "react-lottie";
import loadingAnimation from "../loder.json";
import * as unit from "polotno/utils/unit";
import {
  Button,
  Position,
  Menu,
  HTMLSelect,
  Slider,
  Popover,
  ProgressBar,
} from "@blueprintjs/core";
import SubscriptionModal from "../../../Common/SubscriptionModal";

const Header = (props) => {
  const { t, i18n } = useTranslation();
  const {
    setIsInviteMemberModalOpen,
    store,
    templateData,
    handleSaveEdit,
    type,
    templateChange,
    setSubmitBackClick,
    setIsResizeModal,
    setIsPublishTemplateModalOpen,
    setIsPublish,
    isPublish,
    setIsTemplateModalOpen,
    setIsNewDesign,
    setSubmitPremiumClick,
    fontList
  } = props;

  const user = getUserDataFromLocal();
  const { id } = useParams();
  const navigate = useNavigate();
  const [qualityCount, setQualityCount] = useState(0.8);
  const [downloadType, setDownloadType] = useState("image");
  const [downloadVideoType, setDownloadVideoType] = useState(360);
  const [dVideo, setIsvideo] = useState(false);
  const [pageSizeModifier, setPageSizeModifier] = React.useState(1);
  const [isSubscriptionModal, setIsSubscriptionModal] = useState(false);
  console.log("pageSizeModifier: ", pageSizeModifier);
  const [progress, setProgress] = useState(0);
  console.log('progress ==>12: ', progress);
  const [isSubmitting, setIsSubmitting] = useState(false);
  console.log("downloadVideoType: ", downloadVideoType);
  const isVideo = templateChange?.pages?.[0]?.children?.some(
    (item) => item?.type === "video"
  );
  const url = window.location.href;
  const location = useLocation();

  // Extract the path from the location object
  const currentPath = location.pathname; // e.g., '/copy-template/54'
  const pathSegments = currentPath.split('/'); // Split the path by '/'
  const templateName = pathSegments[1];
  const lastSegment = url.substring(url.lastIndexOf("/") + 1);
  console.log("lastSegment: ", lastSegment);
  const segments = url.split("/");

  // Find the "copy-template" key
  const keyIndex = segments.indexOf("copy-template");
  let templateUrlName = "newurl";

  if (keyIndex !== -1) {
    templateUrlName = segments[keyIndex];
  }
  console.log("templateUrlName: ", templateUrlName);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const addWatermark = (store) => {
    const page = store.pages[0];
    const imageUrl = process.env.PUBLIC_URL + "/assets/images/watermark.png";

    // Calculate the width and height as a fraction of the store's width and height
    const watermarkWidth = store.width / 2; // Adjust width to half of the store's width
    const watermarkHeight = store.height / 2;
    const watermark = page.addElement({
      // type: "text",
      // text: "Craetivo",
      // fontSize: 100,
      // opacity: 0.5,
      // fill: "white",
      // stroke: "black",
      // strokeWidth: 3,
      // width: store.width,
      // height: store.height,
      // selectable: false,
      // alwaysOnTop: true,
      // showInExport: true,
      // type: "text",
      // text: "Craetivo",
      type: "image",
      src: imageUrl,
      opacity: 1,
      width: watermarkWidth,
      height: watermarkHeight,
      selectable: false,
      alwaysOnTop: true,
      showInExport: true,
      x: (store.width - watermarkWidth) / 2, // Center horizontally
      y: (store.height - watermarkHeight) / 2, // Center vertically
      // fontSize: 100,
      // opacity: 0.5,
      // fill: "white",
      // stroke: "black",
      // strokeWidth: 3,
      // width: store.width,
      // height: store.height,
      // selectable: false,
      // alwaysOnTop: true,
      // showInExport: true,
      // // x: store.width / 2, // Center horizontally
      // y: store.height / 2, // Center vertically
      // textAlign: "center",
      // angle: 90
    });
    return watermark;
  };

  const downloadVideo = useCallback(
    async (url) => {
      try {
        const response = await fetch(url);
        const blob = await response.blob();
        const urlBlob = window.URL.createObjectURL(blob);
        // Create a link element
        const link = document.createElement("a");
        link.href = urlBlob;
        link.download = templateData?.name || "video.mp4"; // You can set the filename here
        link.style.display = "none";
        // Append the link to the body
        document.body.appendChild(link);
        // Simulate a click to trigger the download
        link.click();
        // Clean up
        document.body.removeChild(link);
        window.URL.revokeObjectURL(urlBlob);
      } catch (error) {
        console.error("Error downloading video:", error);
      }
    },
    [templateData?.name]
  );
  const handleQualityCount = useCallback((e) => {
    setQualityCount(e.target.value);
  }, []);
  const handleDownload = useCallback(
    async (type = "") => {
      const myPromise = new Promise(async (resolve, reject) => {
        let watermark;
        const shouldAddWatermark = user?.is_subscribed === 0; // Replace this with your condition

        // await store.history.ignore(() => {
        //   if (shouldAddWatermark) {
        //     watermark = addWatermark(store);
        //   }
        // });

        if (type === "image" && dVideo == false) {
          await store
            .saveAsImage({
              fileName: templateData?.name + ".jpeg",
              mimeType: "image/jpeg",
              // quality: shouldAddWatermark ? 0.5 : 1,
              pixelRatio: qualityCount,
            })
            .then(() => {
              if (watermark) {
                store.history.ignore(() => {
                  store.deleteElements([watermark.id]); // Correctly remove the watermark without creating a history entry
                });
              }
            });
        } else if (type == "png" && dVideo == false) {
          await store
            .saveAsImage({
              fileName: templateData?.name + ".png", // Change the file extension to .png
              mimeType: "image/png", // Change the mimeType to image/png
              pixelRatio: qualityCount,
            })
            .then(() => {
              if (watermark) {
                store.history.ignore(() => {
                  store.deleteElements([watermark.id]); // Correctly remove the watermark without creating a history entry
                });
              }
            });
        } else if (type == "svg" && dVideo == false) {
          await store
            .saveAsSVG({
              fileName: templateData?.name + ".svg", // Change the file extension to .svg
              pixelRatio: qualityCount,
            })
            .then(() => {
              if (watermark) {
                store.history.ignore(() => {
                  store.deleteElements([watermark.id]); // Correctly remove the watermark without creating a history entry
                });
              }
            });
        } else if (type === "video" && dVideo == true) {
          let newFonts = await store.toJSON()?.pages[0].children
            .filter((res) => res?.type === 'text' && res.fontFamily?.length > 0)
            // .map((res) => {res.fontFamily});
            .map((res) => {
              let fonUrl = fontList.find((font) => {
                return res.fontFamily === font.name;  // Ensure the comparison is returned
              });
              console.log(' newStore fonUrl: ', fonUrl);
              if (fonUrl && fonUrl?.image) {
                store.addFont({
                  fontFamily: res.fontFamily,
                  url: fonUrl ? fonUrl?.image : ""
                })
              }
            })
          console.log('newStore: ', store.toJSON(), newFonts);
          const downloadFile = async (url, filename) => {
            const response = await fetch(url);
            const blob = await response.blob();
            const blobUrl = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = blobUrl;
            a.download = filename;
            a.click();
          };
          setIsSubmitting(true);
          const data = await store.toJSON();
          console.log("data: ", data);
          console.log('video qualityCount: ', qualityCount);

          const req = await fetch(
            `https://api.polotno.com/api/renders?KEY=${process.env.REACT_APP_POLOTNO_KEY}`,
            {
              method: "POST",
              headers: {
                // it is important to set a json content type
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                // polotno json from store.toJSON()
                design: data,
                format: "mp4",
                outputFormat: 'url',
                // here you can pass other render options
                // pixelRatio: 1,
                pixelRatio: downloadVideoType == 1080 ? 1 : downloadVideoType == 720 ? 0.8 : downloadVideoType == 480 ? 0.6 : 0.3,
                // see bellow for full details of options
              }),
            }
          );

          const { id: jonId } = await req.json();

          if (jonId) {
            for (let i = 0; i < 100; i++) {
              const req = await fetch(
                "https://api.polotno.com/api/renders/" +
                jonId +
                "?KEY=" +
                process.env.REACT_APP_POLOTNO_KEY
              );
              const job = await req.json();
              console.log('job: status===========>', job);
              if (job.status === "error") {
                alert("Error: " + job.error);
                break;
              }
              if (job.status === "progress") {
                setProgress(job.progress);
              }
              if (job.status === "done") {
                const url = job.output;

                downloadFile(url, "export." + "mp4");
                setProgress(0);
                setIsSubmitting(false)
                break;
              }
              // wait a bit
              await new Promise((r) => setTimeout(r, 1000));
            }
          }
          // Usage
          // await mp4Download({
          //   template_data: data,
          //   is_watermark: shouldAddWatermark,
          //   video_quality: downloadVideoType,
          //   is_download: 1
          // })
          //   .then((response) => {
          //     console.log("response: Video ", response);
          //     if (response.code === 200) {
          //       // Create a hidden iframe
          //       const videoUrl = response.data; // Assuming response.data contains the video URL
          //       downloadVideo(videoUrl);
          //       setIsSubmitting(false)
          //     } else {
          //       reject({ message: "Failed to convert into MP4" });
          //       return;
          //     }
          //   })
          //   .then(() => {
          //     if (watermark) {
          //       store.history.ignore(() => {
          //         store.deleteElements([watermark.id]); // Correctly remove the watermark without creating a history entry
          //       });
          //     }
          //   });
        } else {
          console.log("PDF ......................");
          console.log(
            "store.dpi / pageSizeModifier: ",
            store.dpi / pageSizeModifier
          );
          await store
            .saveAsPDF({
              fileName: templateData?.name + ".pdf",
              dpi: store.dpi / pageSizeModifier,
              pixelRatio: 2 * qualityCount,
            })
            .then(() => {
              if (watermark) {
                store.history.ignore(() => {
                  store.deleteElements([watermark.id]); // Correctly remove the watermark without creating a history entry
                });
              }
            });
        }
        if (id) {
          await templateDownload({
            template_id: id,
            category_id: templateData?.category_id,
          });
        }
        resolve({ message: "Template Downloaded" });
      });

      showPromiseNotification(myPromise);
    },
    [
      dVideo,
      downloadVideo,
      downloadVideoType,
      id,
      pageSizeModifier,
      qualityCount,
      store,
      templateData?.category_id,
      templateData?.name,
      user?.is_subscribed,
    ]
  );
  const HandleClickPremium = useCallback(() => {
    // Swal.fire({
    //   icon: 'warning',
    //   title: t('alert'),
    //   text: t('premiumErrorMessage'),
    // });
    setIsSubscriptionModal(true);
  }, []);
  // const handlePublish = useCallback(() => {
  // Swal.fire({
  //   icon: 'success',
  //   title: 'Success',
  //   text: 'once admin accept your template after that You browse it !',
  // });
  // })

  const handlePublish = useCallback(() => {
    const myPromise = new Promise((resolve, reject) => {
      const templateFixSizes = FIX_SIZES?.flatMap(
        (item) => item.templates_size
      );

      const { height, width } = store.toJSON();

      const isFixSized = templateFixSizes?.some(
        (item) => item.height === height && item.width === width
      );
      console.log("templateFixSizes: ", isFixSized);

      if (isFixSized) {
        publishTemplate({ template_id: id }).then((response) => {
          if (response.code === 200) {
            Swal.fire({
              icon: "success",
              title: t("success"),
              text: t("successTemplatePublishMsg"),
            });
            setIsPublish(false);
            resolve(response);
          } else {
            reject(response);
          }
        });
      } else {
        reject({ message: "Fixed size templates only can be publish" });
      }
    });
    showPromiseNotification(myPromise);
  }, [id, store]);

  // useEffect(() => {
  //   let intervalId;

  //   if (isSubmitting) {
  //     const fetchDownloadPercentage = async () => {
  //       try {
  //         const response = await downloadPercentage({});
  //         console.log("response14: ", response);
  //         if (response.code === 200) {
  //           if (response.data.download_percentage < 95) {
  //             setProgress(response.data.download_percentage);
  //           }
  //         }
  //       } catch (error) {
  //         console.error("Error fetching download percentage:", error);
  //       }
  //     };

  //     intervalId = setInterval(fetchDownloadPercentage, 2000);
  //   }

  //   return () => {
  //     if (intervalId) clearInterval(intervalId);
  //   };
  // }, [isSubmitting]);
  const handleback = () => {
    setSubmitBackClick(true);
    Swal.fire({
      title: t("areYouSure"),
      text: t("saveTemplateBackTime"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: t("yesSaveIt"),
      cancelButtonText: t("leave"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const data = await handleSaveEdit();
          console.log("Save operation result:", data);
          if (data.message == "Updated") {
            navigate("/home");
          }
        } catch (error) {
          console.error("Save operation failed:", error);
          // Optionally handle the error (e.g., show a notification)
        }
      } else {
        navigate("/home");
      }
    });
  };
  const HandlePremiumRedirection = () => {
    setSubmitPremiumClick(true);
    Swal.fire({
      title: t("areYouSure"),
      text: t("saveTemplateBackTime"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: t("yesSaveIt"),
      cancelButtonText: t("cancelButtonText"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const data = await handleSaveEdit();
          console.log("Save operation result:", data);
          if (data.message == "Updated") {
            navigate("/subscription");
          }
        } catch (error) {
          console.error("Save operation failed:", error);
          // Optionally handle the error (e.g., show a notification)
        }
      } else {
        navigate("/subscription");
      }
    });
  };

  const HandleNewDesign = async () => {
    try {
      setIsNewDesign(true);
      const data = await handleSaveEdit();
      console.log("Save operation result:", data);
      if (data.message == "Updated") {
        store.clear();
        const pages = store.pages;
        pages.forEach((page) => store.deletePage(page.id));
        navigate("/add-template");
      }
    } catch (error) {
      console.error("Save operation failed:", error);
      // Optionally handle the error (e.g., show a notification)
    }
  };
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      console.log("event.returnValue: ", event);
      const confirmationMessage =
        "You have unsaved changes, do you really want to leave?";
      event.returnValue = confirmationMessage; // Standard for most browsers
      return confirmationMessage; // Required for some browsers
    };

    const handleVisibilityChange = (event) => {
      console.log("event.returnValue: ", event);
      const confirmationMessage =
        "You have unsaved changes, do you really want to leave?";
      alert(confirmationMessage); // Standard for most browsers
      return confirmationMessage;
      // if (document.visibilityState === 'hidden') {
      //   const confirmationMessage = 'You have unsaved changes, do you really want to leave12?';
      //   alert(confirmationMessage); // This alert serves as a warning when switching tabs
      // }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("blur", handleBeforeUnload);
    // document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      // document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [store]);
  return (
    <>
      <header className="header">
        <div className="row w-100">
          <div className="col-12">
            <nav className="navbar">
              <div className="headerLeft">
                <a
                  className="headerBackArrowLink ms-2"
                  // onClick={() => { navigate(-1) }}
                  onClick={() => {
                    handleback();
                  }}
                >
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/icon/headerleft.svg"
                    }
                    alt="logo"
                    className="img-contain"
                  />
                </a>
                <a
                  className="editor-navbar-brand"
                  // onClick={() => { navigate(-1) }}
                  onClick={() => {
                    handleback();
                  }}
                >
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/Craetivo_logo_white.png"
                    }
                    alt="logo"
                    className="comapany-logo img-contain"
                  />
                </a>
                <a
                  class="header-link"
                  onClick={() => {
                    setIsTemplateModalOpen(true);
                    // handleSaveEdit();
                  }}
                >
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/icon/designName.svg"
                    }
                    alt="headerIcon"
                    class="headerIconTwo"
                  />
                  {t("designName")}
                </a>
                {lastSegment != "add-template" && (
                  <a
                    class="header-link"
                    onClick={() => {
                      HandleNewDesign();
                    }}
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/icon/createNewDesign.svg"
                      }
                      alt="headerIcon"
                      class="headerIconTwo"
                    />
                    {t("createNewDesign")}
                  </a>
                )}
              </div>
              <ul className="header-nav">
                {/* <li class="header-item" data-bs-dismiss="offcanvas">
                  <Link to="/contact-us" class="header-link">
                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/Help.svg"} alt="headerIcon" class="headerIconTwo" />
                    {t("help")}
                  </Link>
                </li> */}
                <li className="header-item">
                  <a
                    className="header-link dropdown-toggle "
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    icon="download"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/images/icon/Help.svg"
                      }
                      alt="headerIcon"
                      className="headerIcon"
                    />
                    {t("help")}
                  </a>

                  <div
                    className="dropdown-menu filter_dropdown "
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <div className="create_list_menu">
                      <Link to="/contact-us" style={{ cursor: "pointer" }}>
                        <div className="list_line cursor-pointer">
                          <label style={{ cursor: "pointer" }}>
                            {t("footerContact")}
                          </label>
                        </div>
                      </Link>
                      <Link
                        to="/"
                        state={{ istutorial: true }}
                        style={{ cursor: "pointer" }}
                      >
                        <div className="list_line cursor-pointer">
                          <label style={{ cursor: "pointer" }}>
                            {t("tutorials")}
                          </label>
                        </div>
                      </Link>
                    </div>
                  </div>
                </li>
                {user?.is_subscribed != "1" && (
                  <li class="header-item" data-bs-dismiss="offcanvas">
                    <a
                      class="GoPremiumLink"
                      onClick={() => {
                        HandlePremiumRedirection();
                      }}
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/icon/premium-quality.svg"
                        }
                        alt="headerIcon"
                        class="headerIconTwo"
                      />
                      {t("goPremium")}
                    </a>
                  </li>
                )}
                {user?.is_subscribed == "1" &&
                  type != "add" &&
                  user?.subscribed_type === "enterprise" && templateName != "copy-template" ? (
                  // && user?.subscription_duration === "yearly"
                  <li
                    className="header-item"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsInviteMemberModalOpen(true);
                    }}
                  >
                    <a className="header-link">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/icon/add-event-icon.svg"
                        }
                        alt="headerIcon"
                        className="headerIcon"
                      />
                      {t("invitMember")}
                    </a>
                  </li>
                ) : (
                  <></>
                )}
                {templateData &&
                  (templateData?.type != "accepted" ||
                    templateUrlName == "copy-template") && (
                    <li
                      class="header-item"
                      onClick={() => {
                        setIsResizeModal(true);
                      }}
                    >
                      <a class="header-link">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/icon/resize.svg"
                          }
                          alt="headerIcon"
                          class="headerIconTwo"
                        />
                        {t("resize")}
                      </a>
                    </li>
                  )}
                <li
                  className="header-item"
                  onClick={() => {
                    handleSaveEdit();
                  }}
                >
                  <a className="header-link">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/images/icon/save.svg"
                      }
                      alt="headerIcon"
                      className="headerIconTwo"
                    />
                    {t("save")}
                  </a>
                </li>
                <li className="header-item">
                  <a
                    className="headerDownload dropdown-toggle "
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    aria-expanded="false"
                    icon="download"
                  >
                    {t("download")}
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/icon/download.svg"
                      }
                      alt="headerIcon"
                      className="headerIcon"
                    />
                  </a>

                  <div
                    className="DownloadDropDown dropdown-menu filter_dropdown "
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <ul>
                      <li>
                        <h5 style={{ fontSize: "18px" }}>
                          {t("downloadPolotno")}
                        </h5>
                      </li>
                      <li>
                        <p style={{ fontWeight: "600" }}>
                          {t("selectFileType")}
                        </p>
                      </li>
                      {/* <li className="link-box-li">
                        <div
                          className="nice-select"
                          style={{ width: "100%" }}
                          tabIndex="0"
                        >
                          <span className="">
                            {(downloadType == "pdf") ? (
                              t("DownloadPDF")
                            ) : downloadType == "video" ? (
                              t("DownloadMP4Video")
                            ) : downloadType == "png" ? (
                              t("DownloadImageAsPNG")
                            ) : downloadType == "svg" ? (
                              t("DownloadImageAsSVG")
                            ) : (
                              t("DownloadImageAsJPG")
                            )}
                          </span>
                          <ul className="list">
                            <li
                              data-value={0}
                              className="option selected"
                              onClick={() => {
                                setDownloadType('image')
                                setIsvideo(false)
                              }}
                            >
                              <div>
                                <label>{t("DownloadImageAsJPG")}</label>
                              </div>
                            </li>
                            <li
                              data-value={0}
                              className="option"
                              onClick={() => {
                                setDownloadType('png')
                                setIsvideo(false)
                              }}
                            >
                              <label>{t("DownloadImageAsPNG")}</label>
                            </li>
                            <li
                              data-value={0}
                              className="option"
                              onClick={() => {
                                setDownloadType('svg')
                                setIsvideo(false)
                              }}
                            >
                              <label>{t("DownloadImageAsSVG")}</label>
                            </li>
                            {isVideo && (
                              <li
                                data-value={0}
                                className="option"
                                onClick={() => {
                                  setDownloadType('video')
                                  setIsvideo(true)
                                }}
                              >
                                <label>{t("DownloadMP4Video")}</label>
                              </li>
                            )}
                            <li
                              data-value={1}
                              className="option"
                              onClick={() => {
                                if (user?.is_subscribed == 0) {
                                  HandleClickPremium()
                                } else {
                                  setDownloadType('pdf')
                                  setIsvideo(false)
                                }
                              }}
                            >
                              <label>{t("DownloadPDF")}</label>
                              {user?.is_subscribed == 0 && (
                                <img src={process.env.PUBLIC_URL + "/assets/images/icon/premium-quality-color.svg"} alt="" style={{ height: "17px", margin: "auto 2%", cursor: "pointer" }} ></img>
                              )}
                            </li>
                          </ul>
                        </div>
                      </li> */}
                      <li className="link-box-li">
                        <div
                          className="nice-select"
                          style={{ width: "100%", height: "revert" }}
                          tabIndex="0"
                        >
                          <div className="DownloadSelect">
                            {downloadType === "pdf" ? (
                              // t("DownloadPDF")
                              <>
                                <label>{t("pdfStandard")}</label>
                                <span className="option-description">
                                  {t("highQualityMultiPageDocument")}
                                </span>
                              </>
                            ) : downloadType === "video" ? (
                              // t("DownloadMP4Video")
                              <>
                                {/* <label>{t("video")}</label> */}
                                <label>MP4</label>
                                <span className="option-description">
                                  {t("videoFileFormat")}
                                </span>
                              </>
                            ) : downloadType === "png" ? (
                              // t("DownloadImageAsPNG")
                              <>
                                <label>PNG</label>
                                <span className="option-description">
                                  {t("highQualityImage")}
                                </span>
                              </>
                            ) : downloadType === "svg" ? (
                              // t("DownloadImageAsSVG")
                              <>
                                <label>SVG</label>
                                <span className="option-description">
                                  {t("vectorImageFormat")}
                                </span>
                              </>
                            ) : (
                              // t("DownloadImageAsJPG")
                              <>
                                <label>JPG</label>
                                <span className="option-description">
                                  {t("smallFileSizeImage")}
                                </span>
                              </>
                            )}
                          </div>
                          <ul className="list">
                            <li
                              data-value={0}
                              className="option selected"
                              onClick={() => {
                                setDownloadType("image");
                                setIsvideo(false);
                              }}
                            >
                              <div className="option-content">
                                <label>JPG</label>
                                <span className="option-description">
                                  {t("smallFileSizeImage")}
                                </span>
                              </div>
                            </li>
                            <li
                              data-value={0}
                              className="option"
                              onClick={() => {
                                if (user?.is_subscribed === 0) {
                                  HandleClickPremium();
                                } else {
                                  setDownloadType("png");
                                  setIsvideo(false);
                                }
                              }}
                            >
                              <div className="option-content">
                                <label>
                                  PNG
                                  {user?.is_subscribed === 0 && (
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/icon/premium-quality-color.svg"
                                      }
                                      alt=""
                                      style={{
                                        height: "17px",
                                        margin: "auto 2%",
                                        cursor: "pointer",
                                        paddingBottom: "1%",
                                      }}
                                    />
                                  )}
                                </label>
                                <span className="option-description">
                                  {t("highQualityImage")}
                                </span>
                              </div>
                            </li>
                            <li
                              data-value={0}
                              className="option"
                              onClick={() => {
                                if (user?.is_subscribed === 0) {
                                  HandleClickPremium();
                                } else {
                                  setDownloadType("svg");
                                  setIsvideo(false);
                                }
                              }}
                            >
                              <div className="option-content">
                                <label>
                                  SVG
                                  {user?.is_subscribed === 0 && (
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/icon/premium-quality-color.svg"
                                      }
                                      alt=""
                                      style={{
                                        height: "17px",
                                        margin: "auto 2%",
                                        cursor: "pointer",
                                        paddingBottom: "1%",
                                      }}
                                    />
                                  )}
                                </label>
                                <span className="option-description">
                                  {t("vectorImageFormat")}
                                </span>
                              </div>
                            </li>
                            {/* {isVideo && ( */}
                            <li
                              data-value={0}
                              className="option"
                              onClick={() => {
                                setDownloadType("video");
                                setIsvideo(true);
                              }}
                            >
                              <div className="option-content">
                                {/* <label>{t("video")}</label> */}
                                <label>MP4</label>
                                <span className="option-description">
                                  {t("videoFileFormat")}
                                </span>
                              </div>
                            </li>
                            {/* )} */}
                            <li
                              data-value={1}
                              className="option"
                              onClick={() => {
                                if (user?.is_subscribed === 0) {
                                  HandleClickPremium();
                                } else {
                                  setDownloadType("pdf");
                                  setIsvideo(false);
                                }
                              }}
                            >
                              {/* <div> */}
                              {/* <div> */}
                              <label
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {t("pdfStandard")}

                                {user?.is_subscribed === 0 && (
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/images/icon/premium-quality-color.svg"
                                    }
                                    alt=""
                                    style={{
                                      height: "17px",
                                      margin: "auto 2%",
                                      cursor: "pointer",
                                      paddingBottom: "1%",
                                    }}
                                  />
                                )}
                              </label>

                              <span className="option-description">
                                {t("highQualityMultiPageDocument")}
                              </span>
                              {/* </div> */}
                              {/* </div> */}
                            </li>
                          </ul>
                        </div>
                      </li>

                      {dVideo ? (
                        <li className="link-box-li">
                          <p style={{ fontWeight: "600", marginBottom: "1%" }}>
                            {t("selectvideoSize")}
                          </p>
                          <div
                            className="nice-select"
                            style={{ width: "100%" }}
                            tabIndex="0"
                          >
                            <span className="">{downloadVideoType}p</span>
                            <ul className="list">
                              <li
                                data-value={0}
                                className="option"
                                onClick={() => {
                                  if (user?.is_subscribed == 0) {
                                    HandleClickPremium();
                                  } else {
                                    setDownloadVideoType(1080);
                                  }
                                }}
                              >
                                <label>1080p</label>
                                {user?.is_subscribed == 0 && (
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/images/icon/premium-quality-color.svg"
                                    }
                                    alt=""
                                    style={{
                                      height: "17px",
                                      marginLeft: "2%",
                                      cursor: "pointer",
                                    }}
                                  ></img>
                                )}
                              </li>
                              <li
                                data-value={0}
                                className="option"
                                onClick={() => {
                                  setDownloadVideoType(720);
                                }}
                              >
                                <label>720p</label>
                              </li>
                              <li
                                data-value={0}
                                className="option"
                                onClick={() => {
                                  setDownloadVideoType(480);
                                }}
                              >
                                <label>480p</label>
                              </li>
                              <li
                                data-value={0}
                                className="option selected"
                                onClick={() => {
                                  setDownloadVideoType(360);
                                }}
                              >
                                <label>360p</label>
                              </li>
                              {/* )} */}
                            </ul>
                          </div>
                        </li>
                      ) : (
                        <>
                          <li className="d-flex">
                            <p style={{ fontWeight: "600" }}>
                              {t("imageQuality")}
                            </p>
                            {user?.is_subscribed == "0" && (
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/images/icon/premium-quality-color.svg"
                                }
                                alt=""
                                style={{
                                  height: "17px",
                                  marginLeft: "2%",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  HandleClickPremium();
                                }}
                              ></img>
                            )}
                          </li>
                          <li
                            className="InputRangeBoxLi"
                            onClick={() => {
                              // Call HandleClickPremium when the user clicks on the slider
                              if (user?.is_subscribed == "0") {
                                HandleClickPremium();
                              }
                            }}
                          >
                            <span>{t("low")}</span>
                            {/* <input
                              type="range"
                              min="2"
                              max="20"
                              value={qualityCount}
                              onChange={(e) => {
                                if (user?.is_subscribed == "1") {
                                  handleQualityCount(e)
                                } else {
                                  HandleClickPremium()
                                }
                              }}
                              style={{ cursor: 'pointer' }}
                            /> */}
                            <Slider
                              value={qualityCount}
                              labelRenderer={false}
                              disabled={user?.is_subscribed == "0"}
                              onChange={(quality) => {
                                if (user?.is_subscribed == "1") {
                                  setQualityCount(quality);
                                }
                                // else {
                                //   HandleClickPremium()
                                // }
                              }}
                              stepSize={0.2}
                              min={0.2}
                              max={300 / 72}
                              showTrackFill={false}
                            />
                            <span>{t("high")}</span>
                          </li>
                          <div>
                            {Math.round(store.width * qualityCount)} x{" "}
                            {Math.round(store.height * qualityCount)} px
                          </div>
                          {downloadType == "pdf" && (
                            <>
                              <li className="d-flex">
                                <p style={{ fontWeight: "600" }}>
                                  {t("pageSize")}
                                </p>
                              </li>
                              <li className="InputRangeBoxLi">
                                <span>{t("low")}</span>
                                {/* <input
                                  type="range"
                                  min="0.2"
                                  max="3"
                                  value={pageSizeModifier}
                                  onChange={(e) => {
                                    if (user?.is_subscribed == "1") {
                                      setPageSizeModifier(e.target.value)
                                    } else {
                                      HandleClickPremium()
                                    }
                                  }}
                                  showTrackFill={false}
                                  style={{ cursor: 'pointer' }}
                                /> */}
                                <Slider
                                  value={pageSizeModifier}
                                  labelRenderer={false}
                                  onChange={(pageSizeModifier) => {
                                    if (user?.is_subscribed == "1") {
                                      setPageSizeModifier(pageSizeModifier);
                                    } else {
                                      HandleClickPremium();
                                    }
                                  }}
                                  stepSize={0.2}
                                  min={0.2}
                                  max={3}
                                  showTrackFill={false}
                                />
                                <span>{t("high")}</span>
                              </li>
                              <div>
                                {unit.pxToUnitRounded({
                                  px: store.width * pageSizeModifier,
                                  dpi: store.dpi,
                                  precious: 0,
                                  unit: "mm",
                                })}{" "}
                                x{" "}
                                {unit.pxToUnitRounded({
                                  px: store.height * pageSizeModifier,
                                  dpi: store.dpi,
                                  precious: 0,
                                  unit: "mm",
                                })}{" "}
                                mm
                              </div>
                            </>
                          )}
                        </>
                      )}
                      {isSubmitting && (
                        <li>
                          <progress
                            value={progress}
                            max="100"
                            style={{ width: "100%" }}
                          ></progress>
                        </li>
                      )}
                      <li>
                        <button
                          className="btn-create"
                          type="button"
                          disabled={isSubmitting}
                          onClick={() => {
                            if (downloadType == "pdf") {
                              handleDownload("pdf");
                            } else if (downloadType == "video") {
                              handleDownload("video");
                            } else if (downloadType == "png") {
                              handleDownload("png");
                            } else if (downloadType == "svg") {
                              handleDownload("svg");
                            } else {
                              handleDownload("image");
                            }
                          }}
                        >
                          <div className="d-flex justify-content-center center-align">
                            {isSubmitting ? (
                              <>
                                {t("pleaseWait")}
                                <Lottie
                                  options={defaultOptions}
                                  height={25}
                                  width={80}
                                  style={{ margin: "0" }}
                                />
                              </>
                            ) : (
                              t("downloadPolotno")
                            )}
                          </div>
                        </button>
                      </li>
                    </ul>
                    {/* <a>
                        <div
                          className="list_line cursor-pointer"
                          onClick={() => {
                            handleDownload("image");
                          }}
                        >
                          <label>{t("DownloadImageAsJPG")}</label>
                        </div>
                      </a>
                      {isVideo && (
                        <a>
                          <div
                            className="list_line cursor-pointer"
                            onClick={() => {
                              handleDownload("video");
                            }}
                          >
                            <label>{t("DownloadMP4Video")}</label>
                          </div>
                        </a>
                      )}
                      <a>
                        <div
                          className="list_line cursor-pointer"
                          onClick={() => {
                            handleDownload("pdf");
                          }}
                        >
                          <label>{t("DownloadPDF")}</label>
                        </div>
                      </a> */}
                  </div>
                </li>
                {user.type === "designer" &&
                  templateData?.main_template_id == 0 &&
                  isPublish &&
                  templateData?.user_id === user.id &&
                  !templateData?.type?.length &&
                  lastSegment != "add-template" && (
                    <li
                      data-bs-dismiss="offcanvas"
                      className="header-item"
                      onClick={() => {
                        // handlePublish();
                        setIsPublishTemplateModalOpen(true);
                      }}
                    >
                      <a className="headerPublished">
                        {t("published")}
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/icon/public.svg"
                          }
                          alt="headerIcon"
                          className="headerIcon"
                        />
                      </a>
                    </li>
                  )}
              </ul>
              <div className="DounloadmMainItem">
                <div className="DounloadItem">
                  <a
                    className="headerDownload dropdown-toggle "
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    aria-expanded="false"
                    icon="download"
                  >
                    {t("download")}
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/icon/download.svg"
                      }
                      alt="headerIcon"
                      className="headerIcon"
                    />
                  </a>

                  {/* This is show in moblie screen  */}
                  <div
                    className="DownloadDropDown DownloadDropDownMobile dropdown-menu  filter_dropdown "
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <ul>
                      <li>
                        <h5 style={{ fontSize: "18px" }}>
                          {t("downloadPolotno")}
                        </h5>
                      </li>
                      <li>
                        <p style={{ fontWeight: "600" }}>
                          {t("selectFileType")}
                        </p>
                      </li>
                      <li className="link-box-li">
                        <div
                          className="nice-select"
                          style={{ width: "100%", height: "revert" }}
                          tabIndex="0"
                        >
                          <div className="DownloadSelect">
                            {downloadType === "pdf" ? (
                              <>
                                <label>{t("pdfStandard")}</label>
                                <span className="option-description">
                                  {t("highQualityMultiPageDocument")}
                                </span>
                              </>
                            ) : downloadType === "video" ? (
                              <>
                                {/* <label>{t("video")}</label> */}
                                <label>MP4</label>
                                <span className="option-description">
                                  {t("videoFileFormat")}
                                </span>
                              </>
                            ) : downloadType === "png" ? (
                              <>
                                <label>PNG</label>
                                <span className="option-description">
                                  {t("highQualityImage")}
                                </span>
                              </>
                            ) : downloadType === "svg" ? (
                              <>
                                <label>SVG</label>
                                <span className="option-description">
                                  {t("vectorImageFormat")}
                                </span>
                              </>
                            ) : (
                              <>
                                <label>JPG</label>
                                <span className="option-description">
                                  {t("smallFileSizeImage")}
                                </span>
                              </>
                            )}
                          </div>
                          <ul className="list">
                            <li
                              data-value={0}
                              className="option selected"
                              onClick={() => {
                                setDownloadType("image");
                                setIsvideo(false);
                              }}
                            >
                              <div className="option-content">
                                <label>JPG</label>
                                <span className="option-description">
                                  {t("smallFileSizeImage")}
                                </span>
                              </div>
                            </li>
                            <li
                              data-value={0}
                              className="option"
                              onClick={() => {
                                if (user?.is_subscribed === 0) {
                                  HandleClickPremium();
                                } else {
                                  setDownloadType("png");
                                  setIsvideo(false);
                                }
                              }}
                            >
                              <div className="option-content">
                                <label>
                                  PNG
                                  {user?.is_subscribed === 0 && (
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/icon/premium-quality-color.svg"
                                      }
                                      alt=""
                                      style={{
                                        height: "17px",
                                        margin: "auto 2%",
                                        cursor: "pointer",
                                        paddingBottom: "1%",
                                      }}
                                    />
                                  )}
                                </label>
                                <span className="option-description">
                                  {t("highQualityImage")}
                                </span>
                              </div>
                            </li>
                            <li
                              data-value={0}
                              className="option"
                              onClick={() => {
                                if (user?.is_subscribed === 0) {
                                  HandleClickPremium();
                                } else {
                                  setDownloadType("svg");
                                  setIsvideo(false);
                                }
                              }}
                            >
                              <div className="option-content">
                                <label>
                                  SVG
                                  {user?.is_subscribed === 0 && (
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/icon/premium-quality-color.svg"
                                      }
                                      alt=""
                                      style={{
                                        height: "17px",
                                        margin: "auto 2%",
                                        cursor: "pointer",
                                        paddingBottom: "1%",
                                      }}
                                    />
                                  )}
                                </label>
                                <span className="option-description">
                                  {t("vectorImageFormat")}
                                </span>
                              </div>
                            </li>
                            <li
                              data-value={0}
                              className="option"
                              onClick={() => {
                                setDownloadType("video");
                                setIsvideo(true);
                              }}
                            >
                              <div className="option-content">
                                {/* <label>{t("video")}</label> */}
                                <label>MP4</label>
                                <span className="option-description">
                                  {t("videoFileFormat")}
                                </span>
                              </div>
                            </li>
                            <li
                              data-value={1}
                              className="option"
                              onClick={() => {
                                if (user?.is_subscribed === 0) {
                                  HandleClickPremium();
                                } else {
                                  setDownloadType("pdf");
                                  setIsvideo(false);
                                }
                              }}
                            >
                              <label
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {t("pdfStandard")}

                                {user?.is_subscribed === 0 && (
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/images/icon/premium-quality-color.svg"
                                    }
                                    alt=""
                                    style={{
                                      height: "17px",
                                      margin: "auto 2%",
                                      cursor: "pointer",
                                      paddingBottom: "1%",
                                    }}
                                  />
                                )}
                              </label>

                              <span className="option-description">
                                {t("highQualityMultiPageDocument")}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </li>

                      {dVideo ? (
                        <li className="link-box-li">
                          <p style={{ fontWeight: "600", marginBottom: "1%" }}>
                            {t("selectvideoSize")}
                          </p>
                          <div
                            className="nice-select"
                            style={{ width: "100%" }}
                            tabIndex="0"
                          >
                            <span className="">{downloadVideoType}p</span>
                            <ul className="list">
                              <li
                                data-value={0}
                                className="option"
                                onClick={() => {
                                  if (user?.is_subscribed == 0) {
                                    HandleClickPremium();
                                  } else {
                                    setDownloadVideoType(1080);
                                  }
                                }}
                              >
                                <label>1080p</label>
                                {user?.is_subscribed == 0 && (
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/images/icon/premium-quality-color.svg"
                                    }
                                    alt=""
                                    style={{
                                      height: "17px",
                                      marginLeft: "2%",
                                      cursor: "pointer",
                                    }}
                                  ></img>
                                )}
                              </li>
                              <li
                                data-value={0}
                                className="option"
                                onClick={() => {
                                  setDownloadVideoType(720);
                                }}
                              >
                                <label>720p</label>
                              </li>
                              <li
                                data-value={0}
                                className="option"
                                onClick={() => {
                                  setDownloadVideoType(480);
                                }}
                              >
                                <label>480p</label>
                              </li>
                              <li
                                data-value={0}
                                className="option selected"
                                onClick={() => {
                                  setDownloadVideoType(360);
                                }}
                              >
                                <label>360p</label>
                              </li>
                            </ul>
                          </div>
                        </li>
                      ) : (
                        <>
                          <li className="d-flex">
                            <p style={{ fontWeight: "600" }}>
                              {t("imageQuality")}
                            </p>
                            {user?.is_subscribed == "0" && (
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/images/icon/premium-quality-color.svg"
                                }
                                alt=""
                                style={{
                                  height: "17px",
                                  marginLeft: "2%",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  HandleClickPremium();
                                }}
                              ></img>
                            )}
                          </li>
                          <li
                            className="InputRangeBoxLi"
                            onClick={() => {
                              if (user?.is_subscribed == "0") {
                                HandleClickPremium();
                              }
                            }}
                          >
                            <span>{t("low")}</span>

                            <Slider
                              value={qualityCount}
                              labelRenderer={false}
                              disabled={user?.is_subscribed == "0"}
                              onChange={(quality) => {
                                if (user?.is_subscribed == "1") {
                                  setQualityCount(quality);
                                }
                              }}
                              stepSize={0.2}
                              min={0.2}
                              max={300 / 72}
                              showTrackFill={false}
                            />
                            <span>{t("high")}</span>
                          </li>
                          <div>
                            {Math.round(store.width * qualityCount)} x{" "}
                            {Math.round(store.height * qualityCount)} px
                          </div>
                          {downloadType == "pdf" && (
                            <>
                              <li className="d-flex">
                                <p style={{ fontWeight: "600" }}>
                                  {t("pageSize")}
                                </p>
                              </li>
                              <li className="InputRangeBoxLi">
                                <span>{t("low")}</span>
                                <Slider
                                  value={pageSizeModifier}
                                  labelRenderer={false}
                                  onChange={(pageSizeModifier) => {
                                    if (user?.is_subscribed == "1") {
                                      setPageSizeModifier(pageSizeModifier);
                                    } else {
                                      HandleClickPremium();
                                    }
                                  }}
                                  stepSize={0.2}
                                  min={0.2}
                                  max={3}
                                  showTrackFill={false}
                                />
                                <span>{t("high")}</span>
                              </li>
                              <div>
                                {unit.pxToUnitRounded({
                                  px: store.width * pageSizeModifier,
                                  dpi: store.dpi,
                                  precious: 0,
                                  unit: "mm",
                                })}{" "}
                                x{" "}
                                {unit.pxToUnitRounded({
                                  px: store.height * pageSizeModifier,
                                  dpi: store.dpi,
                                  precious: 0,
                                  unit: "mm",
                                })}{" "}
                                mm
                              </div>
                            </>
                          )}
                        </>
                      )}
                      {isSubmitting && (
                        <li>
                          <progress
                            value={progress}
                            max="100"
                            style={{ width: "100%" }}
                          ></progress>
                        </li>
                      )}
                      <li>
                        <button
                          className="btn-create"
                          type="button"
                          disabled={isSubmitting}
                          onClick={() => {
                            if (downloadType == "pdf") {
                              handleDownload("pdf");
                            } else if (downloadType == "video") {
                              handleDownload("video");
                            } else if (downloadType == "png") {
                              handleDownload("png");
                            } else if (downloadType == "svg") {
                              handleDownload("svg");
                            } else {
                              handleDownload("image");
                            }
                          }}
                        >
                          <div className="d-flex justify-content-center center-align">
                            {isSubmitting ? (
                              <>
                                {t("pleaseWait")}
                                <Lottie
                                  options={defaultOptions}
                                  height={25}
                                  width={80}
                                  style={{ margin: "0" }}
                                />
                              </>
                            ) : (
                              t("downloadPolotno")
                            )}
                          </div>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
                <a
                  className="headerToggleBtn"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#headerWithOutLoginOffcanvas"
                >
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/icon/ToggleIcon.svg"
                    }
                    alt="logo"
                    className="comapany-logo img-contain"
                  />
                </a>
              </div>
            </nav>
          </div>
        </div>
        {/* <div className="container">
        </div> */}
      </header>
      <div
        className="offcanvas offcanvas-end headerWithOutLoginOffcanvas"
        tabIndex={-1}
        id="headerWithOutLoginOffcanvas"
      >
        <div className="offcanvas-header">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          />
        </div>
        <div className="offcanvas-body">
          <ul className="header-nav">
            <li
              class="header-item"
              data-bs-dismiss="offcanvas"
              onClick={() => {
                setIsTemplateModalOpen(true);
                // handleSaveEdit();
              }}
            >
              <a href="javascript:void(0);" class="header-link">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/icon/designName.svg"
                  }
                  alt="headerIcon"
                  class="headerIconTwo"
                />
                {t("designName")}
              </a>
            </li>
            {lastSegment != "add-template" && (
              <li
                class="header-item"
                data-bs-dismiss="offcanvas"
                onClick={() => {
                  HandleNewDesign();
                }}
              >
                <a href="javascript:void(0);" class="header-link">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/icon/createNewDesign.svg"
                    }
                    alt="headerIcon"
                    class="headerIconTwo"
                  />
                  {t("createNewDesign")}
                </a>
              </li>
            )}
            <li className="header-item">
              <a
                className="header-link dropdown-toggle "
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                icon="download"
              >
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/icon/Help.svg"}
                  alt="headerIcon"
                  className="headerIcon"
                />
                {t("help")}
              </a>

              <div
                className="dropdown-menu filter_dropdown "
                aria-labelledby="dropdownMenuButton1"
              >
                <div className="create_list_menu">
                  <Link to="/contact-us">
                    <div className="list_line cursor-pointer">
                      <label>{t("footerContact")}</label>
                    </div>
                  </Link>
                  <Link to="/" state={{ istutorial: true }}>
                    <div className="list_line cursor-pointer">
                      <label>{t("tutorials")}</label>
                    </div>
                  </Link>
                </div>
              </div>
            </li>
            {/* <li class="header-item" data-bs-dismiss="offcanvas">
              <Link to="/contact-us" class="header-link">
                <img src={process.env.PUBLIC_URL + "/assets/images/icon/Help.svg"} alt="headerIcon" class="headerIconTwo" />
                {t("help")}
              </Link>
            </li> */}
            {user?.is_subscribed != "1" && (
              <li class="header-item" data-bs-dismiss="offcanvas">
                <a
                  class="header-link"
                  onClick={() => {
                    HandlePremiumRedirection();
                  }}
                >
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/icon/premium-quality.svg"
                    }
                    alt="headerIcon"
                    class="headerIconTwo"
                  />
                  {t("goPremium")}
                </a>
              </li>
            )}
            {user?.is_subscribed == "1" &&
              type != "add" &&
              user?.subscribed_type === "enterprise" && templateName != "copy-template" && (
                // user?.subscription_duration === "yearly" &&
                <li
                  className="header-item"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsInviteMemberModalOpen(true);
                  }}
                >
                  <a className="header-link">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/icon/add-event-icon.svg"
                      }
                      alt="headerIcon"
                      className="headerIcon"
                    />
                    {t("invitMember")}
                  </a>
                </li>
              )}
            {templateData &&
              (templateData?.type != "accepted" ||
                templateUrlName == "copy-template") && (
                <li
                  class="header-item"
                  onClick={() => {
                    setIsResizeModal(true);
                  }}
                  data-bs-dismiss="offcanvas"
                >
                  <a href="#" class="header-link">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/icon/resize.svg"
                      }
                      alt="headerIcon"
                      class="headerIconTwo"
                    />
                    {t("resize")}
                  </a>
                </li>
              )}
            <li
              className="header-item"
              data-bs-dismiss="offcanvas"
              onClick={() => {
                handleSaveEdit();
              }}
            >
              <a className="header-link">
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/icon/save.svg"}
                  alt="headerIcon"
                  className="headerIconTwo"
                />
                {t("save")}
              </a>
            </li>

            <li className="header-item show_374">
              <a
                className="headerDownload dropdown-toggle "
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                data-bs-auto-close="outside"
                aria-expanded="false"
                icon="download"
              >
                {t("download")}
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/icon/download.svg"
                  }
                  alt="headerIcon"
                  className="headerIcon"
                />
              </a>

              <div
                className="DownloadDropDown dropdown-menu filter_dropdown "
                aria-labelledby="dropdownMenuButton1"
              >
                <ul>
                  <li>
                    <h5 style={{ fontSize: "18px" }}>{t("downloadPolotno")}</h5>
                  </li>
                  <li>
                    <p style={{ fontWeight: "600" }}>{t("selectFileType")}</p>
                  </li>
                  {/* <li className="link-box-li">
                    <div
                      className="nice-select"
                      style={{ width: "100%" }}
                      tabIndex="0"
                    >
                      <span className="">
                        {(downloadType == "pdf") ? (
                          t("DownloadPDF")
                        ) : downloadType == "video" ? (
                          t("DownloadMP4Video")
                        ) : downloadType == "png" ? (
                          t("DownloadImageAsPNG")
                        ) : downloadType == "svg" ? (
                          t("DownloadImageAsSVG")
                        ) : (
                          t("DownloadImageAsJPG")
                        )}
                      </span>
                      <ul className="list">
                        <li
                          data-value={0}
                          className="option selected"
                          onClick={() => {
                            setDownloadType('image')
                            setIsvideo(false)
                          }}
                        >
                          <label>{t("DownloadImageAsJPG")}</label>
                        </li>
                        <li
                          data-value={0}
                          className="option"
                          onClick={() => {
                            setDownloadType('png')
                            setIsvideo(false)
                          }}
                        >
                          <label>{t("DownloadImageAsPNG")}</label>
                        </li>
                        <li
                          data-value={0}
                          className="option"
                          onClick={() => {
                            setDownloadType('svg')
                            setIsvideo(false)
                          }}
                        >
                          <label>{t("DownloadImageAsSVG")}</label>
                        </li>
                        {isVideo && (
                          <li
                            data-value={0}
                            className="option"
                            onClick={() => {
                              setDownloadType('video')
                              setIsvideo(true)
                            }}
                          >
                            <label>{t("DownloadMP4Video")}</label>
                          </li>
                        )}
                        <li
                          data-value={1}
                          className="option"
                          onClick={() => {
                            if (user?.is_subscribed == 0) {
                              HandleClickPremium()
                            } else {
                              setDownloadType('pdf')
                              setIsvideo(false)
                            }
                          }}
                        >
                          <label>{t("DownloadPDF")}</label>
                          {user?.is_subscribed == 0 && (
                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/premium-quality-color.svg"} alt="" style={{ height: "17px", margin: "0 0 6px 0", cursor: "pointer" }} ></img>
                          )}
                        </li>
                      </ul>
                    </div>
                  </li> */}
                  <li className="link-box-li">
                    <div
                      className="nice-select"
                      style={{ width: "100%", height: "revert" }}
                      tabIndex="0"
                    >
                      <div className="DownloadSelect">
                        {downloadType === "pdf" ? (
                          // t("DownloadPDF")
                          <>
                            <label>{t("pdfStandard")}</label>
                            <span className="option-description">
                              {t("highQualityMultiPageDocument")}
                            </span>
                          </>
                        ) : downloadType === "video" ? (
                          // t("DownloadMP4Video")
                          <>
                            {/* <label>{t("video")}</label> */}
                            <label>MP4</label>
                            <span className="option-description">
                              {t("videoFileFormat")}
                            </span>
                          </>
                        ) : downloadType === "png" ? (
                          // t("DownloadImageAsPNG")
                          <>
                            <label>PNG</label>
                            <span className="option-description">
                              {t("highQualityImage")}
                            </span>
                          </>
                        ) : downloadType === "svg" ? (
                          // t("DownloadImageAsSVG")
                          <>
                            <label>SVG</label>
                            <span className="option-description">
                              {t("vectorImageFormat")}
                            </span>
                          </>
                        ) : (
                          // t("DownloadImageAsJPG")
                          <>
                            <label>JPG</label>
                            <span className="option-description">
                              {t("smallFileSizeImage")}
                            </span>
                          </>
                        )}
                      </div>
                      <ul className="list">
                        <li
                          data-value={0}
                          className="option selected"
                          onClick={() => {
                            setDownloadType("image");
                            setIsvideo(false);
                          }}
                        >
                          <div className="option-content">
                            <label>JPG</label>
                            <span className="option-description">
                              {t("smallFileSizeImage")}
                            </span>
                          </div>
                        </li>
                        <li
                          data-value={0}
                          className="option"
                          onClick={() => {
                            if (user?.is_subscribed === 0) {
                              HandleClickPremium();
                            } else {
                              setDownloadType("png");
                              setIsvideo(false);
                            }
                          }}
                        >
                          <div className="option-content">
                            <label>
                              PNG
                              {user?.is_subscribed === 0 && (
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/images/icon/premium-quality-color.svg"
                                  }
                                  alt=""
                                  style={{
                                    height: "17px",
                                    margin: "auto 2%",
                                    cursor: "pointer",
                                    paddingBottom: "1%",
                                  }}
                                />
                              )}
                            </label>
                            <span className="option-description">
                              {t("highQualityImage")}
                            </span>
                          </div>
                        </li>
                        <li
                          data-value={0}
                          className="option"
                          onClick={() => {
                            if (user?.is_subscribed === 0) {
                              HandleClickPremium();
                            } else {
                              setDownloadType("svg");
                              setIsvideo(false);
                            }
                          }}
                        >
                          <div className="option-content">
                            <label>
                              SVG
                              {user?.is_subscribed === 0 && (
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/images/icon/premium-quality-color.svg"
                                  }
                                  alt=""
                                  style={{
                                    height: "17px",
                                    margin: "auto 2%",
                                    cursor: "pointer",
                                    paddingBottom: "1%",
                                  }}
                                />
                              )}
                            </label>
                            <span className="option-description">
                              {t("vectorImageFormat")}
                            </span>
                          </div>
                        </li>
                        {/* {isVideo && ( */}
                        <li
                          data-value={0}
                          className="option"
                          onClick={() => {
                            setDownloadType("video");
                            setIsvideo(true);
                          }}
                        >
                          <div className="option-content">
                            {/* <label>{t("video")}</label> */}
                            <label>MP4</label>
                            <span className="option-description">
                              {t("videoFileFormat")}
                            </span>
                          </div>
                        </li>
                        {/* )} */}
                        <li
                          data-value={1}
                          className="option"
                          onClick={() => {
                            if (user?.is_subscribed === 0) {
                              HandleClickPremium();
                            } else {
                              setDownloadType("pdf");
                              setIsvideo(false);
                            }
                          }}
                        >
                          {/* <div> */}
                          {/* <div> */}
                          <label
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {t("pdfStandard")}
                            {user?.is_subscribed === 0 && (
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/images/icon/premium-quality-color.svg"
                                }
                                alt=""
                                style={{
                                  height: "17px",
                                  margin: "auto 2%",
                                  cursor: "pointer",
                                  paddingBottom: "1%",
                                }}
                              />
                            )}
                          </label>
                          <span className="option-description">
                            {t("highQualityMultiPageDocument")}
                          </span>
                          {/* </div> */}
                          {/* </div> */}
                        </li>
                      </ul>
                    </div>
                  </li>
                  {dVideo ? (
                    <li className="link-box-li">
                      <p style={{ fontWeight: "600", marginBottom: "1%" }}>
                        {t("selectvideoSize")}
                      </p>
                      <div
                        className="nice-select"
                        style={{ width: "100%" }}
                        tabIndex="0"
                      >
                        <span className="">{downloadVideoType}p</span>
                        <ul className="list">
                          <li
                            data-value={0}
                            className="option selected"
                            onClick={() => {
                              if (user?.is_subscribed == 0) {
                                HandleClickPremium();
                              } else {
                                setDownloadVideoType(1080);
                              }
                            }}
                          >
                            <label>1080p</label>
                            {user?.is_subscribed == 0 && (
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/images/icon/premium-quality-color.svg"
                                }
                                alt=""
                                style={{
                                  height: "17px",
                                  marginLeft: "2%",
                                  cursor: "pointer",
                                }}
                              ></img>
                            )}
                          </li>
                          <li
                            data-value={0}
                            className="option"
                            onClick={() => {
                              setDownloadVideoType(720);
                            }}
                          >
                            <label>720p</label>
                          </li>
                          <li
                            data-value={0}
                            className="option"
                            onClick={() => {
                              setDownloadVideoType(480);
                            }}
                          >
                            <label>480p</label>
                          </li>
                          <li
                            data-value={0}
                            className="option selected"
                            onClick={() => {
                              setDownloadVideoType(360);
                            }}
                          >
                            <label>360p</label>
                          </li>
                          {/* )} */}
                        </ul>
                      </div>
                    </li>
                  ) : (
                    <>
                      <li className="d-flex">
                        <p style={{ fontWeight: "600" }}>{t("imageQuality")}</p>
                        {user?.is_subscribed == "0" && (
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/icon/premium-quality-color.svg"
                            }
                            alt=""
                            style={{
                              height: "17px",
                              marginLeft: "2%",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              HandleClickPremium();
                            }}
                          ></img>
                        )}
                      </li>
                      <li
                        className="InputRangeBoxLi"
                        onClick={() => {
                          // Call HandleClickPremium when the user clicks on the slider
                          if (user?.is_subscribed == "0") {
                            HandleClickPremium();
                          }
                        }}
                      >
                        <span>{t("low")}</span>
                        {/* <input
                          type="range"
                          min="2"
                          max="20"
                          value={qualityCount}
                          onChange={(e) => {
                            if (user?.is_subscribed == "1") {
                              handleQualityCount(e)
                            } else {
                              HandleClickPremium()
                            }
                          }}
                          style={{ cursor: 'pointer' }}
                        /> */}
                        <Slider
                          value={qualityCount}
                          labelRenderer={false}
                          disabled={user?.is_subscribed == "0"}
                          onChange={(quality) => {
                            if (user?.is_subscribed == "1") {
                              setQualityCount(quality);
                            }
                            // else {
                            //   HandleClickPremium()
                            // }
                          }}
                          stepSize={0.2}
                          min={0.2}
                          max={300 / 72}
                          showTrackFill={false}
                        />
                        <span>{t("high")}</span>
                      </li>
                      <div>
                        {Math.round(store.width * qualityCount)} x{" "}
                        {Math.round(store.height * qualityCount)} px
                      </div>
                      {downloadType == "pdf" && (
                        <>
                          <li className="d-flex">
                            <p style={{ fontWeight: "600" }}>{t("pageSize")}</p>
                          </li>
                          <li className="InputRangeBoxLi">
                            <span>{t("low")}</span>
                            {/* <input
                                  type="range"
                                  min="0.2"
                                  max="3"
                                  value={pageSizeModifier}
                                  onChange={(e) => {
                                    if (user?.is_subscribed == "1") {
                                      setPageSizeModifier(e.target.value)
                                    } else {
                                      HandleClickPremium()
                                    }
                                  }}
                                  showTrackFill={false}
                                  style={{ cursor: 'pointer' }}
                                /> */}
                            <Slider
                              value={pageSizeModifier}
                              labelRenderer={false}
                              onChange={(pageSizeModifier) => {
                                if (user?.is_subscribed == "1") {
                                  setPageSizeModifier(pageSizeModifier);
                                } else {
                                  HandleClickPremium();
                                }
                              }}
                              stepSize={0.2}
                              min={0.2}
                              max={3}
                              showTrackFill={false}
                            />
                            <span>{t("high")}</span>
                          </li>
                          <div>
                            {unit.pxToUnitRounded({
                              px: store.width * pageSizeModifier,
                              dpi: store.dpi,
                              precious: 0,
                              unit: "mm",
                            })}{" "}
                            x{" "}
                            {unit.pxToUnitRounded({
                              px: store.height * pageSizeModifier,
                              dpi: store.dpi,
                              precious: 0,
                              unit: "mm",
                            })}{" "}
                            mm
                          </div>
                        </>
                      )}
                    </>
                  )}
                  {isSubmitting && (
                    <li>
                      <progress
                        value={progress}
                        max="100"
                        style={{ width: "100%" }}
                      ></progress>
                    </li>
                  )}
                  <li>
                    <button
                      className="btn-create"
                      type="button"
                      disabled={isSubmitting}
                      onClick={() => {
                        if (downloadType == "pdf") {
                          handleDownload("pdf");
                        } else if (downloadType == "video") {
                          handleDownload("video");
                        } else if (downloadType == "png") {
                          handleDownload("png");
                        } else if (downloadType == "svg") {
                          handleDownload("svg");
                        } else {
                          handleDownload("image");
                        }
                      }}
                    >
                      <div className="d-flex justify-content-center center-align">
                        {isSubmitting ? (
                          <>
                            {t("pleaseWait")}
                            <Lottie
                              options={defaultOptions}
                              height={25}
                              width={80}
                              style={{ margin: "0" }}
                            />
                          </>
                        ) : (
                          t("downloadPolotno")
                        )}
                      </div>
                      {/* {t("downloadPolotno")} */}
                    </button>
                  </li>
                </ul>
              </div>
            </li>
            {/* <li className="header-item">
              <a
                className="headerDownload dropdown-toggle "
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                data-bs-auto-close="outside"
                aria-expanded="false"
                icon="download"
              >
                {t("download")}
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/icon/download.svg"
                  }
                  alt="headerIcon"
                  className="headerIcon"
                />
              </a>

              <div
                className="dropdown-menu filter_dropdown "
                aria-labelledby="dropdownMenuButton1"
              >
                <div className="create_list_menu">
                  <a>
                    <div
                      className="list_line cursor-pointer"
                      onClick={() => {
                        handleDownload("image");
                      }}
                    >
                      <label>{t("DownloadImageAsJPG")}</label>
                    </div>
                  </a>
                  {isVideo && (
                    <a>
                      <div
                        className="list_line cursor-pointer"
                        onClick={() => {
                          handleDownload("video");
                        }}
                      >
                        <label>{t("DownloadMP4Video")}</label>
                      </div>
                    </a>
                  )}
                  <a>
                    <div
                      className="list_line cursor-pointer"
                      onClick={() => {
                        handleDownload("pdf");
                      }}
                    >
                      <label>{t("DownloadPDF")}</label>
                    </div>
                  </a>
                </div>
              </div>
            </li> */}
            {user.type === "designer" &&
              templateData?.main_template_id == 0 &&
              isPublish &&
              templateData?.user_id === user.id &&
              !templateData?.type?.length &&
              lastSegment != "add-template" && (
                <li
                  data-bs-dismiss="offcanvas"
                  className="header-item"
                  onClick={() => {
                    // handlePublish();
                    setIsPublishTemplateModalOpen(true);
                  }}
                >
                  <a className="headerPublished">
                    {t("published")}
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/icon/public.svg"
                      }
                      alt="headerIcon"
                      className="headerIcon"
                    />
                  </a>
                </li>
              )}
          </ul>
        </div>
      </div>
      {isSubscriptionModal && (
        <SubscriptionModal
          isSubscriptionModal={isSubscriptionModal}
          setIsSubscriptionModal={setIsSubscriptionModal}
        />
      )}
    </>
  );
};

export default Header;
