import React, { useState, useEffect, useCallback } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useWindowSize from "../hooks/use-window-size";
import { getAllCategoriesSubcategories, logout } from "../api/apiHandler";
import LoginModal from "../pages/auth/Login/LoginModal";
import SignupModal from "../pages/auth/Signup/SignupModal";
import ForgotPassword from "../pages/auth/Login/components/ForgotPassword";
import ResetPassword from "../pages/auth/Login/components/ResetPassword";
import LoginOtpVerify from "../pages/auth/Login/components/OtpVerify";
import SignUpOtpVerify from "../pages/auth/Signup/components/OtpVerify";
import Swal from "sweetalert2";
import { removeUserDataFromLocal } from "../Common/LocalStorageService";
import { showPromiseNotification } from "../Common/notification";

const Header = (props) => {
  const {
    openLogin,
    setOpenLogin = () => {},
    openSignup,
    setOpenSignup,
    state = "",
    setIsShare,
  } = props;
  console.log("state444 555: ", state.state, openLogin);
  const [isLogin, setIsLogin] = useState(false);
  const [isSignup, setIsSignup] = useState(false);
  const { t, i18n } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [loginOtpverify, setLoginOtpverify] = useState(false);
  const [forgotemail, setForgotemail] = useState(false);
  const [resetpassword, setresetpassword] = useState(false);

  const [loginResponse, setLoginResponse] = useState({});
  const [isLoginVerification, setIsLoginVerification] = useState(false);
  const [onSuccessSignup, setSuccessSignUp] = useState();
  const [signupOtpverify, setSignupOtpverify] = useState(false);
  console.log("categoryData: ", categoryData);
  const navigate = useNavigate();
  const location = useLocation();
  const toggleMenu = () => {
    console.log("click");
    setMenuOpen(!menuOpen);
  };
  const windowSize = useWindowSize();
  const handleCustomerStory = () => {
    if (location.pathname !== "/") {
      navigate("/", {
        state: "testimonial",
      });
      return;
    }
    document.getElementById("testimonial").scrollIntoView({
      behavior: "smooth",
      block: windowSize.width >= 992 ? "center" : "start",
    });
  };

  const handlePricing = () => {
    if (location.pathname !== "/") {
      navigate("/", {
        state: "pricing",
      });
      return;
    }
    document.getElementById("pricing").scrollIntoView({
      behavior: "smooth",
      block: windowSize.width >= 992 ? "center" : "start",
    });
  };

  const handleLogoClick = () => {
    console.log("kjkjfkdjfkdfjk");

    window.scrollTo(0, 0);
    // .scrollIntoView({
    //   behavior: "smooth",
    //   block: "start",
    // });
  };

  const generateListItems = (items, t) => {
    return items.map((item, index) => (
      <li key={index}>
        <a href="#">{t(item)}</a>
      </li>
    ));
  };

  const MegamenuSection = ({ title, items, t }) => (
    <>
      <h4>{t(title)}</h4>
      <ul className="megamenu_all">
        {generateListItems(items, t)}
        <li className="last-child">
          <Link to={`/template/${title}`} className="fw-bold">
            {t("viewAll")}{" "}
            <img
              src={process.env.PUBLIC_URL + "/assets/images/arrow-right.svg"}
            />
          </Link>
        </li>
      </ul>
    </>
  );

  const closeLoginModal = () => {
    setIsLogin(false);
    setOpenLogin(false);
  };
  const closeSignupModal = () => {
    setIsSignup(false);
    setOpenSignup && setOpenSignup(false);
  };

  const openLoginModal = () => {
    setIsLogin(true);
    closeSignupModal();
  };
  const HandleLoginModal = (event) => {
    event.preventDefault(); // Prevent form submission
    openLoginModal();
  };
  const openSignupModal = () => {
    closeSignupModal(false);
    setIsSignup(true);
  };
  const HandleSignupModal = (event) => {
    event.preventDefault(); // Prevent form submission
    openSignupModal();
    closeLoginModal();
  };

  const openForgotModal = () => {
    setForgotemail(true);
  };
  const MegamenuColumn = ({ title, items = [], t, category_id }) => {
    if (items.length > 0) {
      return (
        <div className="col-lg-4 col-sm-12 col-12 mb-3">
          <h4>{t(title)}</h4>
          <ul className="megamenu_all">
            {items.slice(0, 3).map((item, index) => (
              <li key={index}>
                {/* <Link to={`/template/category/${item.id}`}>{t(item.name)}</Link> */}
                <Link to={`/template/category/${item?.id}`}>
                  {t(item.name)}
                </Link>
              </li>
            ))}
            <li className="last-child">
              <Link to={`/home`} state={{ category_id }} className="fw-bold">
                {t("viewAll")}{" "}
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/arrow-right.svg"
                  }
                />
              </Link>
            </li>
          </ul>
        </div>
      );
    }
  };
  const forgotmodal = (event) => {
    event.preventDefault(); // Prevent form submission
    openForgotModal();
    closeLoginModal();
  };

  const closeForgotModal = () => {
    setForgotemail(false);
  };

  // console.log(errors, "errorrrrr");

  const openResetModal = () => {
    setresetpassword(true);
  };

  const closeResetModal = () => {
    setresetpassword(false);
  };

  const openDashboardModal = () => {
    setLoginOtpverify(true);
  };

  const closeDashboardModal = () => {
    setLoginOtpverify(false);
  };

  const closeSignupOtpModal = () => {
    setSignupOtpverify(false);
  };

  const openSignupOtpModal = () => {
    closeSignupModal();
    setSignupOtpverify(true);
  };

  useEffect(() => {
    getAllCategoriesSubcategories({}).then((response) => {
      if (response.code == 200) {
        setCategoryData(response.data);
      }
    });
  }, []);

  // useEffect(() => {
  //   handleTutorial()
  // }, [])
  return (
    <>
      <div>
        <header className="header-main">
          <div className="container">
            <div className="d-flex flex-wrap align-items-center justify-content-between justify-content-lg-start">
              <Link
                to="/"
                id="brand-logo"
                className="nav-brand"
                onClick={() => {
                  handleLogoClick();
                }}
              >
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/Logosvg1.svg"}
                />
              </Link>
              <span
                className={`toggle-button ${menuOpen ? "button-open" : ""}`}
                onClick={toggleMenu}
              >
                <div className="menu-bar menu-bar-top" />
                <div className="menu-bar menu-bar-middle" />
                <div className="menu-bar menu-bar-bottom" />
              </span>
              <div
                className={`menu-wrap ${menuOpen ? "menu-show" : ""}`}
                id="menu-1"
              >
                <div className="menu-sidebar">
                  <ul className="menu">
                    <li className="dropdown">
                      <a
                        href=""
                        className="dropdown-toggle cursor-pointer"
                        role="button"
                        id="dropdownMenuLink"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {/* {t("headercategory")} */}
                        {t("headerTemplate")}
                      </a>
                      <div
                        className="dropdown-menu mega-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <>
                          {categoryData.length > 0 &&
                            categoryData.map((item, index) => (
                              <MegamenuColumn
                                title={item.name}
                                items={item.subcategory_details}
                                t={t}
                                category_id={item.id}
                              />
                            ))}
                          {/* <MegamenuSection
                            title="themes"
                            items={[
                              "events",
                              "smallBusiness",
                              "church",
                              "valentinesDay",
                            ]}
                            t={t}
                          />
                          <MegamenuSection
                            title="sizes"
                            items={["flyers", "a4", "posters", "albumCovers"]}
                            t={t}
                          />
                          <MegamenuSection
                            title="socialMedia"
                            items={[
                              "instagramReels",
                              "tiktokVideo",
                              "instagramPost",
                              "facebookPost",
                            ]}
                            t={t}
                          /> */}
                        </>
                      </div>
                    </li>
                    <li>
                      <a
                        onClick={() => {
                          handlePricing();
                          setMenuOpen(false);
                        }}
                        id="div-101"
                        data-type="page-transition"
                        className="data-link-1 cursor-pointer"
                      >
                        {t("headerpricing")}
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() => {
                          handleCustomerStory();
                        }}
                        id="div-103"
                        data-type="page-transition"
                        className="data-link-1 cursor-pointer"
                      >
                        {t("CustomerStories")}
                      </a>
                    </li>
                  </ul>
                  <div className="text-center auth-button mt-4">
                    <a
                      onClick={(e) => {
                        HandleLoginModal(e);
                      }}
                      className="btn me-2 btn-login mb-2"
                    >
                      {t("loginButton")}
                    </a>
                    <a
                      onClick={(e) => {
                        HandleSignupModal(e);
                      }}
                      className="btn btn-outline mb-2"
                    >
                      {t("signupButton")}
                    </a>
                  </div>
                </div>
              </div>
              <ul className="nav col-12 col-lg-auto me-lg-auto nav_menu">
                <li className="dropdown">
                  <a
                    href="#"
                    className="sub_category nav-link dropdown-toggle"
                    // role="button"
                    // id="dropdownMenuLink"
                    // data-bs-toggle="dropdown"
                    // aria-expanded="false"
                  >
                    {/* {t("headercategory")} */}
                    {t("headerTemplate")}
                  </a>
                  <div
                    className="mega-menu"
                    // aria-labelledby="dropdownMenuLink"
                  >
                    <div className="row">
                      <>
                        {categoryData.length > 0 &&
                          categoryData.map((item, index) => (
                            <MegamenuColumn
                              title={item.name}
                              items={item.subcategory_details}
                              t={t}
                              category_id={item.id}
                            />
                          ))}
                      </>
                    </div>
                  </div>
                </li>

                <>
                  <li
                    onClick={() => {
                      handlePricing();
                    }}
                  >
                    <a
                      // href="#pricing"
                      data-type="page-transition"
                      className="nav-link"
                    >
                      {t("headerpricing")}
                    </a>
                  </li>
                  <li
                    onClick={() => {
                      handleCustomerStory();
                    }}
                  >
                    <a
                      // href="#testimonial"
                      data-type="page-transition"
                      className="nav-link"
                    >
                      {t("CustomerStories")}
                    </a>
                  </li>
                </>
              </ul>
              <div className="text-end auth-button d-none d-lg-block">
                <a
                  onClick={(e) => {
                    HandleLoginModal(e);
                  }}
                  className="btn me-2 btn-login mb-2"
                >
                  {t("loginButton")}
                </a>
                <a
                  onClick={(e) => {
                    HandleSignupModal(e);
                  }}
                  className="btn btn-outline mb-2"
                >
                  {t("signupButton")}
                </a>
              </div>
            </div>
          </div>
        </header>
      </div>
      {(isLogin || openLogin) && (
        <LoginModal
          isLogin={isLogin || openLogin}
          closeLoginModal={closeLoginModal}
          forgotmodal={forgotmodal}
          openDashboardModal={openDashboardModal}
          HandleSignupModal={HandleSignupModal}
          setLoginResponse={setLoginResponse}
          setIsLoginVerification={setIsLoginVerification}
          state={state?.state}
          setIsShare={setIsShare}
        />
      )}
      {(isSignup || openSignup) && (
        <SignupModal
          isSignup={isSignup || openSignup}
          openLoginModal={openLoginModal}
          closeSignupModal={closeSignupModal}
          setSuccessSignUp={setSuccessSignUp}
          openDashboardModal={openSignupOtpModal}
          state={state?.state}
        />
      )}
      {forgotemail && (
        <ForgotPassword
          forgotemail={forgotemail}
          setLoginResponse={setLoginResponse}
          closeForgotModal={closeForgotModal}
          openDashboardModal={openDashboardModal}
        />
      )}

      {resetpassword && (
        <ResetPassword
          loginResponse={loginResponse}
          resetpassword={resetpassword}
          closeResetModal={closeResetModal}
        />
      )}

      {loginOtpverify && (
        <LoginOtpVerify
          otpverify={loginOtpverify}
          otpData={loginResponse}
          setLoginResponse={setLoginResponse}
          closeDashboardModal={closeDashboardModal}
          openResetModal={openResetModal}
          isLogin={isLoginVerification}
        />
      )}

      {signupOtpverify && (
        <SignUpOtpVerify
          otpverify={signupOtpverify}
          closeDashboardModal={closeSignupOtpModal}
          onSuccessSignup={onSuccessSignup}
          state={state?.state}
        />
      )}
    </>
  );
};

export default Header;
