import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setlanguage } from "../store/slice/Changelanguage";
import Swal from "sweetalert2";
import BecomeDesigner from "./BecomeDesignerModal";
import {
  getUserDataFromLocal,
  removeUserDataFromLocal,
} from "../Common/LocalStorageService";
import DeleteAccont from "./DeleteAccont";
import RatingModal from "./RatingModal";
import { logout } from "../api/apiHandler";
import { showPromiseNotification } from "../Common/notification";
import { useFilter } from "../Common/FilterContex";
import SubscriptionModal from "../Common/SubscriptionModal";

const Innerheader = ({ isLoading = 0 }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [userDetails, setUserDetails] = useState(getUserDataFromLocal());
  const designerType = getUserDataFromLocal()?.type;
  const changelanguage = useSelector((state) => state.changelanguage);
  const { prevFilters, setPrevFilters } = useFilter();
  console.log('prevFilters44444444444: ', prevFilters);
  const [isSubscriptionModal, setIsSubscriptionModal] = useState(false)

  const [menuOpen, setMenuOpen] = useState(false);
  const [submitFilter, setSubmitFilter] = useState(false);
  const [bocomeDesignerModalOpen, setBocomeDesignerModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [testiMonialModalOpen, setIsTestimonialModalOpen] = useState(false);
  const [language, setLanguage] = useState(
    changelanguage != null ? changelanguage : "EN"
  );

  const initialValue = useMemo(() => {
    return { global_search: "", global_colors: [], global_orientation: "" };
  }, []);
  const [filter, setFilter] = useState({
    ...initialValue,
    ...prevFilters,
  });

  console.log('filter1111: ', filter);

  // old working in home page
  const applyFilter = useCallback(() => {
    setPrevFilters({ ...filter, submitFilter: true });
    setSubmitFilter(true)
  }, [filter, setPrevFilters]);

  const handleClearFilters = useCallback(() => {
    // setPrevFilters({ ...prevFilters, global_orientation: initialValue.global_orientation });
    setPrevFilters(initialValue);
    setFilter(initialValue);
    setSubmitFilter(true)
  }, [initialValue, setPrevFilters]);

  const clearFilter = useCallback(() => {
    setFilter((prev) => {
      return { ...prev, global_search: initialValue.global_search };
    });
    setPrevFilters((prev) => {
      return { ...prev, global_search: initialValue.global_search };
    });
  }, [initialValue.global_search, setPrevFilters]);

  const handleColorChange = (color) => {
    setFilter((prevFilter) => {
      const { global_colors } = prevFilter;
      if (global_colors.includes(color)) {
        // If color is already selected, remove it
        return {
          ...prevFilter,
          global_colors: global_colors.filter(
            (selectedColor) => selectedColor !== color
          ),
        };
      } else {
        // If color is not selected, add it
        return {
          ...prevFilter,
          global_colors: [...global_colors, color],
        };
      }
    });
  };
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setLanguage(lng);
    dispatch(setlanguage(lng));
    localStorage.setItem("selectedLanguage", lng);
  };

  const handledelete = () => {
    setIsDeleteModalOpen(true);
  };

  const handleSubmitReview = () => {
    setIsTestimonialModalOpen(true);
  };
  const toggleMenu = () => {
    console.log("click");
    setMenuOpen(!menuOpen);
  };

  const colors = [
    "#000000",
    "#FFFFFF",
    "#C0C0C0",
    "#808080",
    "#800000",
    "#FF0000",
    "#800080",
    "#FF00FF",
    "#008000",
    "#00FF00",
    "#808000",
    "#FFFF00",
    "#000080",
    "#0000FF",
    "#008080",
    "#00FFFF",
  ];

  const handleLogOut = useCallback(() => {
    Swal.fire({
      title: t("areYouSure"),
      text: t("logoutText"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: t("logOutConfirm"),
    }).then((result) => {
      if (result.isConfirmed) {
        // Perform deletion logic here
        const myPromise = new Promise((resolve, reject) =>
          logout({}).then((response) => {
            console.log("response: ", typeof response, response);
            if (response.code == 200) {
              removeUserDataFromLocal();
              resolve(response);
              navigate("/");
            } else {
              reject(response);
            }
          })
        );
        showPromiseNotification(myPromise);
      }
    });
  }, [navigate, t]);

  const handleBrowseTemplate = useCallback(() => {
    navigate("/home");
  }, [navigate]);

  const handleBlanckTemplate = useCallback(() => {
    navigate("/add-template");
  }, [navigate]);

  useEffect(() => {
    if (
      // !window.location.pathname.endsWith("/home") &&
      (prevFilters?.global_search?.length ||
        prevFilters?.global_orientation?.length ||
        prevFilters?.global_colors?.length > 0)
    ) {
      // navigate("/home");
      navigate("/search-template");
    }
  }, [
    navigate,
    prevFilters?.global_colors?.length,
    prevFilters?.global_orientation?.length,
    prevFilters?.global_search?.length,
  ]);
  console.log(
    'window.location.pathname.endsWith("/home"): ',
    window.location.pathname.endsWith("/home"),
    prevFilters?.global_search?.length,
    prevFilters
  );
  useEffect(() => {
    if (isLoading > 0) {
      setUserDetails(getUserDataFromLocal());
    }
  }, [isLoading])
  useEffect(() => {
    if (submitFilter) {
      // setPrevFilters({...prevFilters})
      setPrevFilters((prev) => {
        return { ...prev, submitFilter: submitFilter };
      });
    }
  }, [setPrevFilters, submitFilter])


  console.log('prevFilters.submitFilter111 ', prevFilters.submitFilter && filter.global_orientation === 'portrait', prevFilters.submitFilter);
  console.log('prevFilters.submitFilter222 ', prevFilters.submitFilter && filter.global_orientation === 'landscape', prevFilters.submitFilter);
  console.log('prevFilters.submitFilter333 ', prevFilters.submitFilter && filter.global_orientation === 'square', prevFilters.submitFilter);
  console.log('prevFilters.submitFilter444 ', prevFilters.submitFilter && filter.global_orientation === 'rectangle', prevFilters.submitFilter);

  return (
    <div>
      <BecomeDesigner
        bocomeDesignerModalOpen={bocomeDesignerModalOpen}
        setBocomeDesignerModalOpen={setBocomeDesignerModalOpen}
      />
      {isDeleteModalOpen && (
        <DeleteAccont
          isDeleteModalOpen={isDeleteModalOpen}
          setIsDeleteModalOpen={setIsDeleteModalOpen}
        />
      )}

      {testiMonialModalOpen && (
        <RatingModal
          testiMonialModalOpen={testiMonialModalOpen}
          setIsTestimonialModalOpen={setIsTestimonialModalOpen}
        />
      )}

      <header className="header_inner_main fixed-top " style={{ background: "#fff", borderBottom: "1px solid rgba(221, 224, 227, 1)" }}>
        <nav className="navbar navbar-expand-lg ">
          <div className="container-fluid d-lg-flex d-none">
            <Link className="navbar-brand" to="/home" style={{ margin: '0 1%' }} onClick={() => { setPrevFilters({}); }}>
              <img
                alt=""
                src={
                  process.env.PUBLIC_URL +
                  // "/assets/images/Craetivo_logo_white.png"
                  "/assets/images/Logo_new.svg"
                }
              />
            </Link>

            <div className=" me-auto search_input position-relative dropdown ">
              <input
                className="form-control me-2"
                placeholder={t("searchPlaceholder")}
                aria-label="Search"
                value={filter?.global_search}
                onChange={(e) => {
                  if (e.target.value) {
                    setFilter((val) => {
                      return { ...val, global_search: e.target.value };
                    });
                  } else {
                    clearFilter();
                    navigate("/home")
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    applyFilter();
                  }
                }}
              />
              <button
                className="dropdown-toggle btn-search innerHeader"
                // type="submit"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style={{ color: 'black' }}
                onClick={() => {
                  if (!prevFilters.submitFilter || filter.global_orientation == "") {
                    setFilter({
                      ...filter,
                      global_orientation: "",
                    });
                  }
                }}
              >
                <img
                  alt=""
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/sidebar/filterNew.svg "
                  }
                  className="me-2"
                />
                {t("filterButton")}
                {/* (
                {((Array.isArray(filter.global_colors))
                  ? filter.global_colors.length
                  : 0) + (filter.submitFilter && filter.global_orientation ? 1 : 0)}
                ) */}
              </button>
              <div
                className="dropdown-menu filter_dropdown"
                aria-labelledby="dropdownMenuButton1"
              >
                {/* <div className="select-color">
                  <h6> {t("Color")}</h6>
                  <div className="color_selection">
                    <ul>
                      {colors.map((color) => {
                        return (
                          <li className="li-white">
                            <input
                              type="checkbox"
                              checked={filter.global_colors.includes(color)}
                              onChange={() => handleColorChange(color)}
                            />
                            <span
                              className=" color-maain"
                              style={{ background: color }}
                            />
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div> */}
                <div className="select-view-port">
                  <h6> {t("Orientation")}</h6>
                  {/* <ul className="view_prot_selection">
                    <li>
                      <input
                        type="radio"
                        name="portrait1"
                        value="portrait"
                        checked={(prevFilters.submitFilter && prevFilters.global_orientation == 'portrait')}
                        onChange={(e) => {
                          setFilter((val) => {
                            return {
                              ...val,
                              global_orientation: e.target.value,
                            };
                          });

                        }}
                      />
                      <label>{t("Portrait")}</label>
                    </li>
                    <li>
                      <input
                        type="radio"
                        name="landscape1"
                        value="landscape"
                        checked={(prevFilters.submitFilter && prevFilters.global_orientation == 'landscape')}
                        onChange={(e) => {
                          setFilter((val) => {
                            return {
                              ...val,
                              global_orientation: e.target.value,
                            };
                          });
                        }}
                      />
                      <label>{t("Landscape")}</label>
                    </li>
                    <li>
                      <input
                        type="radio"
                        name="square1"
                        value="square"
                        checked={(prevFilters.submitFilter && prevFilters.global_orientation == 'square')}
                        onChange={(e) => {
                          setFilter((val) => {
                            return {
                              ...val,
                              global_orientation: e.target.value,
                            };
                          });
                        }}
                      />
                      <label>{t("Square")}</label>
                    </li>
                    <li>
                      <input
                        type="radio"
                        name="rectangle1"
                        value="rectangle"
                        checked={(prevFilters.submitFilter && prevFilters.global_orientation == 'rectangle')}
                        onChange={(e) => {
                          setFilter((val) => {
                            return {
                              ...val,
                              global_orientation: e.target.value,
                            };
                          });
                        }}
                      />
                      <label>{t("Rectangle")}</label>
                    </li>
                  </ul> */}
                  <ul className="view_prot_selection">
                    <li>
                      <input
                        type="radio"
                        name="orientation"
                        value="portrait"
                        checked={(filter.global_orientation === 'portrait') ? true : false}
                        onChange={(e) => {
                          setFilter({
                            ...filter,
                            global_orientation: e.target.value,
                          });
                        }}
                      />
                      <label>{t('Portrait')}</label>
                    </li>
                    <li>
                      <input
                        type="radio"
                        name="orientation"
                        value="landscape"
                        checked={(filter.global_orientation === 'landscape') ? true : false}
                        onChange={(e) => {
                          setFilter({
                            ...filter,
                            global_orientation: e.target.value,
                          });
                        }}
                      />
                      <label>{t('Landscape')}</label>
                    </li>
                    <li>
                      <input
                        type="radio"
                        name="orientation"
                        value="square"
                        checked={(filter.global_orientation === 'square') ? true : false}
                        onChange={(e) => {
                          setFilter({
                            ...filter,
                            global_orientation: e.target.value,
                          });
                        }}
                      />
                      <label>{t('Square')}</label>
                    </li>
                    <li>
                      <input
                        type="radio"
                        name="orientation"
                        value="rectangle"
                        checked={(filter.global_orientation === 'rectangle') ? true : false}
                        onChange={(e) => {
                          setFilter({
                            ...filter,
                            global_orientation: e.target.value,
                          });
                        }}
                      />
                      <label>{t('Rectangle')}</label>
                    </li>
                  </ul>
                </div>
                <div className="d-flex justify-content-end apply-filter">
                  {prevFilters.submitFilter && filter.global_orientation != "" && (
                    < button
                      className="view_all"
                      onClick={() => {
                        handleClearFilters()
                      }}
                      style={{ marginRight: '2%' }}
                    >
                      {t('clearFilter')}
                    </button>
                  )}
                  <button
                    className="view_all"
                    onClick={() => {
                      applyFilter();
                    }}
                  >
                    {t('applyFilter')}
                  </button>
                </div>
              </div>
            </div>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ms-auto mb-lg-0">
                <li className="dropdown white_arrow lang_change">
                  <a
                    className="dropdown-toggle innerHeader"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{ color: "black" }}
                  >
                    <img
                      alt=""
                      src={`${process.env.PUBLIC_URL}${language === "EN"
                        ? "/assets/images/en.svg"
                        : language == "FR"
                          ? "/assets/images/FR.svg"
                          : "/assets/images/PR.svg"
                        }`}
                    />{" "}
                    {language}
                  </a>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li className="cursor-pointer">
                      <a
                        className="dropdown-item"
                        onClick={() => changeLanguage("EN")}
                        style={{ color: "black" }}

                      >
                        <img
                          src={process.env.PUBLIC_URL + "/assets/images/en.svg"} alt=""
                        />{" "}
                        {t("English")}
                      </a>
                    </li>
                    <li className="cursor-pointer">
                      <a
                        className="dropdown-item"
                        onClick={() => changeLanguage("FR")}
                        style={{ color: "black" }}

                      >
                        <img
                          src={process.env.PUBLIC_URL + "/assets/images/FR.svg"} alt=""
                        />{" "}
                        {t("French")}
                      </a>
                    </li>
                    <li className="cursor-pointer">
                      <a
                        className="dropdown-item"
                        onClick={() => changeLanguage("PT")}
                        style={{ color: "black" }}

                      >
                        <img
                          src={process.env.PUBLIC_URL + "/assets/images/PR.svg"} alt=""
                        />{" "}
                        {t("Portuguese")}
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="dropdown white_arrow account_setting">
                  <a
                    className="dropdown-toggle innerHeader"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{ color: "black" }}

                  >
                    <img alt="" src={userDetails?.profile_image} />{" "}
                    {userDetails?.full_name}
                  </a>
                  <ul
                    className="dropdown-menu myriad-pro-ul"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li className="account_bg" >
                      <a className="dropdown-item"
                        style={{ color: "black" }}
                      >
                        <img
                          alt=""
                          src={userDetails?.profile_image}
                          className="ineer_small_icon"
                        />
                        {userDetails?.full_name}
                        {!userDetails?.is_subscribed && (
                          <p className="subs_btn">{t("Free")}</p>
                          // <span className="subs_btn">{t("Free")}</span>
                        )}
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" onClick={() => { setPrevFilters({}); navigate(`/designer-profile/${userDetails?.id}`); }}>
                        <img
                          alt=""
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/account/edit.svg"
                          }
                        />
                        {t("viewProfile")}
                      </a>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/my-account" onClick={() => { setPrevFilters({}); }}>
                        <img
                          alt=""
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/account/settings.svg"
                          }
                        />
                        {t("accountSettings")}
                      </Link>
                    </li>
                    {/* <li>
                      <Link className="dropdown-item" to="/change-password" onClick={() => { setPrevFilters({}); }}>
                        <img
                          alt=""
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/account/password.svg"
                          }
                        />
                        {t("changePassword")}
                      </Link>
                    </li> */}
                    <li>
                      <Link className="dropdown-item" to="/subscription" onClick={() => { setPrevFilters({}); }}>
                        <img
                          alt=""
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/account/crown.svg"
                          }
                        />
                        {t("unlimitedDownloads")}
                      </Link>
                    </li>
                    {/* <li className="cursor-pointer">
                      <a
                        className="dropdown-item"
                        onClick={() => { handledelete(); setPrevFilters({}) }}
                      >
                        <img
                          alt=""
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/account/delete.svg"
                          }
                        />
                        {t("deleteAccount")}
                      </a>
                    </li> */}
                    <li className="cursor-pointer">
                      <a
                        className="dropdown-item"
                        onClick={() => handleSubmitReview()}
                      >
                        <img
                          alt=""
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/star-fill.svg"
                          }
                        />
                        {t("addYourReview")}
                      </a>
                    </li>
                    <li className="cursor-pointer">
                      <Link
                        to={"/contact-us"}
                        className="dropdown-item"

                      >
                        <img
                          alt=""
                          // style={{ height: "20px" }}
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/Help.svg"
                          }
                        // className="ImageBlackFilter"
                        />
                        {t("help")}
                      </Link>
                    </li>
                    <li
                      onClick={() => {
                        handleLogOut();
                        setPrevFilters({})
                      }}
                    >
                      <a className="dropdown-item cursor-pointer">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/sign-out.svg"
                          }
                        />
                        {t("Logout")}
                      </a>
                    </li>
                    {/* <li className="text-center cursor-pointer">
                      {designerType === "designer" ? (
                        <a className="becomre_deigner">
                          {t("alreadyDesigner")}
                        </a>
                      ) : (
                        <Link
                          to={"/become-designer"}
                          className="becomre_deigner"
                        // onClick={() => {
                        //   setBocomeDesignerModalOpen(true);
                        // }}
                        >
                          {t("becomeDesigner")}
                        </Link>
                      )}
                    </li> */}
                  </ul>
                </li>
                <li className="form_input">
                  <div>
                    <button
                      className="dropdown-toggle btn btn-create"
                      type="submit"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {t("authButton")}
                    </button>
                    <div
                      className="dropdown-menu filter_dropdown"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <div className="create_list_menu">
                        <a href>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/browse_template.svg"
                            }
                          />
                          <div
                            className="list_line cursor-pointer"
                            onClick={() => {
                              handleBrowseTemplate();
                            }}
                          >
                            <h6>{t("browseTemplates")}</h6>
                            <label>{t("flyerVideoedPost")} </label>
                          </div>
                        </a>
                        <a href>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/blank_design.svg"
                            }
                          />
                          <div
                            className="list_line cursor-pointer"
                            onClick={() => {
                              handleBlanckTemplate();
                            }}
                          >
                            <h6>{t("blankDesign")}</h6>
                            <label>{t("startWithFreshCanvas")}</label>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="container-fluid d-flex d-lg-none mobile_menu">
            <div className="main_top_header">
              <Link className="navbar-brand" to="/home" style={{ margin: '0 1%' }} onClick={() => { setPrevFilters({}); }}>
                <img
                  alt=""
                  src={
                    process.env.PUBLIC_URL +
                    // "/assets/images/Craetivo_logo_white.png"
                    "/assets/images/Logo_new.svg"
                  }
                />
              </Link>
              <div className=" me-auto search_input position-relative dropdown first_header">
                <input
                  type="search"
                  className="form-control me-2"
                  placeholder={t("searchPlaceholder")}
                  aria-label="Search"
                  value={filter?.global_search}
                  onChange={(e) => {
                    if (e.target.value) {
                      setFilter((val) => {
                        return { ...val, global_search: e.target.value };
                      });
                    } else {
                      clearFilter();
                      navigate("/home")
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      applyFilter();
                    }
                  }}
                />
                {/* <input
                  className="form-control me-2"
                  placeholder={t("searchPlaceholder")}
                  aria-label="Search"
                  value={filter?.global_search}
                  onChange={(e) => {
                    if (e.target.value) {
                      setFilter((val) => {
                        return { ...val, global_search: e.target.value };
                      });
                    } else {
                      clearFilter();
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      applyFilter();
                    }
                  }}
                  inputmode="search"
                  style={{ fontSize: "16px" }}
                  autocapitalize="none"
                  autocorrect="off"
                /> */}
                <button
                  className="dropdown-toggle btn-search innerHeader"
                  // type="submit"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{ color: 'black' }}
                  onClick={() => {
                    if (!prevFilters.submitFilter || filter.global_orientation == "") {
                      setFilter({
                        ...filter,
                        global_orientation: "",
                      });
                    }
                  }}
                >
                  <img
                    alt=""
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/sidebar/filterNew.svg "
                    }
                    className="me-2"
                  />
                  {t("filterButton")}
                  {/* (
                  {((Array.isArray(filter.global_colors))
                    ? filter.global_colors.length
                    : 0) + (filter.submitFilter && filter.global_orientation ? 1 : 0)}
                  ) */}
                </button>
                <div
                  className="dropdown-menu filter_dropdown"
                  aria-labelledby="dropdownMenuButton1"
                >
                  {/* <div className="select-color">
                    <h6> {t("Color")}</h6>
                    <div className="color_selection">
                      <ul>
                        {colors.map((color) => {
                          return (
                            <li className="li-white">
                              <input
                                type="checkbox"
                                checked={filter.global_colors.includes(color)}
                                onChange={() => handleColorChange(color)}
                              />
                              <span
                                className=" color-maain"
                                style={{ background: color }}
                              />
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div> */}
                  <div className="select-view-port">
                    <h6> {t("Orientation")}</h6>
                    {/* <ul className="view_prot_selection">
                      <li>
                        <input
                          type="radio"
                          name="portrait2"
                          value="portrait"
                          checked={(filter.submitFilter && filter.global_orientation === 'portrait')}
                          onChange={(e) => {
                            setFilter((val) => {
                              return {
                                ...val,
                                global_orientation: e.target.value,
                              };
                            });
                          }}
                        />
                        <label>{t("Portrait")}</label>
                      </li>
                      <li>
                        <input
                          type="radio"
                          name="landscape2"
                          value="landscape"
                          checked={(filter.submitFilter && filter.global_orientation == 'landscape')}
                          onChange={(e) => {
                            setFilter((val) => {
                              return {
                                ...val,
                                global_orientation: e.target.value,
                              };
                            });
                          }}
                        />
                        <label>{t("Landscape")}</label>
                      </li>
                      <li>
                        <input
                          type="radio"
                          name="square2"
                          value="square"
                          checked={(filter.submitFilter && filter.global_orientation == 'square')}
                          onChange={(e) => {
                            setFilter((val) => {
                              return {
                                ...val,
                                global_orientation: e.target.value,
                              };
                            });
                          }}
                        />
                        <label>{t("Square")}</label>
                      </li>
                      <li>
                        <input
                          type="radio"
                          name="rectangle2"
                          value="rectangle"
                          checked={(filter.submitFilter && filter.global_orientation == 'rectangle')}
                          onChange={(e) => {
                            setFilter((val) => {
                              return {
                                ...val,
                                global_orientation: e.target.value,
                              };
                            });
                          }}
                        />
                        <label>{t("Rectangle")}</label>
                      </li>
                    </ul> */}
                    <ul className="view_prot_selection">
                      <li>
                        <input
                          type="radio"
                          name="orientation1"
                          value="portrait"
                          checked={(filter.global_orientation === 'portrait') ? true : false}
                          onChange={(e) => {
                            setFilter({
                              ...filter,
                              global_orientation: e.target.value,
                            });
                          }}
                        />
                        <label>{t('Portrait')}</label>
                      </li>
                      <li>
                        <input
                          type="radio"
                          name="orientation1"
                          value="landscape"
                          checked={(filter.global_orientation === 'landscape') ? true : false}
                          onChange={(e) => {
                            setFilter({
                              ...filter,
                              global_orientation: e.target.value,
                            });
                          }}
                        />
                        <label>{t('Landscape')}</label>
                      </li>
                      <li>
                        <input
                          type="radio"
                          name="orientation1"
                          value="square"
                          checked={(filter.global_orientation === 'square') ? true : false}
                          onChange={(e) => {
                            setFilter({
                              ...filter,
                              global_orientation: e.target.value,
                            });
                          }}
                        />
                        <label>{t('Square')}</label>
                      </li>
                      <li>
                        <input
                          type="radio"
                          name="orientation1"
                          value="rectangle"
                          checked={(filter.global_orientation === 'rectangle') ? true : false}
                          onChange={(e) => {
                            setFilter({
                              ...filter,
                              global_orientation: e.target.value,
                            });
                          }}
                        />
                        <label>{t('Rectangle')}</label>
                      </li>
                    </ul>
                  </div>
                  <div className="d-flex justify-content-end apply-filter">
                    {prevFilters.submitFilter && filter.global_orientation != "" && (
                      <button
                        className="view_all"
                        onClick={() => {
                          handleClearFilters()
                        }}
                        style={{ marginRight: '2%' }}
                      >
                        {t('clearFilter')}
                      </button>
                    )}
                    <button
                      className="view_all"
                      onClick={() => {
                        applyFilter();
                      }}
                    >
                      {t('applyFilter')}
                    </button>
                  </div>
                </div>
              </div>
              <div className="right_option">
                <ul className="normal_list ms-auto mb-lg-0">
                  <li className="dropdown white_arrow lang_change">
                    {/* <a
                      className="dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        alt=""
                        src={process.env.PUBLIC_URL + "/assets/images/en.svg"}
                      />{" "}
                      <span>EN</span>
                    </a> */}
                    <a
                      className="dropdown-toggle innerHeader"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{ color: "black" }}
                    >
                      <img
                        alt=""
                        src={`${process.env.PUBLIC_URL}${language === "EN"
                          ? "/assets/images/en.svg"
                          : language == "FR"
                            ? "/assets/images/FR.svg"
                            : "/assets/images/PR.svg"
                          }`}
                      />{" "}
                      <span>{language}</span>
                    </a>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={() => changeLanguage("EN")}
                        >
                          <img
                            alt=""
                            src={
                              process.env.PUBLIC_URL + "/assets/images/en.svg"
                            }
                          />{" "}
                          {t("English")}
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={() => changeLanguage("FR")}
                        >
                          <img
                            alt=""
                            src={
                              process.env.PUBLIC_URL + "/assets/images/FR.svg"
                            }
                          />{" "}
                          {t("French")}
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={() => changeLanguage("PT")}
                        >
                          <img
                            alt=""
                            src={
                              process.env.PUBLIC_URL + "/assets/images/PR.svg"
                            }
                          />{" "}
                          {t("Portuguese")}
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="dropdown white_arrow account_setting">
                    <a
                      className="dropdown-toggle innerHeader"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{ color: "black" }}
                    >
                      <img alt="" src={userDetails?.profile_image} />{" "}
                      <span>{userDetails?.full_name}</span>
                    </a>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li className="account_bg">
                        <a className="dropdown-item" href="#"
                          style={{ color: "black" }}
                        >
                          <img
                            alt=""
                            src={userDetails?.profile_image}
                            className="ineer_small_icon"
                          />
                          {userDetails?.full_name}
                          {!userDetails?.is_subscribed && (
                            <p className="subs_btn">{t("Free")}</p>
                            // <span className="subs_btn">{t("Free")}</span>
                          )}
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" onClick={() => { setPrevFilters({}); navigate(`/designer-profile/${userDetails?.id}`); }}>
                          <img
                            alt=""
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/account/edit.svg"
                            }
                          />
                          {t("viewProfile")}
                        </a>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/my-account" onClick={() => { setPrevFilters({}); }}>
                          <img
                            alt=""
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/account/settings.svg"
                            }
                          />
                          {t("accountSettings")}
                        </Link>
                      </li>
                      {/* <li>
                        <Link className="dropdown-item" to="/change-password" onClick={() => { setPrevFilters({}); }}>
                          <img
                            alt=""
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/account/password.svg"
                            }
                          />
                          {t("changePassword")}
                        </Link>
                      </li> */}
                      <li>
                        <Link className="dropdown-item" to="/subscription" onClick={() => { setPrevFilters({}); }}>
                          <img
                            alt=""
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/account/crown.svg"
                            }
                          />
                          {t("unlimitedDownloads")}
                        </Link>
                      </li>
                      {/* <li
                        className="cursor-pointer"
                        onClick={() => { handledelete(); setPrevFilters({}) }}
                      >
                        <a className="dropdown-item">
                          <img
                            alt=""
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/account/delete.svg"
                            }
                          />
                          {t("deleteAccount")}
                        </a>
                      </li> */}
                      <li
                        className="cursor-pointer"
                        onClick={() => handleSubmitReview()}
                      >
                        <a className="dropdown-item">
                          <img
                            alt=""
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/star-fill.svg"
                            }
                          />
                          {t("addYourReview")}
                        </a>
                      </li>
                      <li className="cursor-pointer">
                        <Link
                          to={"/contact-us"}
                          className="dropdown-item"
                          onClick={() => { setPrevFilters({}); }}
                        >
                          <img
                            alt=""
                            // style={{ height: "20px" }}
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/Help.svg"
                            }
                          // className="ImageBlackFilter"
                          />
                          {t("help")}
                        </Link>
                      </li>
                      <li>
                        <a
                          className="dropdown-item cursor-pointer"
                          onClick={() => {
                            handleLogOut();
                            setPrevFilters({})
                          }}
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/sign-out.svg"
                            }
                          />
                          {t("Logout")}
                        </a>
                      </li>
                      {/* <li className="text-center">
                        {designerType === "designer" ? (
                          <p className="becomre_deigner">
                            {t("alreadyDesigner")}
                          </p>
                        ) : (
                          <Link
                            to={"/become-designer"}
                            className="becomre_deigner"
                          // onClick={() => {
                          //   setBocomeDesignerModalOpen(true);
                          // }}
                          >
                            {t("becomeDesigner")}
                          </Link>
                        )}
                      </li> */}
                    </ul>
                  </li>
                </ul>
                <span
                  className={`toggle-button ${menuOpen ? "button-open" : ""}`}
                  onClick={toggleMenu}
                >
                  <div className="menu-bar menu-bar-top" style={{ border: "1px solid black" }} />
                  <div className="menu-bar menu-bar-middle" style={{ backgroundColor: "black" }} />
                  <div className="menu-bar menu-bar-bottom" style={{ border: "1px solid black" }} />
                </span>
              </div>
            </div>
            <div className="main_bottom_header">
              <div className=" me-auto search_input position-relative dropdown ">
                <input
                  className="form-control me-2"
                  placeholder={t("searchPlaceholder")}
                  aria-label="Search"
                  value={filter?.global_search}
                  onChange={(e) => {
                    if (e.target.value) {
                      setFilter((val) => {
                        return { ...val, global_search: e.target.value };
                      });
                    } else {
                      clearFilter();
                      navigate("/home")
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      applyFilter();
                    }
                  }}
                />
                <button
                  className="dropdown-toggle btn-search innerHeader"
                  // type="submit"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{ color: 'black' }}
                  onClick={() => {
                    if (!prevFilters.submitFilter || filter.global_orientation == "") {
                      setFilter({
                        ...filter,
                        global_orientation: "",
                      });
                    }
                  }}
                >
                  <img
                    alt=""
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/sidebar/filterNew.svg"
                    }
                    className="me-2"
                  />
                  {t("filterButton")}
                  {/* (
                  {((Array.isArray(filter.global_colors))
                    ? filter.global_colors.length
                    : 0) + (filter.submitFilter && filter.global_orientation ? 1 : 0)}
                  ) */}
                </button>
                <div
                  className="dropdown-menu filter_dropdown"
                  aria-labelledby="dropdownMenuButton1"
                >
                  {/* <div className="select-color">
                    <h6> {t("Color")}</h6>
                    <div className="color_selection">
                      <ul>
                        {colors.map((color) => {
                          return (
                            <li className="li-white">
                              <input
                                type="checkbox"
                                checked={filter.global_colors.includes(color)}
                                onChange={() => handleColorChange(color)}
                              />
                              <span
                                className=" color-maain"
                                style={{ background: color }}
                              />
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div> */}
                  <div className="select-view-port">
                    <h6> {t("Orientation")}</h6>
                    {/* <ul className="view_prot_selection">
                      <li>
                        <input
                          type="radio"
                          name="portrait3"
                          value="portrait"
                          checked={true}
                          onChange={(e) => {
                            setFilter((val) => {
                              return {
                                ...val,
                                global_orientation: e.target.value,
                              };
                            });
                          }}
                        />
                        <label>{t("Portrait")}</label>
                      </li>
                      <li>
                        <input
                          type="radio"
                          name="landscape3"
                          value="landscape"
                          checked={(filter.submitFilter && filter.global_orientation == 'landscape')}
                          onChange={(e) => {
                            setFilter((val) => {
                              return {
                                ...val,
                                global_orientation: e.target.value,
                              };
                            });
                          }}
                        />
                        <label>{t("Landscape")}</label>
                      </li>
                      <li>
                        <input
                          type="radio"
                          name="square3"
                          value="square"
                          checked={(filter.submitFilter && filter.global_orientation == 'square')}
                          onChange={(e) => {
                            setFilter((val) => {
                              return {
                                ...val,
                                global_orientation: e.target.value,
                              };
                            });
                          }}
                        />
                        <label>{t("Square")}</label>
                      </li>
                      <li>
                        <input
                          type="radio"
                          name="rectangle3"
                          value="rectangle"
                          checked={(filter.submitFilter && filter.global_orientation == 'rectangle')}
                          onChange={(e) => {
                            setFilter((val) => {
                              return {
                                ...val,
                                global_orientation: e.target.value,
                              };
                            });
                          }}
                        />
                        <label>{t("Rectangle")}</label>
                      </li>
                    </ul> */}
                    <ul className="view_prot_selection">
                      <li>
                        <input
                          type="radio"
                          name="orientation2"
                          value="portrait"
                          checked={(filter.global_orientation === 'portrait') ? true : false}
                          onChange={(e) => {
                            setFilter({
                              ...filter,
                              global_orientation: e.target.value,
                            });
                          }}
                        />
                        <label>{t('Portrait')}</label>
                      </li>
                      <li>
                        <input
                          type="radio"
                          name="orientation2"
                          value="landscape"
                          checked={(filter.global_orientation === 'landscape') ? true : false}
                          onChange={(e) => {
                            setFilter({
                              ...filter,
                              global_orientation: e.target.value,
                            });
                          }}
                        />
                        <label>{t('Landscape')}</label>
                      </li>
                      <li>
                        <input
                          type="radio"
                          name="orientation2"
                          value="square"
                          checked={(filter.global_orientation === 'square') ? true : false}
                          onChange={(e) => {
                            setFilter({
                              ...filter,
                              global_orientation: e.target.value,
                            });
                          }}
                        />
                        <label>{t('Square')}</label>
                      </li>
                      <li>
                        <input
                          type="radio"
                          name="orientation2"
                          value="rectangle"
                          checked={(filter.global_orientation === 'rectangle') ? true : false}
                          onChange={(e) => {
                            setFilter({
                              ...filter,
                              global_orientation: e.target.value,
                            });
                          }}
                        />
                        <label>{t('Rectangle')}</label>
                      </li>
                    </ul>
                  </div>
                  <div className="d-flex justify-content-end apply-filter">
                    {prevFilters.submitFilter && filter.global_orientation != "" && (
                      <button
                        className="view_all"
                        onClick={() => {
                          handleClearFilters()
                        }}
                        style={{ marginRight: '2%' }}
                      >
                        {t('clearFilter')}
                      </button>
                    )}
                    <button
                      className="view_all"
                      onClick={() => {
                        applyFilter();
                      }}
                    >
                      {t('applyFilter')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`menu-wrap ${menuOpen ? "menu-show" : ""}`}
              id="menu-1"
            >
              <div className="menu-sidebar myriad-pro-ul">
                <ul className="menu">
                  <li onClick={() => { toggleMenu(); setPrevFilters({}); }}>
                    <Link to="/home" className="data-link-1">
                      {t("home")}
                    </Link>
                  </li>
                  {getUserDataFromLocal()?.type === "designer" && (
                    <li onClick={() => { toggleMenu(); setPrevFilters({}); }}>
                      <Link to="/my-earning" className="data-link-1">
                        {t("myEarnings")}
                      </Link>
                    </li>
                  )}
                  <li onClick={() => { toggleMenu(); setPrevFilters({}); }}>
                    <Link to="/my-projects" className="data-link-1">
                      {t("myProjects")}
                    </Link>
                  </li>
                  <li onClick={() => { toggleMenu(); setPrevFilters({}); }}>
                    <Link to="/invited-project" className="data-link-1">
                      {t("teamProjects")}
                    </Link>
                  </li>
                  {getUserDataFromLocal()?.is_subscribed == 1 &&
                    getUserDataFromLocal()?.subscribed_type === "enterprise" ? (
                    <li onClick={() => { toggleMenu(); setPrevFilters({}); }}>
                      <Link to="/my-team" className="data-link-1">
                        {t("myTeam")}
                      </Link>
                    </li>
                  ) : (
                    <li onClick={() => { setIsSubscriptionModal(true); toggleMenu(); setPrevFilters({}); }}>
                      <Link className="data-link-1">
                        {t("myTeam")}
                        <img className="ms-2 headerIconTwo" src={process.env.PUBLIC_URL + "/assets/images/icon/premium-quality-color.svg"} alt="headerIcon" />
                      </Link>
                    </li>
                  )}
                </ul>
                <div className="text-center auth-button mt-4 form_input">
                  {/* <button
                    onClick={() => {
                      handleLogOut();
                    }}
                    type="button"
                    className="btn me-2 btn-login"
                  >
                    {t("Logout")}
                  </button> */}
                  <button
                    className="dropdown-toggle btn btn-create"
                    type="submit"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {t("authButton")}
                  </button>
                  {/* <div></div> */}

                  <div
                    className="dropdown-menu filter_dropdown"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <div className="create_list_menu">
                      <a onClick={() => { toggleMenu(); setPrevFilters({}); }}>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/browse_template.svg"
                          }
                        />
                        <div
                          className="list_line cursor-pointer"
                          onClick={() => {
                            handleBrowseTemplate();
                          }}
                        >
                          <h6>{t("browseTemplates")}</h6>
                          <label>{t("flyerVideoedPost")} </label>
                        </div>
                      </a>
                      <a href>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/blank_design.svg"
                          }
                        />
                        <div
                          className="list_line cursor-pointer"
                          onClick={() => {
                            handleBlanckTemplate();
                          }}
                        >
                          <h6>{t("blankDesign")}</h6>
                          <label>{t("startWithFreshCanvas")}</label>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
      {isSubscriptionModal && (
        <SubscriptionModal isSubscriptionModal={isSubscriptionModal} setIsSubscriptionModal={setIsSubscriptionModal} />
      )}
    </div >
  );
};

export default Innerheader;
