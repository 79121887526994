// AuthService.js

// Function to save user data to local storage
export const saveUserDataToLocal = (userData) => {
  localStorage.setItem("userData", btoa(JSON.stringify(userData)));
};

// Function to get user data from local storage
export const getUserDataFromLocal = () => {
  const userDataString = localStorage.getItem("userData");
  return userDataString ? JSON.parse(atob(userDataString)) : null;
};

// Function to remove user data from local storage
export const removeUserDataFromLocal = () => {
  localStorage.removeItem("userData");
};

// Function to check if the user is authenticated
export const isAuthenticated = () => {
  const userData = getUserDataFromLocal();
  return userData && userData.token;
};

// Function to get the user token
export const getToken = () => {
  const userData = getUserDataFromLocal();
  console.log("userData: ", userData);
  return userData ? userData.token : null;
};
