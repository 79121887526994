import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Innerheader from "./Innerheader";
import Header from "./Header";
import { getToken } from "../Common/LocalStorageService";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { showPromiseNotification } from "../Common/notification";
import { becomeDesigner } from "../api/apiHandler";
import checkValidation from "../validation/ValidationSchema";

// import ShareModal from "../../components/ShareModal";

const BecomeDesigner = () => {
    const { t } = useTranslation();
    const [isLogin, setIsLogin] = useState(false);
    const schema = checkValidation(["PortfolioLink", "Description"]);
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = (data) => {
        const payload = {
            about: data?.Description,
            portfolio_link: data?.PortfolioLink,
        };

        const myPromise = new Promise((resolve, reject) =>
            becomeDesigner(payload).then((response) => {
                if (response.code === 200) {
                    reset();
                    resolve(response);
                } else {
                    reject(response);
                }
            })
        );
        showPromiseNotification(myPromise);
    };
    return (
        <main>
            {getToken() ? (
                <Innerheader />
            ) : (
                <Header openLogin={isLogin} setOpenLogin={setIsLogin} />
            )}
            <section className="become_designer_1">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-5 col-sm-6 col-12 me-auto">
                            <div className="right_side_section">
                                <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/bd1.png"
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-md-5 col-sm-6 col-12 me-auto">
                            <h1>{t("becomeaDesigner.")}</h1>
                            <label>{t("joinCraetivo")}</label>
                            <p>{t("craetivoTemplatesMsg1")}</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="become_designer_2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-12 mb-5 text-center">
                            <h2 className="fw-bold"> {t("howItWorks")}</h2>
                        </div>
                        <div className="col-md-4 col-sm-4 col-12 mb-sm-0 mb-3">
                            <div className="how_it_works_option">
                                <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/bd4.png"
                                    }
                                />
                                <h2>{t("flexibleScheduling")}</h2>
                                <p>{t("goodbyeToDeadlinesMsg")}</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-4 col-12 mb-sm-0 mb-3">
                            <div className="how_it_works_option">
                                <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/bd5.png"
                                    }
                                />
                                <h2>{t("earnWhileSleep")}</h2>
                                <p>{t("earnWhileSleepMsg")}</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-4 col-12 mb-sm-0 mb-3">
                            <div className="how_it_works_option">
                                <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/bd3.png"
                                    }
                                />
                                <h2>{t("royalty50")}</h2>
                                <p>{t("royalty50Msg")}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="become_designer_3">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-5 col-sm-6 col-12 me-auto">
                            <div className="right_side_section">
                                <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/bd2.png"
                                    }
                                />
                                {/* <img src="assets/images/become_3.png" className="mix_blend" /> */}
                            </div>
                        </div>
                        <div className="col-md-5 col-sm-6 col-12 me-auto">
                            <h3>{t("applyToBecomeDesigner")}</h3>
                            <div className="become_form">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-group ">
                                        <label>{t("shareLinkPortfolio")}</label>
                                        <small>{t("showcasingYourDesigns")}</small>

                                        <input type="text" placeholder={t("portfolioLink")} defaultValue={""} {...register("PortfolioLink")} />
                                        {errors.PortfolioLink && (
                                            <p
                                                role="alert"
                                                className="error-text m-1"
                                                style={{ color: "red" }}
                                            >
                                                {errors.PortfolioLink.message}
                                            </p>
                                        )}
                                    </div>
                                    <div className="form-group">
                                        <label>{t("tellUsAboutYourself")}</label>
                                        <small>{t("becomingCraetivoDesignerMsg")}</small>
                                        <textarea defaultValue={""} placeholder={t("aboutMe")} {...register("Description")} />
                                        {errors.Description && (
                                            <p
                                                role="alert"
                                                className="error-text m-1"
                                                style={{ color: "red" }}
                                            >
                                                {errors.Description.message}
                                            </p>
                                        )}
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-login">{t("apply")}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default BecomeDesigner;
