import React, { useCallback, useEffect, useState } from "react";
import { InputGroup } from "@blueprintjs/core";
import { FileInput } from "@blueprintjs/core";
import styled from "polotno/utils/styled";
import { ImagesGrid } from "polotno/side-panel";
import { t } from "polotno/utils/l10n";
import { addUploadDocumentPolotnoApi, getStickers, getUploadDocumentPolotnoApi, removeUploadDocumentPolotnoApi } from "../../../api/apiHandler";
import { getImageSize, getCrop } from "polotno/utils/image";
import { observer } from 'mobx-react-lite';
import { Button } from '@blueprintjs/core';
import uploadToS3, { generateRandomFileName } from "../../../Common/s3AwsService";
import { useTranslation } from "react-i18next";
import UploadDocumentModal from "./uploadDocumentModal";
import Swal from "sweetalert2";
import i18n from "../../../i18n";
import { selectVideo } from 'polotno/side-panel/select-video';
async function imageUrlToBase64(imageUrl) {
    try {
        const response = await fetch(imageUrl);
        if (!response.ok) {
            throw new Error("Network response was not ok " + response.statusText);
        }

        const contentType = response.headers.get("content-type");
        let base64;

        if (contentType.includes("image/svg+xml")) {
            const text = await response.text();
            base64 = btoa(text);
        } else {
            const arrayBuffer = await response.arrayBuffer();
            base64 = arrayBufferToBase64(arrayBuffer);
        }

        const base64String = `data:${contentType};base64,${base64}`;
        console.log(base64String);
        return base64String;
    } catch (error) {
        console.error("There has been a problem with your fetch operation:", error);
    }
}

// Helper function to convert ArrayBuffer to base64
function arrayBufferToBase64(buffer) {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
}

const NounContainer = styled("div")`
  height: 100%;
  overflow: hidden;
`;

export const NounprojectPanel = ({ store, query, isUpload, setIsUpload, addData, t, page, setPage, type }) => {
    console.log('type123: ', type);
    console.log('query: ', query);
    console.log('isUpload123: ', isUpload);
    const [data, setData] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        // if (page == 1) {
        setData([])
        setIsLoading(true);
        getUploadDocumentPolotnoApi({ search: query, type: type }).then((response) => {
            console.log('response1111: ', response);
            if (response.code == 200) {
                setData(response.data.data);
                setHasMore(true);
                setIsLoading(false);
                // setPage(page + 1);
            } else {
                // if (page == 1) {
                // }
                setData([])
                setHasMore(false);
                setIsLoading(false);
            }
        });
        // }
    }, [query, type]);

    // const loadMore = useCallback(() => {
    //     setIsLoading(true);
    //     getUploadDocumentPolotnoApi({ page: page + 1, limit: 3, search: query }).then((response) => {
    //         if (response.code == 200) {
    //             setData((prev) => [...prev, ...response.data.data]);
    //             // const newData = Array.isArray(response.data.data) ? response.data.data : [];
    //             // let mergedata = [...data, ...newData]
    //             // console.log('responseload 478: ', newData, mergedata);
    //             // setData(mergedata)
    //             // setData((prevData) => {
    //             //     // Ensure the response data is an array before spreading it
    //             //     return [...prevData, ...newData];
    //             // });
    //             setHasMore(true);
    //             setIsLoading(false);
    //             // setPage(page + 1);
    //         } else {
    //             setHasMore(false);
    //             setIsLoading(false);
    //         }
    //         setPage(page + 1);
    //     });
    // }, [page, query, setPage]);
    const HandleRemoveDocument = useCallback((id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-outline-success',
                cancelButton: 'btn btn-outline-danger mx-2',
            },
            buttonsStyling: false,
        })
        swalWithBootstrapButtons
            .fire({
                title: i18n.t("areYouSure"),
                text: i18n.t("deleteImageMsg"),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: i18n.t("deleteIt"),
                cancelButtonText: i18n.t("noCancel"),
                reverseButtons: true,
            })
            .then(async (result) => {
                if (result.isConfirmed) {
                    removeUploadDocumentPolotnoApi({ id }).then((response) => {
                        if (response.code == 200) {
                            const filteredData = data.filter((item) => item.id !== id)
                            setData(filteredData)
                        }
                    });
                    swalWithBootstrapButtons.fire(
                        i18n.t("deleted"),
                        i18n.t("fileDeleteSuccess"),
                        i18n.t("success")
                    );
                }
            })
    }, [data])
    useEffect(() => {
        if (isUpload) {
            setData([addData, ...data])
            setIsUpload(false)
        }
    }, [addData, data, isUpload, setIsUpload])
    console.log('data: ', data);
    return (
        <NounContainer>
            <ImagesGrid
                shadowEnabled={false}
                images={data}
                getPreview={(item) => item?.low_image || item?.image}
                // getPreview={(item) => ({ image: item?.image, name: item?.name })}
                getCredit={(item) => (
                    <div className="d-flex justify-content-between">
                        <p>{item?.name}</p>
                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/delete.svg"} alt="" style={{ height: '17px', cursor: 'pointer' }} onClick={(e) => {
                            e.stopPropagation();
                            HandleRemoveDocument(item?.id)
                        }}></img>
                    </div>
                )}
                isLoading={isLoading}
                itemHeight={"80px"}
                onSelect={async (image, pos, element) => {
                    const typeOf = image.type;
                    console.log('typeOf: ', typeOf);

                    if (typeOf == 'video') {
                        const src = image.video
                        console.log('src: ', src);

                        selectVideo({
                            src,
                            store,
                            droppedPos: pos,
                            targetElement: element,
                        });
                    } else {
                        // let url = await imageUrlToBase64(image.image);
                        let url = image.image;

                        let { width, height } = await getImageSize(url);
                        const isSVG = url.indexOf("svg+xml") >= 0 || url.indexOf(".svg") >= 0;
                        console.log("isSVG: ", isSVG);

                        const type = isSVG ? "svg" : "image";

                        if (
                            element &&
                            element?.type === "svg" &&
                            !element.locked &&
                            type === "image"
                        ) {
                            element.set({ maskSrc: url });
                            return;
                        }

                        // if (
                        //     element &&
                        //     element.type === "image" &&
                        //     !element.locked &&
                        //     type === "svg"
                        // ) {
                        //     element.set({ clipSrc: url });
                        //     return;
                        // }
                        if (
                            element &&
                            element.type === 'image' &&
                            element.contentEditable &&
                            type == 'image'
                        ) {
                            const crop = getCrop(element, {
                                width,
                                height,
                            });
                            element.set({ src: url, ...crop });
                            return;
                        }

                        const scale = Math.min(store.width / width, store.height / height, 1);
                        width = width * scale;
                        height = height * scale;

                        const x = (pos?.x || store.width / 2) - width / 2;
                        const y = (pos?.y || store.height / 2) - height / 2;

                        store.activePage?.addElement({
                            type,
                            src: url,
                            x,
                            y,
                            width,
                            height,
                        });
                    }
                }
                }
                rowsNumber={3}
                // loadMore={hasMore && loadMore}
                renderItem={(item) => (
                    <div style={{ textAlign: 'center' }}>
                        <img
                            src={item.image}
                            alt={item.name}
                            style={{ height: '80px' }}
                        />
                        <div>{item.name}</div>
                    </div>
                )}
            />
        </NounContainer>
    );
};

export const UploadPanel = ({ store }) => {
    const requestTimeout = React.useRef();
    const { t, i18n } = useTranslation();
    const [query, setQuery] = React.useState("");
    // const [images, setImages] = React.useState([]);
    const [isUploading, setUploading] = React.useState(false);
    const [delayedQuery, setDelayedQuery] = React.useState(query);
    const [isUpload, setIsUpload] = React.useState(false);
    const [type, setType] = React.useState('image');
    const [isModal, setIsModal] = React.useState(false);
    const [addData, setAddData] = React.useState();
    const [page, setPage] = useState(1);

    console.log('addData: ', addData);

    const handleFileInput = async (e) => {
        setIsUpload(false)
        const { target } = e;
        let upload_image = [];
        let notificationImageData = Array.from(e.target.files)
        if (notificationImageData && notificationImageData.length > 0) {
            console.log('notificationImageData: ', notificationImageData);
            const uploadPromises = await Promise.allSettled(notificationImageData.map(async (item, index) => {
                try {
                    let response = await uploadToS3(
                        item,
                        `${generateRandomFileName()}.${item.type.split("/")[1]}`,
                        "user"
                    ).then((response) => {
                        upload_image[index] = response.key;
                    });
                    // const response = await uploadToS3(item, 'templify/uploadPolotno');
                    // console.log('response1111: ', response);
                    // if (response && response.key) {
                    //     if (response.key.length) {
                    //         upload_image[index] = response.key;
                    //     }
                    // } else {
                    //     console.error('Upload failed or response.key is undefined for item:', item);
                    // }
                    return response; // return response to capture in uploadPromises
                } catch (error) {
                    console.error('Error uploading item:', item, error);
                    return null; // return null for failed uploads
                }
            }));
            console.log('uploadPromises: ', uploadPromises);
        }
        addUploadDocumentPolotnoApi({ image: upload_image }).then((response) => {
            if (response.code === 200) {
                setAddData(response.data)
                setIsUpload(true)
                // resolve(response)
                // navigate('/notification')
            } else {
                // reject(response)
            }
        })
    };

    // const getUploadImage = () => {
    //     getStickers({ page: 1, limit: 10 }).then((response) => {
    //         if (response.code == 200) {
    //             console.log('response.data.data: ', response.data);
    //             setImages(response.data.data);
    //             // setUploading(true)
    //         } else {
    //             setUploading(false);
    //         }
    //     });
    // }
    React.useEffect(() => {
        requestTimeout.current = setTimeout(() => {
            setDelayedQuery(query);
        }, 500);
        return () => {
            clearTimeout(requestTimeout.current);
        };
    }, [query]);
    // const load = useCallback(async () => {
    //     getUploadImage()
    // }, []);
    // React.useEffect(() => {
    //     load();
    // }, [load]);

    return (
        <>
            <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                {isModal && (
                    <UploadDocumentModal isModal={isModal} setIsModal={setIsModal} setAddData={setAddData} setIsUpload={setIsUpload} setType={setType} />
                )}
                <InputGroup
                    leftIcon="search"
                    placeholder={t("searchPlaceholderPolotno")}
                    onChange={(e) => {
                        setQuery(e.target.value);
                        setPage(1);
                    }}
                    type="search"
                />
                <div style={{ margin: '10px auto' }}>
                    <label htmlFor="input-file">
                        {/* <Button
                        icon="upload"
                        style={{ width: '100%' }}
                        onClick={() => {
                            document.querySelector('#input-file')?.click();
                        }}
                        loading={isUploading}
                    >
                        Upload Image
                    </Button> */}

                        <button className="btn-create mt-2" type="button" onClick={() => { setIsModal(true) }}> {t("uploadImageOrVideo")}
                        </button>
                        <input
                            type="file"
                            id="input-file"
                            style={{ display: 'none' }}
                            onChange={handleFileInput}
                        // multiple
                        />
                    </label>
                </div>
                <nav className="MyProjectsNavPCTab"  >
                    <div class="nav nav-tabs pb-2" id="nav-tab" role="tablist">

                        <button className={`nav-link ${type == "image" && "active"}`} id="nav-in-progress-tab" data-bs-toggle="tab" data-bs-target="#nav-in-progress" type="button" role="tab" aria-controls="nav-in-progress" aria-selected="true" onClick={() => { setType('image') }}>
                            {t("imagePolotno")}
                        </button>

                        <button className={`nav-link ${type == "video" && "active"}`} id="nav-in-progress-tab" data-bs-toggle="tab" data-bs-target="#nav-in-progress" type="button" role="tab" aria-controls="nav-in-progress" aria-selected="true" onClick={() => { setType('video') }}>
                            {t("video")}
                        </button>
                    </div>
                </nav>
                <NounprojectPanel query={delayedQuery} store={store} isUpload={isUpload} setIsUpload={setIsUpload} addData={addData} t={t} setPage={setPage} page={page} type={type} />
                {/* <ImagesGrid
                images={images}
                getPreview={(item) => item.image}
                crossOrigin="anonymous"
                getCredit={(image) => (
                    <div>
                        <Button
                            icon="trash"
                        // onClick={async (e) => {
                        //     e.stopPropagation();
                        //     if (
                        //         window.confirm('Are you sure you want to delete the image?')
                        //     ) {
                        //         await deleteImage(image.id);
                        //         await load();
                        //     }
                        // }}
                        ></Button>
                    </div>
                )}
                onSelect={async (image, pos, element) => {
                    const { url } = image;
                    let { width, height } = await getImageSize(url);
                    const isSVG = url.indexOf('svg+xml') >= 0 || url.indexOf('.svg') >= 0;

                    const type = isSVG ? 'svg' : 'image';

                    if (
                        element &&
                        element.type === 'svg' &&
                        !element.locked &&
                        type === 'image'
                    ) {
                        element.set({ maskSrc: url });
                        return;
                    }

                    if (
                        element &&
                        element.type === 'image' &&
                        !element.locked &&
                        type === 'image'
                    ) {
                        element.set({ src: url });
                        return;
                    }

                    const scale = Math.min(store.width / width, store.height / height, 1);
                    width = width * scale;
                    height = height * scale;

                    const x = (pos?.x || store.width / 2) - width / 2;
                    const y = (pos?.y || store.height / 2) - height / 2;

                    store.activePage?.addElement({
                        type,
                        src: url,
                        x,
                        y,
                        width,
                        height,
                    });
                }}
            /> */}
            </div>
        </>
    );
};
