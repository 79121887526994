import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Footer2 from "../../components/Footer2";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import checkValidation from "../../validation/ValidationSchema";
import { PhoneInput } from "react-international-phone";
import { PhoneNumberUtil } from "google-libphonenumber";
import {
  getToken,
  getUserDataFromLocal,
} from "../../Common/LocalStorageService";
import Header2 from "../../components/Header2";
import { addContactUs } from "../../api/apiHandler";
import { showPromiseNotification } from "../../Common/notification";
import Footer from "../../components/Footer";

const Contactus = () => {
  const { t, i18n } = useTranslation();
  // const [value, setValue] = useState()
  const [phone, setPhone] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const schema = checkValidation([
    "Fullname",
    "PhoneNumber",
    "Email",
    "Subject",
    "Descriptiontext",
  ]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setError,
    setValue,
    watch,
    reset,
    formState: { isSubmitted },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    const payload = {
      full_name: data?.Fullname.trim(),
      email: data?.Email,
      subject: data?.Subject,
      description: data?.Descriptiontext,
      mobile_no: data?.PhoneNumber,
      userId: getUserDataFromLocal()?.id,
    };

    console.log("payload: ", payload);
    const myPromise = new Promise((resolve, reject) =>
      addContactUs(payload).then((response) => {
        if (response.code === 200) {
          reset();
          setPhone("+380")
          resolve(response);
        } else {
          reject(response);
        }
      })
    );
    showPromiseNotification(myPromise);
  };

  const phoneUtil = PhoneNumberUtil.getInstance();
  console.log("watch:===> ", watch());
  const isPhoneValid = (phone) => {
    try {
      return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
    } catch (error) {
      return false;
    }
  };

  return (
    <div>
      {/* {scroll ? <ScrollToTop /> : null} */}
      <main>
        {getToken() ? <Header2 /> : <Header />}
        <section className="about_us_page" id="about">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-5 col-12 ">
                
                <div className="left_content_img">
                  <img
                    src="assets/images/contact.svg"
                    className="img-fluid"
                    alt="about"
                  />
                </div>
              </div>
              <div className="col-lg-8 col-md-8 col-sm-7 col-12 ">
                <div className="right_side_content">
                  <div className="main_title">
                    <h1>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/contact_icon.svg"
                        }
                      />
                      {t("getInTouch")}
                    </h1>
                    <p>{t("contactInfo")}</p>
                  </div>
                  <div className="contact_form">
                    <div className="login_form">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                          <div className="col-12 mb-3">
                            <div className="form-group left_fixed">
                              <input
                                type="text"
                                className="form-control"
                                placeholder={t("fullName")}
                                {...register("Fullname")}
                              />
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/images/account.svg"
                                }
                              />
                            </div>
                            {errors.Fullname && (
                              <span class="text-danger">
                                {" "}
                                {errors.Fullname.message}
                              </span>
                            )}
                          </div>
                          <div className="col-12 mb-3">
                            <PhoneInput
                              defaultCountry="ua"
                              value={phone}
                              className="form-group"
                              inputProps={{
                                className: "form-control",
                                name: "PhoneNumber",
                              }}
                              inputStyle={{
                                borderTopLeftRadius: "0",
                                borderBottomLeftRadius: "0",
                                borderLeft: "0",
                                paddingLeft: "0",
                              }}
                              countrySelectorStyleProps={{
                                buttonContentWrapperClassName:
                                  "form-control h-100",
                                buttonContentWrapperStyle: {
                                  borderTopRightRadius: "0",
                                  borderBottomRightRadius: "0",
                                  border: "1px solid #E4EAF7",
                                  paddingRight: "5px",
                                  borderRight: "0",
                                },

                                buttonClassName: "border-0 h-100",
                              }}
                              // dialCodePreviewStyleProps={{className:"form-control"}}
                              onChange={(phone) => {
                                console.log("phone:===> ", phone);
                                setPhone(phone);
                                setValue("PhoneNumber", phone);
                                const errorPhone = isPhoneValid(phone);
                                console.log("errorPhone: ", errorPhone);
                                if (errorPhone) {
                                  clearErrors("PhoneNumber");
                                  console.log("--------------------hererere");
                                } else {
                                  setError("PhoneNumber", {
                                    type: "phoneInvalid",
                                    message: i18n.t(`phoneInvalid`),
                                  });
                                }
                              }}
                            />
                            {isSubmitted && errors.PhoneNumber && (
                              <span class="text-danger">
                                {" "}
                                {errors.PhoneNumber.message}
                              </span>
                            )}
                          </div>
                          <div className="col-12 mb-3">
                            <div className="form-group left_fixed">
                              <input
                                type="text"
                                className="form-control"
                                placeholder={t("email")}
                                {...register("Email")}
                              />
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/images/email.svg"
                                }
                              />
                            </div>
                            {errors.Email && (
                              <span class="text-danger">
                                {" "}
                                {errors.Email.message}
                              </span>
                            )}
                          </div>
                          <div className="col-12 mb-3">
                            <div className="form-group left_fixed">
                              <input
                                type="text"
                                className="form-control"
                                placeholder={t("subject")}
                                {...register("Subject")}
                              />
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/images/subject.svg"
                                }
                              />
                            </div>
                            {errors.Subject && (
                              <span class="text-danger">
                                {" "}
                                {errors.Subject.message}
                              </span>
                            )}
                          </div>
                          <div className="col-12 mb-3">
                            <div className="form-group left_fixed">
                              <textarea
                                className="form-control textarea_height"
                                placeholder={t("description")}
                                {...register("Descriptiontext")}
                              />
                            </div>
                            {errors.Descriptiontext && (
                              <span className="text-danger">
                                {errors.Descriptiontext.message}
                              </span>
                            )}
                          </div>

                          <div className="col-12 mb-3">
                            <div className="form-group left_fixed">
                              <button type="submit" className="btn btn-theme">
                                {t("send")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
        {/* <Footer2 /> */}
      </main>
    </div>
  );
};

export default Contactus;
