import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import { getUserDataFromLocal } from './LocalStorageService';
import { Link } from 'react-router-dom';

function SubscriptionModal({ isSubscriptionModal, setIsSubscriptionModal }) {
    const { t, i18n } = useTranslation();
    const user = getUserDataFromLocal();

    return (
        <>
            <div className={`modal fade ${isSubscriptionModal ? "show" : ""}`} id="sub_modal" tabIndex={-1} aria-labelledby="exampleModalCenterTitle" aria-hidden="true"
                style={{ display: isSubscriptionModal ? "block" : "none" }}
            >
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-body signupCloseBtn p-0">
                            <button data-bs-dismiss="modal" className="ModalCloseBtn" onClick={() => { setIsSubscriptionModal(false); }}>
                                <img src={process.env.PUBLIC_URL + "/assets/images/icon/close.svg"} className="closeBtngt576 " alt='' />
                                <img className="closeBtnlt576" src="/templify-dev/assets/images/close-white.svg" alt="Close" style={{ height: "24px" }} />
                            </button>
                            {/* <button type="button" className="custom_btn signupCloseBtn" aria-label="Close"><img className="WhiteBtn" src="/templify-dev/assets/images/close-white.svg" alt="Close" /><img className="BlackBtn" src="/templify-dev/assets/images/close.svg" alt="Close" /></button> */}

                            <div className="row align-items-center">
                                <div className="col-md-6 col-sm-6 col-12">
                                    <div className="grediant_modal">
                                        <div className="my_subscription_div current">
                                            <h4>{t("workingFun")}</h4>
                                            <label>{t("marketingTogetherMsg")}</label>
                                            <ul>
                                                <li>{t("advancedMarketingTools")}</li>
                                                <li>{t("customizableTemplates")}</li>
                                                <li>{t("multiuserAccess")}</li>
                                                <li>{t("thirdpartyIntegrations")}</li>
                                                <li>{t("prioritySupport")}</li>
                                            </ul>
                                            <Link to={"/subscription"} className="subs_btn_1" onClick={() => { setIsSubscriptionModal(false); }}>{t("exploreOurPlans")}</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-12">
                                    <div className="img_modal">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/team_design.png"} alt='' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div >
            {
                isSubscriptionModal && (
                    <div className="modal-backdrop fade show" onClick={() => { setIsSubscriptionModal(false) }}></div>
                )
            }
        </>

    )
}

export default SubscriptionModal