import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import $ from "jquery";
import { useTranslation } from "react-i18next";
import {
  addTemplate,
  addUploadDocumentPolotnoApi,
  editTemplate,
  getAllCategories,
} from "../../../api/apiHandler";
import { TagsInput } from "react-tag-input-component";
import uploadToS3, {
  generateRandomFileName,
  uploadOrUpadateJSONToS3,
} from "../../../Common/s3AwsService";
import { getVideoSize, getVideoPreview } from "polotno/utils/video";
import {
  showErrorMessage,
  showPromiseNotification,
} from "../../../Common/notification";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import Lottie from "react-lottie";
import loadingAnimation from "../loder.json";
import imageCompression from "browser-image-compression";
window.jQuery = window.$ = $;
require("jquery-nice-select");

const UploadDocumentModal = (props) => {
  const { isModal, setIsModal, setAddData, setIsUpload, setType } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isImgChange, setIsImgChange] = useState(false);
  const [image, setImage] = useState(null);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("nameIsRequired")),
    image: Yup.mixed()
      .test("fileSize", t("max20MbReq"), (value) => {
        if (!value || !value.size) return true;
        return value.size <= 20 * 1024 * 1024;
      })
      .required(t("imageVideoReq")),
  });
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    setError,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  function generateVideoThumbnail(videoFile) {
    return new Promise((resolve, reject) => {
      const video = document.createElement("video");
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      video.src = URL.createObjectURL(videoFile);
      video.load();

      video.addEventListener("loadeddata", () => {
        // Set canvas dimensions to video frame size
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        // Seek to the desired frame (e.g., 1 second in)
        video.currentTime = 1;

        video.addEventListener("seeked", () => {
          // Draw the video frame to the canvas
          context.drawImage(video, 0, 0, canvas.width, canvas.height);

          // Convert the canvas to a Blob
          canvas.toBlob((blob) => {
            resolve(new File([blob], "thumbnail.webp", { type: "image/webp" }));
          }, "image/webp");
        });
      });

      video.addEventListener("error", reject);
    });
  }
  const onSubmit = useCallback(
    async (data) => {
      setIsSubmitting(true);
      console.log("data:====> ", data);
      let payload = {
        name: data.name,
        image_type: data.image_type,
      };
      let compressedImage = "";
      if (data.image_type == "video") {
        setType("video");
        let videoThumb = await generateVideoThumbnail(data.image);
        compressedImage = await imageCompression(videoThumb, {
          maxSizeMB: 0.1,
          maxWidthOrHeight: 800,
          useWebWorker: true,
          initialQuality: 0.5,
          fileType: "image/webp",
        });
        await uploadToS3(
          data.image,
          `${generateRandomFileName()}.${data.image_type != "video" ? data.image.type.split("/")[1] : "mp4"
          }`,
          "user"
        ).then((response) => {
          payload.video = response.key;
        });
        await uploadToS3(
          compressedImage,
          `${generateRandomFileName()}.${data.image_type != "video" ? data.image.type.split("/")[1] : "mp4"
          }`,
          "user"
        ).then((response) => {
          payload.image = response.key;
        });
      } else {
        setType("image");
        compressedImage = await imageCompression(data.image, {
          maxSizeMB: 0.1,
          maxWidthOrHeight: 800,
          useWebWorker: true,
          initialQuality: 0.5,
          fileType: "image/webp",
        });
        let compressedOrignalImage = await imageCompression(data.image, {
          maxSizeMB: 1,
          maxWidthOrHeight: 800,
          useWebWorker: true,
          initialQuality: 1,
          fileType: "image/webp",
        });
        await uploadToS3(
          compressedOrignalImage,
          `${generateRandomFileName()}.${compressedOrignalImage.type.split("/")[1]}`,
          "user"
        ).then((response) => {
          payload.image = response.key;
        });
      }
      await uploadToS3(
        compressedImage,
        `${generateRandomFileName()}1.${compressedImage.type.split("/")[1]}`,
        "user"
      ).then((response) => {
        payload.low_image = response.key;
      });

      const myPromise = new Promise((resolve, reject) =>
        addUploadDocumentPolotnoApi(payload).then((response) => {
          console.log("response123456: ", response);
          if (response.code === 200) {
            setAddData(response.data);
            setIsSubmitting(false);
            setIsUpload(true);
            setIsModal(false);
            resolve(response);
            // navigate('/notification')
          } else {
            reject(response);
          }
        })
      );
      showPromiseNotification(myPromise);
    },
    [setAddData, setIsModal, setIsUpload, setType]
  );
  const getVideoThumbnail = (videoUrl) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement("video");
      video.src = videoUrl;
      video.load();

      video.addEventListener("loadeddata", () => {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");

        // Set canvas dimensions to video frame size
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        // Seek to the desired frame (e.g., 1 second in)
        video.currentTime = 1;

        video.addEventListener("seeked", () => {
          // Draw the video frame to the canvas
          context.drawImage(video, 0, 0, canvas.width, canvas.height);

          // Convert the canvas to a data URL
          const dataUrl = canvas.toDataURL("image/png");
          resolve(dataUrl);
        });
      });

      video.addEventListener("error", reject);
    });
  };

  const handleImageChange = useCallback(
    async (e) => {
      const file = e.target.files[0];
      console.log("file: ", file, file.type);
      if (file && file.type.startsWith("image")) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImage(reader?.result);
        };
        reader.readAsDataURL(file);
        setIsImgChange(true);
        setImage(file);
        setValue("image", file);
        setValue("image_type", "image");
      } else if (file && file.type.startsWith("video")) {
        console.log("file: 12313132", file, file.type);
        await getVideoPreview(URL.createObjectURL(file));
        // const reader = new FileReader()
        // reader.onloadend = () => {
        //     setImage(reader?.result)
        // }
        // reader.readAsDataURL(file)
        // setIsImgChange(true)
        // setImage(file)
        const thumbnail = await getVideoThumbnail(URL.createObjectURL(file));
        setImage(thumbnail);

        setValue("image", file);
        setValue("image_type", "video");
      } else if (file) {
        setIsImgChange(false);
        setImage(null);
        showErrorMessage("Only image files are allowed.");
        setError("image", "Only image files are allowed.");
        setValue("image", null);
      } else {
        setIsImgChange(false);
        setImage(null);
        setError("image", t("imageVideoReq"));
        setValue("image", null);
      }
    },
    [setError, setValue, t]
  );
  return (
    <>
      <div
        className={`modal fade ${isModal ? "show d-block" : "d-none"}`}
        tabIndex={-1}
        id="add-template-modal"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="custom_btn"
                onClick={() => setIsModal(false)}
              >
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/close.svg"}
                  alt="close"
                />
              </button>
              <div className="modal-login-main">
                <h6 className="modal-heading">{t("uploadImageOrVideo")}</h6>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <ul className="link-box-ul">
                    <li className="add-template-li">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        className={`form-control `}
                        placeholder={t("name")}
                        {...register("name")}
                      />
                      {errors.name && (
                        <span className="text-danger fs-12px">
                          {errors.name.message}
                        </span>
                      )}
                    </li>
                    <li className="link-box-li">
                      <input
                        className="img-input d-none"
                        id="clickfile"
                        // {...register('profile_image')}
                        name="icon_image"
                        accept="image/*,video/*"
                        type="file"
                        // {...register("image")}
                        // style={{ height: 'fit-content' }}
                        onChange={(e) => handleImageChange(e)}
                      // ref={fileRef}
                      // disabled={data?.type == 'view'}
                      />

                      <label htmlFor="clickfile" style={{ width: "100%" }}>
                        {image ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={image}
                              id="output"
                              alt="Profile"
                              className="img-thumbnail icon-image"
                              style={{ maxHeight: "200px" }}
                            />
                          </div>
                        ) : (
                          <div className="area">
                            <div id="dropZone">{t("addImageHere")}</div>
                          </div>
                        )}
                      </label>
                      {errors.image && (
                        <span className="text-danger fs-12px">
                          {errors.image.message}
                        </span>
                      )}
                    </li>
                    <li className="link-box-li">
                      <div className="form-group left_fixed">
                        <button
                          type="submit"
                          className="btn btn-theme"
                          disabled={isSubmitting}
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          {isSubmitting ? (
                            <>
                              {t("pleaseWait")}
                              <Lottie
                                options={defaultOptions}
                                height={25}
                                width={80}
                                style={{ margin: "0" }}
                              />
                            </>
                          ) : (
                            t("uploadPolotno")
                          )}
                        </button>
                      </div>
                    </li>
                  </ul>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModal && <div className="modal-backdrop fade show"></div>}
    </>
  );
};

export default UploadDocumentModal;
