import React, { useRef, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import checkValidation from "../../../validation/ValidationSchema";
import { addTeamMember } from "../../../api/apiHandler";
import Lottie from 'react-lottie';
import loadingAnimation from '../../Editor/loder.json'
import { showPromiseNotification } from "../../../Common/notification";

const AddMember = (props) => {
  const { addmember, closeDashboardModal, setMember } = props;
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const schema = checkValidation(["Fullname", "Email"]);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    setIsSubmitting(true)

    const payload = {
      full_name: data?.Fullname,
      email: data?.Email,
    };

    const myPromise = new Promise((resolve, reject) =>
      addTeamMember(payload).then((response) => {
        if (response.code === 200) {
          setMember((prev) => [response.data, ...prev]);
          reset();
          closeDashboardModal();
          resolve(response);
        } else {
          reject(response);
        }
      })
    );
    showPromiseNotification(myPromise);
  };

  return (
    <>
      <div
        className={`modal fade ${addmember ? "show" : ""}`}
        id="add_member"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
        style={{ display: addmember ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="custom_btn"
                onClick={() => {
                  closeDashboardModal();
                }}
                aria-label="Close"
              >
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/close.svg"}
                />
              </button>
              <div className="modal_forgot">
                <div className="mobile_icon text-center mb-2">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/images/add_member.svg"
                    }
                  />
                </div>
                <h6>{t("addNewMember")}</h6>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="login_form mt-5">
                    <div className="row">
                      <div className="col-12 mb-3">
                        <div className="form-group left_fixed">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t("fullName")}
                            {...register("Fullname")}
                          />
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/account.svg"
                            }
                          />
                        </div>
                        {errors.Fullname && (
                          <span class="text-danger">
                            {" "}
                            {errors.Fullname.message}
                          </span>
                        )}
                      </div>
                      <div className="col-12 mb-3">
                        <div className="form-group left_fixed">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t("email")}
                            {...register("Email")}
                          />
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/email.svg"
                            }
                          />
                        </div>
                        {errors.Email && (
                          <span class="text-danger">
                            {" "}
                            {errors.Email.message}
                          </span>
                        )}
                      </div>
                      <div className="col-12 mb-3">
                        <div className="form-group left_fixed">
                          <button type="submit" className="btn btn-theme" disabled={isSubmitting} style={{ display: 'flex', justifyContent: "center" }}>
                            {/* {t("add")} */}
                            {
                              isSubmitting ?
                                (
                                  <>
                                    {t("pleaseWait")}
                                    < Lottie options={defaultOptions} height={25} width={80} style={{ margin: '0' }} />
                                  </>
                                )
                                : t("add")
                            }
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </>
  );
};

export default AddMember;
