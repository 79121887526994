import React, { useCallback, useEffect, useState } from 'react'
import { getUserDataFromLocal } from '../../../Common/LocalStorageService';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import { getAllCategories, getCategoryIcon } from '../../../api/apiHandler';

function ResizeModal({ isResizeModal, setIsResizeModal, FIX_SIZES, setTemplateSizes, store, setFillCategoryData }) {
    const { t, i18n } = useTranslation();
    const [resizeId, setResizeId] = useState(1)
    const [maincategory, setMaincategory] = useState(0)
    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0)
    const [boxWidth, setBoxWidth] = useState(100)
    const [boxHeight, setBoxHeight] = useState(100)
    const user = getUserDataFromLocal();
    const [iconData, setIconData] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    console.log('categoryData: ', categoryData);

    const handleIconData = useCallback((id) => {
        getCategoryIcon({ category_id: id }).then((response) => {
            console.log('response123: ', response);
            if (response?.code == 200) {
                setIconData(response?.data)
                //     console.log('response1234: ', response);
                //     setTemplateData(response?.data[0]);
                //     setIsTemplateDetailsModalOpen(true);
            }
        });
        getAllCategories({}).then((response) => {
            console.log('response123: ', response);
            if (response?.code == 200) {
                setCategoryData(response?.data)
                //     console.log('response1234: ', response);
                //     setTemplateData(response?.data[0]);
                //     setIsTemplateDetailsModalOpen(true);
            }
        });
    }, [])
    useEffect(() => {
        handleIconData(resizeId)
    }, [])
    // useEffect(() => {
    //     if (height > 0 && width > 0) {
    //         if (height > width) {
    //             const percentage = (width / height) * 100;
    //             console.log('Box Width Percentage: ', percentage);
    //             setBoxWidth(percentage);
    //             setBoxHeight(100); 
    //         } else if (width > height) {
    //             const percentage = (height / width) * 100;
    //             console.log('Box Height Percentage: ', percentage);
    //             setBoxHeight(percentage);
    //             setBoxWidth(100);
    //         } else {
    //             setBoxWidth(100);
    //             setBoxHeight(100);
    //         }
    //     }
    // }, [width, height]);
    useEffect(() => {
        if (height > 0 && width > 0) {
            console.log(height == width || height == 0 || width == 0);
            if (height == width || height == 0 || width == 0) {
                setBoxHeight(100);
                setBoxWidth(100);
            } else {
                const total = height + width;
                console.log('Percentage total: ', total, height, width);
                const heightPercentage = (height / total) * 100;
                const widthPercentage = (width / total) * 100;

                console.log('Percentage Box Height : ', heightPercentage);
                console.log('Percentage Box Width : ', widthPercentage);

                setBoxHeight(heightPercentage);
                setBoxWidth(widthPercentage);
            }
        }
    }, [width, height]);

    return (
        <>
            <div className={`PCModalBodyControl modal fade ${isResizeModal ? "show" : ""}`} id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true"
                style={{ display: isResizeModal ? "block" : "none" }}
            >
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            {/* <form action="#"> */}
                            <div className="ModalBox">
                                {
                                    user && user.type != "designer" && (
                                        <nav className="MyProjectsNavPCTab"  >
                                            <div class="nav nav-tabs" id="nav-tab" role="tablist">

                                                <button className={`nav-link active`} id="nav-in-progress-tab" data-bs-toggle="tab" data-bs-target="#nav-in-progress" type="button" role="tab" aria-controls="nav-in-progress" aria-selected="true" onClick={() => { setMaincategory(0) }}>
                                                    {t("popularFormats")}
                                                </button>

                                                <button className={`nav-link `} id="nav-in-progress-tab" data-bs-toggle="tab" data-bs-target="#nav-in-progress" type="button" role="tab" aria-controls="nav-in-progress" aria-selected="true" onClick={() => { setMaincategory(1) }}>
                                                    {t("customSize")}
                                                </button>
                                            </div>
                                        </nav>
                                    )}
                                {maincategory == 0 ? (
                                    <div className="row ">
                                        {/* <div className={`${user.type != 'designer' ? 'col-xxl-8 col-xl-8 col-lg-8' : 'col-xxl-12 col-xl-12 col-lg-12'} col-md-12 col-sm-12 col-12`}> */}
                                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" >
                                            <div className="ModakNav">
                                                <button data-bs-dismiss="modal" className="ModalCloseBtn" onClick={() => { setIsResizeModal(false) }}> <img src={process.env.PUBLIC_URL + "/assets/images/icon/close.svg"} className="closeBtnimg" alt /> </button>
                                                <nav className="MyProjectsNavTabBox"  >
                                                    <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                                        {/* {FIX_SIZES && FIX_SIZES.length > 0 && FIX_SIZES.map((item, index) => {
                                                            return (
                                                                <button className={`nav-link ${index == 0 && 'active'}`} id="nav-in-progress-tab" data-bs-toggle="tab" data-bs-target="#nav-in-progress" type="button" role="tab" aria-controls="nav-in-progress" aria-selected="true" onClick={() => { setResizeId(index) }}>
                                                                    {item.category_name}
                                                                </button>
                                                            )
                                                        })} */}
                                                        {
                                                            categoryData && categoryData.length > 0 && categoryData?.map((item, index) => {
                                                                return (
                                                                    <button
                                                                        className={`nav-link ${index ==
                                                                            0 &&
                                                                            "active"
                                                                            }`}
                                                                        id="nav-in-progress-tab"
                                                                        data-bs-toggle="tab"
                                                                        data-bs-target="#nav-in-progress"
                                                                        type="button"
                                                                        role="tab"
                                                                        aria-controls="nav-in-progress"
                                                                        aria-selected="true"
                                                                        onClick={() => {
                                                                            handleIconData(
                                                                                item?.id
                                                                            );
                                                                        }}
                                                                    >
                                                                        {item.name}
                                                                    </button>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </nav>
                                                {/* <ul className="nav nav-tabs" id="myTab" role="tablist">
                                            {FIX_SIZES && FIX_SIZES.length > 0 && FIX_SIZES.map((item, index) => {
                                                return (
                                                    <li className="nav-item" role="presentation">
                                                        <button className={`nav-link ${index == 0 && 'active'}`} id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true"
                                                            onClick={() => { setResizeId(index) }}
                                                        >
                                                            {item.category_name}
                                                        </button>
                                                    </li>
                                                )
                                            })}
                                        </ul> */}
                                                <div className="tab-content" id="myTabContent">
                                                    <div className="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex={0}>
                                                        <ul className="ModalContentsUl">
                                                            <div className="row">
                                                                {/* {
                                                                    FIX_SIZES && FIX_SIZES.length > 0 && FIX_SIZES[resizeId].templates_size.length > 0 && FIX_SIZES[resizeId].templates_size.map((item, index) => {
                                                                        return (
                                                                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6 MyModalGride" onClick={() => {
                                                                                store.setSize(item.width, item.height, true);
                                                                                setIsResizeModal(false)
                                                                            }}>
                                                                                <a className="ModalContentsLink">
                                                                                    <div className="ModalIconBox">
                                                                                        <img className="MyModalIcon img-contain" src={item.image ? item.image : process.env.PUBLIC_URL + "/assets/images/icon/instagram.svg"} alt="" />
                                                                                    </div>
                                                                                    <span className="PostName text-center">{item.name}</span>
                                                                                    <span className="PostSize">{item.height} x {item.width}</span>
                                                                                </a>
                                                                            </div>
                                                                        )
                                                                    })
                                                                } */}
                                                                {
                                                                    iconData && iconData.length > 0 && iconData.map((item, index) => {
                                                                        return (
                                                                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6 MyModalGride" onClick={() => {
                                                                                store.setSize(item.width, item.height, true);
                                                                                setIsResizeModal(false);
                                                                                setFillCategoryData(
                                                                                    {
                                                                                        category_id:
                                                                                            item?.category_id,
                                                                                        subcategory_id:
                                                                                            item?.id,
                                                                                    }
                                                                                );
                                                                            }}>
                                                                                <a className="ModalContentsLink">
                                                                                    <div className="ModalIconBox">
                                                                                        <img className="MyModalIcon img-contain" src={item.icon_image ? item.icon_image : process.env.PUBLIC_URL + "/assets/images/icon/instagram.svg"} alt="" />
                                                                                    </div>
                                                                                    <span className="PostName text-center">{item.name}</span>
                                                                                    <span className="PostSize">{item.height} x {item.width}</span>
                                                                                </a>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                                {/*  */}
                                                                {/*  */}
                                                            </div>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* {
                                        user && user.type != "designer" && (
                                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                                <div className="ModalRightBox">
                                                    <div>
                                                        <h3 className="Modalrightheading">
                                                            {t("customSize")}
                                                        </h3>
                                                        <ul className="ModalCustomeUl">
                                                            <li className="ModalCustomeLi">
                                                                <label htmlFor="num1">{t("widthPolotno")}</label>
                                                                <input type="number" id="num1" className="MyModalInput" min={0} onChange={(e) => { setWidth(e.target.value) }} />
                                                            </li>
                                                            <li className="ModalCustomeLi">
                                                                <label htmlFor="num1"> {t("heightPolotno")} </label>
                                                                <input type="number" id="num2" className="MyModalInput" min={0} onChange={(e) => { setHeight(e.target.value) }} />
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="ModalFooter">
                                                        <button type="button" className="CreateBtn" onClick={() => {
                                                            if (width > 0 && height > 0) {
                                                                store.setSize(parseInt(width), parseInt(height), true);
                                                                setIsResizeModal(false)
                                                            } else {
                                                                toast.error(t("ErrorResize"));
                                                            }
                                                        }}>{t("create")}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    } */}
                                    </div>

                                ) : (
                                    <div className="row d-flex justify-content-center">
                                        <div className="ModakNav">
                                            <button data-bs-dismiss="modal" className="ModalCloseBtn" onClick={() => { setIsResizeModal(false) }}> <img src={process.env.PUBLIC_URL + "/assets/images/icon/close.svg"} className="closeBtnimg" alt /> </button>
                                        </div>
                                        <div style={{ height: "25vh", width: "25vh", margin: "2% 0", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <div className='resizeBox' style={{ height: boxHeight + "%", width: boxWidth + "%" }} >
                                            </div>
                                        </div>
                                        <div class="custom-size">
                                            <div class="size-inputs">
                                                <ul className="ModalCustomeUl">
                                                    <li className="ModalCustomeLi">
                                                        <label htmlFor="num1">{t("widthPolotno")}</label>
                                                        <input type="number" id="num1" className="MyModalInput" min={0} max={8000}
                                                            value={width > 0 && width} placeholder='0'
                                                            onChange={(e) => { if (e.target.value >= 0 && e.target.value <= 8000) { setWidth(Number(e.target.value)) } }} />
                                                    </li>
                                                    <li className="ModalCustomeLi">
                                                        <label htmlFor="num1"> {t("heightPolotno")} </label>
                                                        <input type="number" id="num2" className="MyModalInput" min={0} max={8000}
                                                            value={height > 0 && height} placeholder='0'
                                                            onChange={(e) => { if (e.target.value >= 0 && e.target.value <= 8000) { setHeight(Number(e.target.value)) } }} />
                                                    </li>
                                                    <li className="ModalCustomeLi">
                                                        <label htmlFor="num1"> formate </label>
                                                        <input type="text" className="MyModalInput"
                                                            value={"Px"}
                                                            disabled={true}
                                                            style={{ width: "50px" }}
                                                        />
                                                        {/* <p className="MyModalInput">px</p> */}
                                                        {/* <select class="unit-select" onChange={() => { setHeight(0); setWidth(0) }}>
                                                        <option value="px">px</option>
                                                        <option value="cm">cm</option>
                                                        <option value="in">in</option>
                                                    </select> */}
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className=" d-flex justify-content-center">
                                                <button className="btn-create mt-4" type="button"
                                                    style={{ cursor: (width > 8000 && height > 8000) || (height == 0 && width == 0) ? "no-drop" : 'pointer' }}
                                                    disabled={(width > 8000 && height > 8000) || (height == 0 && width == 0)}
                                                    onClick={() => {
                                                        if (width > 0 && height > 0) {
                                                            store.setSize(parseInt(width), parseInt(height), true);
                                                            setIsResizeModal(false)
                                                        } else {
                                                            toast.error(t("ErrorResize"));
                                                        }
                                                    }}> Create Design
                                                </button>
                                            </div>
                                            {(height == 0 || width == 0) && (
                                                <div class="alert alert-danger mt-2" role="alert">
                                                    Each dimention must be at least 1 and no more than 8000!
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                            {/* </form> */}
                        </div>
                    </div>
                </div>
            </div >
            {
                isResizeModal && (
                    <div className="modal-backdrop fade show"></div>
                )
            }
        </>

    )
}

export default ResizeModal