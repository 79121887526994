import React from "react";
import "./style.css";
import { Link } from "react-router-dom";
const PageNotFound = () => {
  return (
    // <div>
    //   <section className="d-flex align-items-center min-vh-100 py-5">
    //     <div className="container py-5">
    //       <div className="row align-items-center">
    //         <div className="col-md-6 order-md-2">
    //           <div className="lc-block">
    //             <lottie-player
    //               src="https://assets9.lottiefiles.com/packages/lf20_kcsr6fcp.json"
    //               background="transparent"
    //               speed={1}
    //               loop
    //               autoPlay
    //             />
    //           </div>
    //           {/* /lc-block */}
    //         </div>
    //         {/* /col */}
    //         <div className="col-md-6 text-center text-md-start ">
    //           <div className="lc-block mb-3">
    //             <div editable="rich">
    //               <h1 className="fw-bold h4">
    //                 PAGE NOT FOUND!
    //                 <br />
    //               </h1>
    //             </div>
    //           </div>
    //           <div className="lc-block mb-3">
    //             <div editable="rich">
    //               <h1 className="display-1 fw-bold text-muted">Error 404</h1>
    //             </div>
    //           </div>
    //           {/* /lc-block */}
    //           <div className="lc-block mb-5">
    //             <div editable="rich">
    //               <p className="rfs-11 fw-light">
    //                 {" "}
    //                 The page you are looking for was moved, removed or might
    //                 never existed.
    //               </p>
    //             </div>
    //           </div>
    //           {/* /lc-block */}
    //           <div className="lc-block">
    //             <a className="btn btn-lg btn-primary" href="#" role="button">
    //               Back to homepage
    //             </a>
    //           </div>
    //           {/* /lc-block */}
    //         </div>
    //         {/* /col */}
    //       </div>
    //     </div>
    //   </section>
    //   <p className="py-5 small text-center text-muted">
    //     {" "}
    //     Powered by{" "}
    //     <a href="https://library.livecanvas.com/">
    //       LiveCanvas Bootstrap Page Templates
    //     </a>
    //   </p>
    // </div>
    <section class="page_404">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 d-flex justify-content-center">
            <div class="col-sm-10 col-sm-offset-1 text-center">
              <img
                src={process.env.PUBLIC_URL + "/assets/images/Logo_new.svg"}
                style={{ width: "15em" }}
              ></img>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 d-flex justify-content-center">
            <div class="col-sm-10 col-sm-offset-1 text-center">
              <div class="four_zero_four_bg">
                <h1 class="text-center ">404</h1>
              </div>
              <div class="contant_box_404">
                <h3 class="h2">Look like you're lost</h3>
                <p>The page you are looking for not available!</p>
                <Link to="/" class="link_404">
                  Go to Home
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageNotFound;
