import { useLocation, useNavigate } from "react-router-dom";
import { t } from "i18next";
import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import {
  getUserDataFromLocal,
  removeUserDataFromLocal,
} from "../Common/LocalStorageService";
import { logout } from "../api/apiHandler";
import { showPromiseNotification } from "../Common/notification";
import Swal from "sweetalert2";
import { useFilter } from "../Common/FilterContex";
import SubscriptionModal from "../Common/SubscriptionModal";

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const user = getUserDataFromLocal();
  const [isSubscriptionModal, setIsSubscriptionModal] = useState(false)
  const { prevFilters, setPrevFilters } = useFilter();
  const menuItems = [
    // {
    //   path: "/home",
    //   icon: "/assets/images/sidebar/invited_project.svg",
    //   activeIcon: "/assets/images/sidebar/invited_project.svg",
    //   label: t("myProjects"),
    // },
    // {
    //   path: "/my-team",
    //   icon: "/assets/images/sidebar/my_team.svg",
    //   activeIcon: "/assets/images/sidebar/g_my_team.svg",
    //   label: t("myTeam"),
    // },

    // {
    //   path: "/my-earning",
    //   icon: "/assets/images/sidebar/invited_project.svg",
    //   activeIcon: "/assets/images/sidebar/g_earning.svg",
    //   label: t("myEarnings"),
    // },
    // {
    //   path: "/invited-project",
    //   icon: "/assets/images/sidebar/my_project.svg",
    //   activeIcon: "/assets/images/sidebar/g_invited_project.svg",
    //   label: t("invitedProjects"),
    // },
    // {
    //   path: "/invite-member",
    //   icon: "/assets/images/sidebar/invited_project.svg",
    //   activeIcon: "/assets/images/sidebar/g_invited_project.svg",
    //   label: t("inviteMember"),
    // },
    // {
    //   path: "/profile2",
    //   icon: "/assets/images/sidebar/invited_project.svg",
    //   activeIcon: "/assets/images/sidebar/g_invited_project.svg",
    //   label: t("designerProfile"),
    // },

    {
      path: "/home",
      icon: "/assets/images/newicons/normal/home.svg",
      activeIcon: "/assets/images/newicons/red/home.svg",
      label: t("home"),
      access: true,
    },
    {
      path: "/my-projects",
      icon: "/assets/images/newicons/normal/My_projects.svg",
      activeIcon: "/assets/images/newicons/red/My_projects.svg",
      label: t("myProjects"),
      access: true,
    },
    {
      path: "/my-earning",
      icon: "/assets/images/newicons/normal/My_Earnings.svg",
      activeIcon: "/assets/images/newicons/red/My_Earnings.svg",
      label: t("myEarnings"),
      access: getUserDataFromLocal()?.type === "designer",
    },
    {
      path: "/invited-project",
      icon: "/assets/images/newicons/normal/Invited_projects.svg",
      activeIcon: "/assets/images/newicons/red/Invited_projects.svg",
      label: t("teamProjects"),
      access: true,
    },
    {
      path: "/my-team",
      icon: "/assets/images/newicons/normal/My_team.svg",
      activeIcon: "/assets/images/newicons/red/My_team.svg",
      label: t("myTeam"),
      type:
        getUserDataFromLocal()?.is_subscribed == "0" ||
        getUserDataFromLocal()?.subscribed_type !== "enterprise",
      access: true
      // &&
      // getUserDataFromLocal()?.subscription_duration === "yearly",
    },
  ];
  console.log('menuItems: ', menuItems);

  const handleLogOut = useCallback(() => {
    Swal.fire({
      title: t("areYouSure"),
      text: t("logoutText"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: t("logOutConfirm"),
      cancelButtonText: t("cancelText"),
    }).then((result) => {
      if (result.isConfirmed) {
        // Perform deletion logic here
        const myPromise = new Promise((resolve, reject) =>
          logout({}).then((response) => {
            console.log("response: ", typeof response, response);
            if (response.code == 200) {
              removeUserDataFromLocal();
              resolve(response);
              navigate("/");
            } else {
              reject(response);
            }
          })
        );
        showPromiseNotification(myPromise);
      }
    });
  }, [navigate]);

  return (
    <>
      <div className="siderbar">
        <ul className="menu_sidebar myriad-pro-ul" >
          {menuItems
            ?.filter((item) => item.access)
            .map((item, index) => (
              <li
                key={index}
                className={location.pathname === item.path ? "active" : ""}
              >
                {(getUserDataFromLocal()?.is_subscribed == "0" ||
                  getUserDataFromLocal()?.subscribed_type !== "enterprise") && item.type ? (
                  <Link
                    onClick={() => {
                      setPrevFilters({});
                      setIsSubscriptionModal(true)
                    }}
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        (location.pathname === item.path
                          ? item.activeIcon
                          : item.icon)
                      }
                      className="LeftSideIcon"
                      alt=""
                    />
                    {item.label}
                    {/* {getUserDataFromLocal()?.is_subscribed == "0" &&
                    getUserDataFromLocal()?.subscribed_type !== "enterprise" && item.type && ( */}
                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/premium-quality-color.svg"} alt="headerIcon" className="headerIconTwo ms-2" />
                    {/* )} */}
                  </Link>
                ) : (
                  <Link
                    to={item.path}
                    onClick={() => {
                      setPrevFilters({});
                    }}
                  >
                    <img

                      src={
                        process.env.PUBLIC_URL +
                        (location.pathname === item.path
                          ? item.activeIcon
                          : item.icon)
                      }
                      className="LeftSideIcon"
                      alt=""
                    />
                    {item.label}
                    {/* {getUserDataFromLocal()?.is_subscribed == "0" &&
                    getUserDataFromLocal()?.subscribed_type !== "enterprise" && item.type && (
                      <img src={process.env.PUBLIC_URL + "/assets/images/icon/premium-quality-color.svg"} alt="headerIcon" class="headerIconTwo" />
                    )} */}
                  </Link>
                )
                }
              </li>
            ))}
          {user?.is_subscribed != "1" && (
            <li className="mt-auto last-left-li" >
              {/* <Link onClick={handleLogOut}>
            <img
              alt=""
              src={process.env.PUBLIC_URL + "/assets/images/sidebar/logout.svg"}
            />
            {t("Logout")}
          </Link>{" "} */}
              <li>
                <div className="LiLastBox">
                  <div>
                    <p className="LiLastText">
                      {t("gainUnlimitedAccessTo")}
                    </p>
                    <p className="LiLastText">
                      {t("downloadsAndMoreWith")}
                    </p>
                  </div>
                  <p className="LiLastBoldText">
                    {t("craetivoPremium")}
                  </p>
                  <Link to={"/subscription"} className="GoPrimium" onClick={() => {
                    setPrevFilters({});
                  }}>
                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/premium-quality.svg"} alt="headerIcon" />
                    {t("upgrade")}
                  </Link>
                </div>
              </li>

            </li>
          )}
        </ul>
      </div>
      {isSubscriptionModal && (
        <SubscriptionModal isSubscriptionModal={isSubscriptionModal} setIsSubscriptionModal={setIsSubscriptionModal} />
      )}
    </>
  );
};

export default Sidebar;
