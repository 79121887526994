import React from 'react';
import { Helmet } from 'react-helmet';

const JQueryScript = () => (
  <Helmet>
    <script src="https://code.jquery.com/jquery-3.6.0.min.js"></script>
    <script>
      {`
        $(document).ready(function () {
          var $toggleButton = $('.toggle-button'),
              $menuWrap = $('.menu-wrap'),
              $datalink = $('.data-link-1'),
              $sidebarArrow = $('.sidebar-menu-arrow');

          $toggleButton.on('click', function () {
            $(this).toggleClass('button-open');
            $menuWrap.toggleClass('menu-show');
          });

          $datalink.on('click', function () {
            $menuWrap.toggleClass('menu-show');
            $toggleButton.removeClass('button-open');
          });

          $sidebarArrow.click(function () {
            $(this).next().slideToggle(300);
          });

          
        });
      `}
    </script>
  </Helmet>
);

export default JQueryScript;
